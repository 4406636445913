import { Component, OnInit } from '@angular/core';
import { MailService } from 'src/app/services/mail.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup
  formTrue: boolean = false
  formSuccess: boolean = false

  constructor(private mailService: MailService, private scroll: ScrollService) { }

  ngOnInit(): void {
    this.scroll.setScroll()
    this.contactForm = new FormGroup({
      telefon: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*[0-9+ /-]*$"), Validators.minLength(2)]),
      vorname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      nachname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      message: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  send() {
    this.formTrue = true
    let formData = new FormData
    let name = this.f.vorname.value + ' ' + this.f.nachname.value

    formData.append('telefon', this.f.telefon.value),
    formData.append('name', name),
      formData.append('message', this.f.message.value)

    this.formTrue = true;
    if (this.contactForm.valid) {
      console.log('success')
      this.formTrue = false;
      this.formSuccess = true;
      this.mailService.sendMail(formData).catch(error => {
        if (error) {
          this.formSuccess = false;
          console.log(error)
        }
      });
    } else {
      console.log("error");
    }
  }

  // google maps zoom level
  zoom: number = 15;

  // initial center position for the map
  lat: number = 47.083191;
  lng: number = 8.342170;

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     draggable: true
  //   });
  // }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  markers: marker[] = [
    {
      lat: 47.083191,
      lng: 8.342170,
      label: 'A',
      draggable: false
    },
  ]


  style = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]

}
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}