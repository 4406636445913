<h2>Informationen anfordern</h2>
<form class="form-wrapper" [formGroup]="financeForm" action="" *ngIf="!formSidebarSuccess">
    <div class="field">
        <input formControlName="vorname" type="text" placeholder="Vorname"
            [ngClass]="{'invalid': (f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)}">
        <div class="error-msg" *ngIf="(f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)">
            <span *ngIf="f.vorname.errors.required">Bitte Vorname angeben</span>
        </div>
    </div>
    <div class="field">
        <input formControlName="nachname" type="text" placeholder="Nachname"
            [ngClass]="{'invalid': (f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)}">
        <div class="error-msg"
            *ngIf="(f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)">
            <span *ngIf="f.nachname.errors.required">Bitte Nachname angeben</span>
        </div>
    </div>
    <div class="field">
        <input formControlName="email" type="text" placeholder="E-Mail"
            [ngClass]="{'invalid': (f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)}">
        <div class="error-msg" *ngIf="(f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)">
            <span *ngIf="f.email.errors.required">Bitte Email angeben</span>
        </div>
    </div>
    <div class="field">
        <input formControlName="telefon" type="text" placeholder="Telefon"
            [ngClass]="{'invalid': (f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)}">
        <div class="error-msg" *ngIf="(f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)">
            <span *ngIf="f.telefon.errors.required">Bitte Telefon angeben</span>
        </div>
    </div>
    <div class="field area">
        <textarea formControlName="message" cdkAutosizeMinRows="110" placeholder="Nachricht" cdkAutosizeMaxRows="120"
            [ngClass]="{'invalid': (f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)}"></textarea>
        <div class="error-msg" *ngIf="(f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)">
            <span *ngIf="f.message.errors.required">Bitte Nachricht eingeben</span>
        </div>
    </div>
    <div class="btn-wrapper">
        <button class="classic-button" (click)="sendSidebar()">SENDEN</button>
    </div>
</form>
<div class="success-mess" *ngIf="formSidebarSuccess">
    Nachricht wurde erfolgreich übermittelt.
</div>