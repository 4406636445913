import { Component, OnInit, ViewChild } from '@angular/core';
import { MailService } from 'src/app/services/mail.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { trigger, state, style, animate, transition, } from '@angular/animations';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        'height': '0px',
      })),
      state('open', style({
        'max-height': '800px',
      })),
      transition('open <=> closed', animate(400)),
    ])
  ]
})
export class HomeComponent implements OnInit {

  phoneSection: boolean = false
  contactSection: boolean = true
  chatSection: boolean = false
  mobile: boolean = false
  firstCard: boolean = true
  secondCard: boolean = false
  thirdCard: boolean = false
  firstSection: boolean = true
  secondSection: boolean = false
  thirdSection: boolean = false
  contactForm: FormGroup
  formTrue: boolean = false
  formSuccess: boolean = false
  elements
  public show: boolean = true;
  firstActive: boolean = true;
  secondActive: boolean = false;
  thirdActive: boolean = false;
  fourthActive: boolean = false;
  firstTabState = 'closed'
  secondTabState = 'closed'
  thirdTabState = 'closed'
  fourthTabState = 'closed'

  constructor(private mailService: MailService, private scroll: ScrollService) {
  }

  ngOnInit(): void {
    this.scroll.setScroll()
    if (window.innerWidth < 578) {
      this.mobile = true;
    }
    this.contactForm = new FormGroup({
      telefon: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*[0-9+ /-]*$"), Validators.minLength(2)]),
      name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      message: new FormControl('', [Validators.required, Validators.minLength(2)])
    });
  }

  public disabled: boolean = false;

  public config: SwiperConfigInterface = {
    slidesPerView: 1,
    spaceBetween: 4,
    centeredSlides: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: true,
    watchOverflow: false
  };

  @ViewChild(SwiperComponent) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;

  toggleSlidesPerView(): void {
    if (this.config.slidesPerView !== 1) {
      this.config.slidesPerView = 1;
    } else {
      this.config.slidesPerView = 2;
    }
  }

  get f() {
    return this.contactForm.controls;
  }

  chooseSection(section) {
    if (section == 'phone') {
      this.phoneSection = true
      this.contactSection = false;
      this.chatSection = false;
    } else if (section == 'contact') {
      this.contactSection = true
      this.phoneSection = false;
      this.chatSection = false;
    } else {
      this.chatSection = true
      this.contactSection = false;
      this.phoneSection = false;
    }
  }

  onResize(event) {
    if (event.target.innerWidth < 578) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  sendContact() {
    this.formTrue = true
    let formData = new FormData

    formData.append('telefon', this.f.telefon.value),
      formData.append('name', this.f.name.value),
      formData.append('message', this.f.message.value)

    this.formTrue = true;
    if (this.contactForm.valid) {
      console.log('success')
      this.formTrue = false;
      this.formSuccess = true;
      this.mailService.sendMail(formData).catch(error => {
        if (error) {
          this.formSuccess = false;
          console.log(error)
        }
      });

    } else {
      console.log("error");
    }
  }

  makeActive(type){
    if (type == 'one') {
      this.firstActive = true;
      this.secondActive = false;
      this.thirdActive = false;
      this.fourthActive = false
    } else if (type == 'two') {
      this.firstActive = false;
      this.secondActive = true;
      this.thirdActive = false;
      this.fourthActive = false
    }  else if (type == 'three') {
      this.firstActive = false;
      this.secondActive = false;
      this.thirdActive = true;
      this.fourthActive = false;
    } else if (type == 'four') {
      this.firstActive = false;
      this.secondActive = false;
      this.thirdActive = false;
      this.fourthActive = true;
    }
  }

  showtab(type) {
    if (type == 'one') {
      this.firstTabState = this.firstTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
    }
    if (type == 'two') {
      this.secondTabState = this.secondTabState === 'closed' ? 'open' : 'closed';
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
    }
    if (type == 'three') {
      this.thirdTabState = this.thirdTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
    }
  }

}
