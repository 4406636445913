<div class="main-wrapper">
    <div class="intro-default">
        <div class="container">
            <div class="image-wrapper">
                <img src="assets/maska.svg" alt="">
            </div>
        </div>
    </div>
    <div class="container">
        <h1 class="title-page">Werte</h1>
        <div class="main">
            <div class="section">
                <h2>Unsere Werte</h2>
                <p>Unsere Werte machen uns aus. Sie sind der Wind in unseren Segeln und die wichtigste Grundlage im
                    Daily Business. Für diese Werte stehen wir ein: Leidenschaft, Innovation, lösungsorientierte
                    Beratung, Zuverlässigkeit, Nachhaltigkeit und Treue.</p>
            </div>
            <div class="tabordion">
                <section id="section1" #sectionOne>
                    <label id="option1" (click)="showtab('firstTabState')"><span
                            [ngClass]="{rotate : states.firstTabState=='open'}">+</span>
                        <p>Leidenschaft</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.firstTabState>
                        <article>
                            <p>Unsere Leidenschaft zeichnet uns aus. Wir kommen unserer Mission, mehr Transparenz in die
                                Versicherungs- und Finanzwelt zu bringen, mit Begeisterung nach. Diese Leidenschaft
                                motiviert uns, stets auf der Höhe der Zeit zu sein. Ebenso leidenschaftlich verfolgen
                                wir Ihre Anliegen. Sie erhalten eine Beratung auf Grundlage aktueller Informationen, mit
                                der Sie Ihre Ziele erreichen können.
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section2" #sectionTwo>
                    <label id="option2" (click)="showtab('secondTabState')"><span
                            [ngClass]="{rotate : states.secondTabState=='open'}">+</span>
                        <p>Innovation</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.secondTabState>
                        <article>
                            <p>Jeder unserer Kunden ist einzigartig. Keine Lebensphase gleicht der anderen, alle Menschen
                                verfolgen ganz individuelle Wünsche und Ziele. Das erfordert ebenso einzigartige und
                                innovative Lösungen. Für unser ganzheitliches Finanzcoaching setzen wir daher innovative
                                technische Systeme ein. So können wir Ihre Situation genau analysieren und
                                massgeschneiderte Angebote für Sie finden.</p>
                        </article>
                    </div>
                </section>
                <section id="section3" #sectionThree>
                    <label id="option3" (click)="showtab('thirdTabState')"><span
                            [ngClass]="{rotate : states.thirdTabState=='open'}">+</span>
                        <p>Lösungsorientierte Beratung</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.thirdTabState>
                        <article>
                            <p>Unser Ziel ist es, stets passende Lösungen für Ihren Bedarf zu finden. Dabei orientieren
                                wir uns an Ihren ganz persönlichen Wünschen und Zielen. Wir hören Ihnen zu und
                                analysieren gemeinsam mit Ihnen Ihre individuelle Situation. Auf diese Weise legen wir
                                den konkreten Bedarf offen. Ob Sie Versicherungslücken schliessen möchten, nach einer
                                cleveren Anlagestrategie suchen oder ein Eigenheim finanzieren wollen - wir zeigen Ihnen
                                passende Lösungen auf.</p>
                        </article>
                    </div>
                </section>
                <section id="section4" #sectionFour>
                    <label id="option4" (click)="showtab('fourthTabState')"><span
                            [ngClass]="{rotate : states.fourthTabState=='open'}">+</span>
                        <p>Zuverlässigkeit</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.fourthTabState>
                        <article>
                            <p>Wir möchten, dass unsere Kunden sich stets auf uns verlassen können. Zuverlässigkeit
                                gehört daher zu unseren wichtigsten Werten. Welche Fragen Sie auch bezüglich
                                Versicherungen und Finanzen haben, wir sind immer für Sie da. Wir wissen, wie
                                unübersichtlich der Finanzmarkt für Laien sein kann. Unsere Berater beantworten daher
                                alle Fragen offen und transparent. Sind Sie sich einmal unsicher, scheuen Sie sich
                                nicht, einfach nachzufragen.</p>
                        </article>
                    </div>
                </section>
                <section id="section5" #sectionFive>
                    <label id="option5" (click)="showtab('fifthTabState')"><span
                            [ngClass]="{rotate : states.fifthTabState=='open'}">+</span>
                        <p>Nachhaltigkeit</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.fifthTabState>
                        <article>
                            <p>Ein weiterer Wert, der uns am Herzen liegt, ist Nachhaltigkeit. Wir interessieren uns
                                nicht für kurzfristige Lösungen, sondern möchten Produkte und Angebote finden, von denen
                                Sie langfristig profitieren. Unser Finanzcoaching hat immer Ihre Zukunft im Blick. Wir
                                entwickeln nachhaltige Strategien und Lösungsansätze, die auf lange Sicht funktionieren.
                            </p>
                        </article>
                    </div>
                </section>
                <section id="section6" #sectionSix>
                    <label id="option6" (click)="showtab('sixthTabState')"><span
                            [ngClass]="{rotate : states.sixthTabState=='open'}">+</span>
                        <p>Treue</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.sixthTabState>
                        <article>
                            <p>Wir sind unseren Kunden und unseren Partnern treu. Wir beraten Sie nicht nur bei
                                einzelnen Fragen, sondern sind in allen Lebensphasen für Sie da. Ob Sie heiraten wollen,
                                sich Nachwuchs ankündigt oder Sie mit der Firma expandieren wollen: Sie können sich
                                stets sicher sein, dass wir an Ihrer Seite stehen. Unsere hohe Verlässlichkeit bedeutet
                                mehr Planungssicherheit für Sie.
                            </p>
                        </article>
                    </div>
                </section>
            </div>
        </div>

        <div class="sidebar">
            <h2>Informationen anfordern</h2>
            <form class="form-wrapper" [formGroup]="financeForm" action="" *ngIf="!formSidebarSuccess">
                <div class="field">
                    <input formControlName="vorname" type="text" placeholder="Vorname"
                        [ngClass]="{'invalid': (f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)">
                        <span *ngIf="f.vorname.errors.required">Bitte Vorname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="nachname" type="text" placeholder="Nachname"
                        [ngClass]="{'invalid': (f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)">
                        <span *ngIf="f.nachname.errors.required">Bitte Nachname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="email" type="text" placeholder="E-Mail"
                        [ngClass]="{'invalid': (f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)">
                        <span *ngIf="f.email.errors.required">Bitte Email angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="telefon" type="text" placeholder="Telefon"
                        [ngClass]="{'invalid': (f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)">
                        <span *ngIf="f.telefon.errors.required">Bitte Telefon angeben</span>
                    </div>
                </div>
                <div class="checkbox-wrapper">
                    <div class="row">
                        <label class="checkbox">Krankenkasse
                            <input type="checkbox">
                            <span class="checkmark" #krankenkasse
                                (click)="selectType('Krankenkasse', krankenkasse)"></span>
                        </label>
                        <label class="checkbox">Sachversicherung
                            <input type="checkbox">
                            <span class="checkmark" #sachversicherung
                                (click)="selectType('Sachversicherung', sachversicherung)"></span>
                        </label>
                    </div>
                    <div class="row">
                        <label class="checkbox">Unfallversicherung
                            <input type="checkbox">
                            <span class="checkmark" #unfallversicherung
                                (click)="selectType('Unfallversicherung', unfallversicherung)"></span>
                        </label>
                        <label class="checkbox">Vorsorge
                            <input type="checkbox">
                            <span class="checkmark" #vorsorge (click)="selectType('Vorsorge', vorsorge)"></span>
                        </label>
                    </div>
                </div>
                <div class="field area">
                    <textarea formControlName="message" cdkAutosizeMinRows="110" placeholder="Nachricht"
                        cdkAutosizeMaxRows="120"
                        [ngClass]="{'invalid': (f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)}"></textarea>
                    <div class="error-msg"
                        *ngIf="(f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)">
                        <span *ngIf="f.message.errors.required">Bitte Nachricht eingeben</span>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <button class="classic-button" (click)="sendSidebar()">SENDEN</button>
                </div>
            </form>
            <div class="success-mess" *ngIf="formSidebarSuccess">
                Nachricht wurde erfolgreich übermittelt.
            </div>
        </div>
    </div>
</div>