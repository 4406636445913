import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-agenture',
  templateUrl: './agenture.component.html',
  styleUrls: ['./agenture.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        'height': '0px',
      })),
      state('open', style({
        'max-height': '3200px',
      })),
      transition('open <=> closed', animate(400)),
    ])
  ]
})

export class AgentureComponent implements OnInit {


  // google maps zoom level
  zoom: number = 17;

  // initial center position for the map
  lat: number = 47.083191;
  lng: number = 8.342170;

  markers: marker[] = [
  ]
  
  states = {
    'firstTabState' : 'closed',
    'secondTabState' : 'closed',
    'thirdTabState' : 'closed',
    'fourthTabState' : 'closed',
    'fifthTabState' : 'closed',
    'fifthOneTabState' : 'closed',
    'fifthTwoTabState' : 'closed',
    'fifthThreeTabState' : 'closed',
    'fifthFourTabState' : 'closed',
    'fifthFiveTabState': 'closed',
    'fifthSixTabState': 'closed',
    'sixthTabState' : 'closed',
    'seventhTabState' : 'closed',
    'newTabState' : 'closed',
    'tenTabState' : 'closed',
    'eightTabState': 'closed',
    'eightTabStates': 'closed',
    'nineTenTabState': 'closed',
    'sevenZeroTabState': 'closed',
    'eightZeroTabState': 'closed',
    'nineZeroTabState': 'closed',
    'sixthOneTabState': 'closed',
    'sixthTwoTabState': 'closed',
    'sevenOneTabState': 'closed',
    'eightOneTabState': 'closed',
    'eightTwoTabState': 'closed',
    'tenOneTabState': 'closed',
    'tenTwoTabState': 'closed',
    'tenThreeTabState': 'closed',
    'tenFourTabState': 'closed',
    'elevenOneTabState': 'closed',
    'elevenTwoTabState': 'closed',
    'elevenThreeTabState': 'closed',
    'elevenFourTabState': 'closed',
    'nineThreeThreeTabState': 'closed',
    'newOneTabState': 'closed',
    'sectioNewTwo': 'closed',
    'sectionNewThree': 'closed',
    'sectionNineThreeThreeTwo': 'closed',
    'sectionElevenTwoNew': 'closed',
    'sectioNewTwoNew': 'closed',
    'sectionNineOneZeroNew': 'closed',
    'newTwoTabState': 'closed',
    'newElevenThreeTabState': 'closed',
    'newLuzernOneTabState': 'closed',
    'newLuzernTwoTabState': 'closed',
    'newTabStateBuch': 'closed',
    'newTicinoOneTabState': 'closed',
    'newTicinoTwoTabState': 'closed',
    'newTicinoThreeTabState': 'closed',
    'fifthTwoNewTabState': 'closed',
    'fifthTwoNew1TabState': 'closed',
    'elevenFourTwoTabState': 'closed',
    'newTabStateKeyt': 'closed',
    'newTicinoFoueTabState': 'closed',
    'sectionNineThreeThreeThree': 'closed'
  }
  desktop: boolean = false
  mobile: boolean = false

  main: boolean = true
  zurich: boolean = false
  gallen: boolean = false
  nord: boolean = false
  zug: boolean = false
  zoffingen: boolean = false
  safenwil: boolean = false
  glattbrugg: boolean = false
  pfungen: boolean = false
  lausanne: boolean = false
  regensdorf: boolean = false
  bern: boolean = false
  luzern: boolean = false
  ticino: boolean = false
  selectedName: string = ''
  image: string = "url('/assets/aboutnew.jpg')";
  mobileImage: string = "url('/assets/aboutnew.jpg')";
  imagePosition: string = "50% 58%";

  constructor(private scroll: ScrollService, private routing: Router, private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.params.subscribe(param => {
      this.selectedName = param['name'];
      if(this.selectedName == 'olten') {
        this.lat = 47.347390;
        this.lng = 7.887870;
      }
      if(this.selectedName == 'st-gallen') {
        this.lat = 47.475240;
        this.lng = 9.484230;
      }
      if(this.selectedName == 'zofingen') {
        this.lat = 47.347390;
        this.lng = 7.934770;
      }
      if(this.selectedName == 'zurich-sud') {
        this.lat = 47.320370;
        this.lng = 8.522180;
      }
      if(this.selectedName == 'zurich-nord') {
        this.lat = 47.434800;
        this.lng = 8.560390;
      }
      if(this.selectedName == 'zug') {
        this.lat = 47.194117;
        this.lng = 8.485909;
      }
      if(this.selectedName == 'pfungen') {
        this.lat = 47.516087;
        this.lng = 8.6467;
      }
      if(this.selectedName == 'lausanne') {
        this.lat = 46.550010;
        this.lng = 6.574550;
      }
      if(this.selectedName == 'buchs') {
        this.lat = 47.456774;
        this.lng = 8.439381;
      }
      if(this.selectedName == 'ticino') {
        this.lat = 45.993695;
        this.lng = 8.943347;
      }
      if(this.selectedName == 'luzern') {
        this.lat = 47.093681;
        this.lng = 8.269951;
      }
      let marker = {
        lat:this.lat,
        lng: this.lng,
        label: 'A',
        draggable: false
      };
      this.markers.push(marker)
    })

    if (window.innerWidth < 578) {
      this.mobile = true;
    }else{
      this.desktop = true;
    }

    this.scroll.setScroll()
  }

  openInfo(element:HTMLElement){
      if(element.classList.contains('open')){
        if(window.innerWidth > 578){
          element.setAttribute("style", "height:48px; transition: height .35s ease-in-out;");
        }else{
          element.setAttribute("style", "height:40px; transition: height .35s ease-in-out;");
        }
        element.classList.remove("open");
      }
      else{
        element.setAttribute("style", "height:134px; transition: height .35s ease-in-out;");
        element.classList.add("open");
      }
  }

  onResize(event) {
    if (event.target.innerWidth < 578) {
      this.mobile = true;
      this.desktop = false;
    } else {
      this.mobile = false;
      this.desktop = true;
    }
  }
  
  changeAffiliate(type){
    if(type == 'main'){
      this.bern = false;
      this.main = true;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.luzern = false;
      this.ticino = false;
    }
    if(type == 'zurich'){
      this.main = false;
      this.luzern = false;
      this.bern = false;
      this.zurich = true;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.ticino = false;
    }
    if(type == 'gallen'){
      this.main = false;
      this.luzern = false;
      this.zurich = false;
      this.bern = false;
      this.gallen = true;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.ticino = false;
    }
    if(type == 'nord'){
      this.luzern = false;
      this.main = false;
      this.zurich = false;
      this.gallen = false;
      this.bern = false;
      this.nord = true;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.ticino = false;
    }
    if(type == 'zug'){
      this.main = false;
      this.luzern = false;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.bern = false;
      this.zug = true;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.ticino = false;
    }
    if(type == 'zoffingen'){
      this.main = false;
      this.luzern = false;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.bern = false;
      this.zoffingen = true;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.ticino = false;
    }
    if(type == 'safenwil'){
      this.main = false;
      this.luzern = false;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.bern = false;
      this.safenwil = true;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.ticino = false;
    }
    if(type == 'glattbrugg'){
      this.main = false;
      this.luzern = false;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.bern = false;
      this.glattbrugg = true;
      this.regensdorf = false;
      this.pfungen = false;
      this.ticino = false;
    }
    if(type == 'regensdorf'){
      this.main = false;
      this.luzern = false;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.bern = false;
      this.regensdorf = true;
      this.pfungen = false;
      this.ticino = false;
    }
    if(type == 'pfungen'){
      this.main = false;
      this.luzern = false;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.bern = false;
      this.pfungen = true;
      this.ticino = false;
    }
    if(type == 'bern'){
      this.main = false;
      this.luzern = false;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.ticino = false;
      this.bern = true;
    }
    if(type == 'luzern'){
      this.main = false;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.bern = false;
      this.ticino = false;
      this.luzern = true;
    }
    if(type == 'ticino'){
      this.main = false;
      this.zurich = false;
      this.gallen = false;
      this.nord = false;
      this.zug = false;
      this.zoffingen = false;
      this.safenwil = false;
      this.glattbrugg = false;
      this.regensdorf = false;
      this.pfungen = false;
      this.bern = false;
      this.ticino = true;
      this.luzern = false;
    }
  }
  
  showtab(type) {
    for (var key in this.states) {
      if(key == type){
        this.states[key]= this.states[key] === 'closed' ? 'open' : 'closed';
      }else{
        this.states[key] = 'closed'
      }
    }
  }



  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  // mapClicked($event: MouseEvent) {
  //   this.markers.push({
  //     lat: $event.coords.lat,
  //     lng: $event.coords.lng,
  //     draggable: true
  //   });
  // }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }


  style = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]

}
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
