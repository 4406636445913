<div class="main-wrapper">
    <div class="intro-default">
        <div class="location-wrapper">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [mapTypeControl]='false'
                [zoomControl]="true" [styles]="style" [scrollwheel]="false" [streetViewControl]="false">

                <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker(m.label, i)"
                    [latitude]="m.lat" [longitude]="m.lng" [label]="m.label" [markerDraggable]="m.draggable"
                    (dragEnd)="markerDragEnd(m, $event)">

                    <!-- <agm-info-window>
                  <strong>InfoWindow content</strong>
                </agm-info-window> -->

                </agm-marker>

                <!-- <agm-circle [latitude]="lat + 0.3" [longitude]="lng" 
                  [radius]="5000"
                  [fillColor]="'red'"
                  [circleDraggable]="true"
                  [editable]="true">
              </agm-circle> -->

            </agm-map>
        </div>
    </div>
    <div class="container">
        <div class="contact-wrapper">
            <div class="info">
                <h2>SSM Partner AG</h2>
                <p>Stationsstrasse 92</p>
                <p>CH-6023 Rothenburg</p>
                <p>Telefon: <a href="{{'tel:0412202050'}}">+41 (0)41 220 20 50</a></p>
                <p>E-mail: <a href="mailto:info@ssmpartner.ch">info@ssmpartner.ch</a></p>
            </div>
            <form class="contact-form-wrapper" [formGroup]="contactForm" action="" *ngIf="!formSuccess">
                <div class="left-side">
                    <div class="field">
                        <input formControlName="vorname" type="text" placeholder="Vorname"
                            [ngClass]="{'invalid': (f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && formTrue)}">
                        <div class="error-msg"
                            *ngIf="(f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && formTrue)">
                            <span *ngIf="f.vorname.errors.required">Bitte Vorname angeben</span>
                        </div>
                    </div>
                    <div class="field">
                        <input formControlName="nachname" type="text" placeholder="Nachname"
                            [ngClass]="{'invalid': (f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && formTrue)}">
                        <div class="error-msg"
                            *ngIf="(f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && formTrue)">
                            <span *ngIf="f.nachname.errors.required">Bitte Nachname angeben</span>
                        </div>
                    </div>
                    <div class="field">
                        <input formControlName="telefon" type="text" placeholder="Telefon"
                            [ngClass]="{'invalid': (f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && formTrue)}">
                        <div class="error-msg"
                            *ngIf="(f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && formTrue)">
                            <span *ngIf="f.telefon.errors.required">Bitte Telefon angeben</span>
                        </div>
                    </div>
                </div>
                <div class="right-side">
                    <div class="area-field">
                        <textarea formControlName="message" cdkAutosizeMinRows="110" placeholder="Nachricht"
                            cdkAutosizeMaxRows="120"
                            [ngClass]="{'invalid': (f.message.invalid && f.message.touched) || (f.message.invalid && formTrue)}"></textarea>
                        <div class="error-msg"
                            *ngIf="(f.message.invalid && f.message.touched) || (f.message.invalid && formTrue)">
                            <span *ngIf="f.message.errors.required">Bitte Nachricht eingeben</span>
                        </div>
                    </div>
                    <button class="classic-button" (click)="send()">SENDEN</button>
                </div>
            </form>
            <div class="success-mess" *ngIf="formSuccess">
                Nachricht wurde erfolgreich übermittelt.
            </div>
        </div>
    </div>
</div>