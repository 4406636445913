import { Component, OnInit } from '@angular/core';
import { MailService } from 'src/app/services/mail.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerForm: FormGroup
  formTrue:boolean = false
  formSuccess:boolean = false
  landingPage: boolean = false

  constructor(private mailService: MailService, private router : Router, private location: Location) { }

  ngOnInit(): void {
    this.footerForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z0-9. _]*[A-Za-z0-9][A-Za-z0-9. _]*$")]),
      telefon: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(2)]),
      message: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z0-9. _]*[A-Za-z0-9][A-Za-z0-9. _]*$"), Validators.minLength(2)]),
    });
    if(this.location.path() != '/karriere-pusher'){
      this.landingPage = false;
    }else{
      this.landingPage = true;
    }
  }

  get f() {
    return this.footerForm.controls;
  }  
  
  send(){
    this.formTrue = true
    let formData = new FormData

    formData.append('name', this.f.name.value),
    formData.append('telefon', this.f.telefon.value),
    formData.append('message', this.f.message.value)

    this.formTrue = true;
    if (this.footerForm.valid) {
      console.log('success')
      this.formTrue = false;
      this.formSuccess = true;
      this.mailService.sendMail(formData).catch(error => {
        if(error){
          this.formSuccess = false;
          console.log(error)
        }
      });
    } else {
      console.log("error");
    }
  }

}
