<div class="main-wrapper">
    <div class="intro-default">
        <div class="container">
            <div class="section-default">
                <h2>In jeder Lebensphase gut beraten</h2>
                <p>Jede Lebensphase bringt Veränderungen mit sich. Mit dem Übergang in einen neuen Lebensabschnitt
                    ändern sich auch Ihre Bedürfnisse. Wir als Unternehmen und ganzheitlicher Finanzcoach freuen uns,
                    Sie in jeder Lebensphase begleiten zu dürfen. Gemeinsam mit Ihnen arbeiten wir Ihre Wünsche und
                    Ziele heraus und finden Dienstleister und Angebote, die zu Ihnen passen.</p>
            </div>
            <div class="image-wrapper">
                <img src="assets/maska.svg" alt="">
            </div>
        </div>
    </div>
    <div class="container">
        <div class="theme-cards-wrapper">
            <div class="theme-card" [routerLink]="['/lebensphase/single']">
                <div class="img-wrapper">
                    <img src="assets/single-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Single</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensphase/kinderloses-paar']">
                <div class="img-wrapper">
                    <img src="assets/kindeloses-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Kinderloses Paar</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensphase/student']">
                <div class="img-wrapper">
                    <img src="assets/student-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Student</h2>
                </div>
            </div>

            <div class="theme-card" [routerLink]="['/lebensphase/expats']">
                <div class="img-wrapper">
                    <img src="assets/expats-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Expats</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensphase/junge-familie']">
                <div class="img-wrapper">
                    <img src="assets/junge-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Junge Familie</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensphase/familie-mit-alteren-kinder']">
                <div class="img-wrapper">
                    <img src="assets/familie-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Familie mit älteren Kindern</h2>
                </div>
            </div>

            <div class="theme-card">
                <div class="img-wrapper" [routerLink]="['/lebensphase/patchwork-family']">
                    <img src="assets/patchwork-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Patchwork-Family</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensphase/geschiedene-und-alleinerziehende']">
                <div class="img-wrapper">
                    <img src="assets/geschiendene-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Geschiedene und Alleinerziehende</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensphase/vor-der-pensionierung']">
                <div class="img-wrapper">
                    <img src="assets/vonder-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Vor der Pensionierung</h2>
                </div>
            </div>
        </div>
    </div>
</div>