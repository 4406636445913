import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-lebensereignis-overview',
  templateUrl: './lebensereignis-overview.component.html',
  styleUrls: ['./lebensereignis-overview.component.scss']
})
export class LebensereignisOverviewComponent implements OnInit {
  component;
  selectedName;
  desktop: boolean = false
  mobile: boolean = false

  constructor(private routing: Router, private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.params.subscribe(param => {
      this.selectedName = param['name'];
    })

    for (let i = 0; i < this.components.length; i++) {
      const element = this.components[i];
      if (element.urlName == this.selectedName) {
        this.component = element;
      }
    }

    if (window.innerWidth < 578) {
      this.mobile = true;
    } else {
      this.desktop = true;
    }

  }

  onResize(event) {
    if (event.target.innerWidth < 578) {
      this.mobile = true;
      this.desktop = false;
    } else {
      this.mobile = false;
      this.desktop = true;
    }
  }

  components = [
    {
      title: 'Ausbildung',
      urlName: 'ausbildung',
      image: "url('/assets/ausbildung.jpg')",
      mobileImage: "url('/assets/ausbildung-mobile.jpg')",
      imagePosition: "50% 65%",
      content: "<h2 class='title-text'>Aus- und Weiterbildung: Investieren Sie in Ihre Zukunft!</h2> "+
      "<p class='p-text'>Aus- und Weiterbildungen sind eine Investition in Ihre Zukunft. Unser "+
      "Lebensereignis-Ratgeber zeigt Ihnen, welche Ausbildungskosten auf Sie zukommen und welche "+
      "Finanzierungsmöglichkeiten es gibt. Selbstverständlich beraten wir Sie auch persönlich. Gerne stehen wir "+
      "auch jungen Eltern zur Seite, die für die Ausbildung Ihrer Kinder vorsorgen möchten.</p> "+
      "<h2 class='title-text'>Welche Kosten kommen während Aus- und Weiterbildung auf Sie zu?</h2> "+
      "<p class='p-text'>Die genauen Kosten für ein Studium oder eine Weiterbildung unterscheiden sich je nach "+
      "Bildungseinrichtung, Ort, Studienrichtung und Länge des Studiengangs. Zu den reinen Ausbildungskosten "+
      "kommen die allgemeinen Lebenshaltungskosten. Insgesamt müssen Sie mit etwa CHF 20'000.- bis CHF 30'000.- "+
      "pro Jahr rechnen. </p> "+
      "<p class='p-text'>Zu den Aus- und Weiterbildungskosten zählen: </p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Studien- und Semestergebühren</p><p class='sub-p'><span class='line'>-</span> Aufnahme- und Prüfungsgebühren</p>"+
      "<p class='sub-p'><span class='line'>-</span> Studienmaterial und Heimausstattung wie PC, Drucker, Scanner und Internetanschluss</p>"+
      "<p class='sub-p'><span class='line'>-</span> Beiträge für Bibliotheken, studentische Organisationen und Verbände</p>"+
      "<p class='sub-p'><span class='line'>-</span> Kosten für Exkursionen und spezielle Lehrveranstaltungen</p>"+
      "<p class='sub-p'><span class='line'>-</span> Fahrtkosten</p></p> "+
      "<h2 class='title-text'>Wie können Sie Ihre Aus- und Weiterbildung finanzieren?</h2> "+
      "<p class='p-text'>Zur Ausbildungsfinanzierung bestehen verschiedene Möglichkeiten:</p> "+
      "<p class='p-text'>1. Finanzielle Unterstützung durch die Eltern: Eltern sind gesetzlich verpflichtet, ihren "+
      "Kindern die erste berufliche Ausbildung zu finanzieren. Das gilt auch für ein ans Matura anschliessendes "+
      "Studium. Sind die Eltern dazu finanziell nicht in der Lage, bestehen Alternativen.</p> "+
      "<p class='p-text'>2. Stipendien und Bildungsdarlehen der Kantone: Bei geringem Einkommen oder Vermögen der "+
      "Eltern haben Studierende ein Anrecht auf staatliche Stipendien oder Bildungsdarlehen. Stipendien müssen "+
      "in der Regel nicht zurückgezahlt werden. Einige Kantone verlangen eine teilweise Rückzahlung, wenn die "+
      "Bezieher nach dem Studium in guten finanziellen Verhältnissen leben. Bildungsdarlehen sind meist "+
      "inklusive Zinsen zurückzuzahlen. Für die Vergabe legt jeder Kanton eigene Regeln fest.</p> "+
      "<p class='p-text'>3. Teilzeit-Job: Rund drei Viertel aller Studierenden sind in der Schweiz nebenbei "+
      "erwerbstätig. Ein Teilzeit-Job bessert die Haushaltskasse auf, kostet aber auch Zeit und kann zur "+
      "Studienverlängerung führen.</p> "+
      "<p class='p-text'>4. Weitere Finanzierungsmöglichkeiten: Neben den Kantonen bieten in einigen Fällen auch "+
      "der Bund oder private Stiftungen und Fonds Beihilfe zu den Ausbildungskosten. Einzelne Hochschulen "+
      "unterhalten ebenfalls Fonds zur Unterstützung von Studierenden. Zudem besteht für einige Studierende die "+
      "Möglichkeit, sich von den Studiengebühren befreien zu lassen oder diese zu reduzieren. Unsere "+
      "Finanzcoaches beraten Sie gerne und und helfen Ihnen, die passende Finanzierungsmöglichkeit zu finden. "+
      "</p>"
    },
    {
      title:"Autokauf",
      urlName:"autokauf",
      image: "url('/assets/autokauf.jpg')",
      mobileImage: "url('/assets/autokauf-mobile.jpg')",
      imagePosition: "50% 25%",
      content:"<h2 class='title-text'>Worauf Sie beim Autokauf achten sollten</h2> "+
      "<p class='p-text'>Passt Ihr altes Auto nicht mehr zu Ihren Bedürfnissen, muss ein Neues her. Vor dem "+
      "Autokauf gibt es vieles zu klären: Welches Modell eignet sich am besten für Ihre Lebenssituation? Soll "+
      "es ein Neuwagen oder ein Occasionswagen werden? Wie können Sie den Kauf am besten finanzieren? Unsere "+
      "Finanzcoaches beraten Sie ganzheitlich unter Berücksichtigung Ihrer Wünsche und weiteren Pläne. Was Sie "+
      "beim Autokauf ebenfalls beachten sollten, zeigt unser Ratgeber.</p> "+
      "<h2 class='title-text'>Was Sie vor dem Autokauf klären sollten</h2> "+
      "<p class='p-text'>Ein Autokauf bedeutet eine grosse Investition und sollte entsprechend gut überlegt sein. "+
      "Stellen Sie sich vor dem Kauf daher folgende Fragen:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Welches Auto passt zu Ihnen? </p><p class='sub-p'><span class='line'>-</span> Soll es ein Neuwagen oder ein Occasionswagen sein? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Wo kaufen Sie das Auto? Beim Fachhändler, von Privat oder im Ausland?</p><p class='sub-p'><span class='line'>-</span> Wie bezahlen Sie das Auto? Wollen Sie bar bezahlen, den Wagen per Ratenzahlung finanzieren oder Leasen? </p></p> "+
      "<h2 class='title-text'>Probefahrt</h2> "+
      "<p class='p-text'>Ohne Probefahrt sollten Sie sich nicht auf den Autokauf einlassen. Idealerweise findet die "+
      "Probefahrt bei möglichst gutem Wetter, bei Tageslicht und ausserhalb der Stosszeiten statt. Vergessen "+
      "Sie nicht, Ihre Ausweispapiere und Ihren Führerschein zum Termin mitzubringen. Das Auto muss für die "+
      "Probefahrt zugelassen sein. Planen Sie die Route im Vorfeld. Zu Beginn sollten Sie möglichst ruhige "+
      "Strassen befahren, um sich ganz auf das Fahrverhalten konzentrieren zu können. Am besten nehmen Sie eine "+
      "weitere Person zur Probefahrt mit, denn zwei Menschen bemerken mehr als einer.</p> "+
      "<p class='p-text'>Gerne klären wir mit Ihnen, welche Versicherungen Sie für die Probefahrt benötigen und wie "+
      "es mit dem Selbstbehalt aussieht, falls es zum Unfall kommen sollte.</p> "+
      "<h2 class='title-text'>Budgetplanung und Preisverhandlungen</h2> "+
      "<p class='p-text'>Legen Sie vor dem Autokauf Ihr Budget fest. Wir unterstützen Sie bei der Finanzplanung und "+
      "informieren Sie gerne, welche Preise für das gewählte Modell angemessen sind. Bei der Preisverhandlung "+
      "mit dem Verkäufer sollten Sie sachlich und professionell auftreten. Setzen Sie Ihr erstes Angebot etwa "+
      "25 bis 30 Prozent unter dem Verkaufspreis an. Lassen Sie sich nicht unter Druck setzen und versuchen "+
      "Sie, einen guten Kompromiss zu finden. Erscheint Ihnen das letzte Angebot des Verkäufers noch zu hoch, "+
      "fragen Sie nach Zusatzleistungen wie Tankgutscheinen oder einem Satz Winterreifen.</p> "+
      "<h2 class='title-text'>Passende Motorfahrzeug-Versicherung finden</h2> "+
      "<p class='p-text'>Unsere Finanzcoaches erklären Ihnen gerne, worauf es beim Kaufvertrag für das Auto zu "+
      "achten gilt. Ausserdem unterstützen wir Sie bei der Suche nach den passenden Versicherungen für Ihre "+
      "Fahrzeuge. Dazu gehören:</p> "+
      "<p class='p-text li-text'>- Haftpflichtversicherungen<br>- Kaskoversicherungen (Teil- oder Vollkasko)<br>- Assistance<br>- Insassenversicherungen</p>"
    },
    {
      title:"Bewerbung",
      urlName:"bewerbung",
      image: "url('/assets/bewerbung.jpg')",
      mobileImage: "url('/assets/bewerbung-mobile.jpg')",
      imagePosition: "50% 45%",
      content: "<h2 class='title-text'>Neuer Job: Tipps für eine erfolgreiche Bewerbung</h2> "+
      "<p class='p-text'>Haben Sie Ihre Ausbildung abgeschlossen? Oder sehnen Sie sich nach neuen beruflichen "+
      "Herausforderungen? Bevor Sie einen neuen Job antreten können, gilt es zunächst, die Bewerbungsphase zu "+
      "meistern. Das beginnt mit einer geordneten Bewerbungsmappe und einem Motivationsschreiben, welches das "+
      "Interesse des gewünschten Arbeitgebers weckt. Ein überzeugendes Dossier verhilft Ihnen zum "+
      "Bewerbungsgespräch. Möchten Sie Ihren zukünftigen Chef von Ihren Qualitäten überzeugen, sollten Sie sich "+
      "jetzt von Ihrer besten Seite zeigen.</p> "+
      "<p class='p-text'>Hier finden Sie Tipps für eine erfolgreiche Bewerbung für einen neuen Job. </p> "+
      "<h2 class='title-text'>Die Bewerbungsunterlagen </h2> "+
      "<p class='p-text'>In eine vollständige Bewerbungsmappe gehören die folgenden Unterlagen:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> ein einseitiges Anschreiben (Bewerbungsbrief)</p><p class='sub-p'><span class='line'>-</span> optional ein Deckblatt</p>"+
      "<p class='sub-p'><span class='line'>-</span> ein Bewerbungsfoto</p>"+
      "<p class='sub-p'><span class='line'>-</span> ein maximal zweiseitiger Lebenslauf (Curriculum Vitae)</p><p class='sub-p'><span class='line'>-</span> die Dritte Seite mit Kurzprofil, Projektliste und Motivationsschreiben </p>"+
      "<p class='sub-p'><span class='line'>-</span> Beilagen wie Zeugniskopien, Empfehlungsschreiben und Referenzen</p></p>"+
      "<h2 class='title-text'>Was Sie beim Bewerbungsbrief und Motivationsschreiben beachten sollten</h2> "+
      "<p class='p-text'>Der Bewerbungsbrief hat zum Ziel, das Interesse des Lesers zu wecken. Es soll den "+
      "Arbeitgeber dazu bewegen, sich die weiteren Bewerbungsunterlagen anzusehen. Der Bewerbungsbrief sollte "+
      "Ihre Persönlichkeit in den Fokus stellen, dabei aber stets den Bezug zum geforderten Jobprofil "+
      "herstellen. Beantworten Sie dabei Fragen wie: Warum streben Sie genau diese Position an? Warum möchten "+
      "Sie für das jeweilige Unternehmen arbeiten? Was begeistert Sie an diesem Beruf? Wieso sind Sie der "+
      "ideale Bewerber für den Posten? Belegen Sie Ihre Fähigkeiten mit Beispielen aus dem Berufs- oder "+
      "Ausbildungsalltag. Statt eines Bewerbungsbriefs wünschen einige Arbeitgeber auch ein "+
      "Motivationsschreiben, das auf die gleichen Fragen eingeht.</p> "+
      "<h2 class='title-text'>Lebenslauf und Beilagen</h2> "+
      "<p class='p-text'>Der Lebenslauf soll es dem Leser ermöglichen, sich schnell einen Überblick über Ihren "+
      "beruflichen Werdegang zu verschaffen. Führen Sie hier Ihre bisherigen Tätigkeiten auf. Eventuelle Lücken "+
      "sollten Sie plausibel erklären können. Beilagen wie Schul- und Arbeitszeugnisse, Empfehlungsschreiben "+
      "und Referenzen helfen dem Arbeitgeber, sich ein Bild von Ihrem Ausbildungsstand und Ihrer "+
      "Arbeitseinstellung zu machen.</p> "+
      "<h2 class='title-text'>So überzeugen Sie im Bewerbungsgespräch </h2> "+
      "<p class='p-text'>Hat Ihre Bewerbungsmappe überzeugt, lädt der Arbeitgeber Sie zu einem Bewerbungsgespräch "+
      "ein. Einen professionellen Eindruck machen Sie durch ein ordentliches, seriöses Erscheinungsbild und "+
      "gute Vorbereitung. Informieren Sie sich über das jeweilige Unternehmen und überlegen Sie, warum gerade "+
      "Sie der richtige Kandidat für die ausgeschriebene Position sind. Denken Sie auch darüber nach, welche "+
      "Fragen Sie stellen können. Fragen zeugen von Interesse.</p>"
    },
    {
      title:"Clever Sparen",
      urlName:"clever-sparen",
      image: "url('/assets/cleversparen.jpg')",
      mobileImage: "url('/assets/cleversparen-mobile.jpg')",
      imagePosition: "50% 35%",
      content:"<h2 class='title-text'>Clever sparen: Einfache Tricks für mehr Geld in der Haushaltskasse</h2> "+
      "<p class='p-text'>Wer spart, hat mehr Geld zu Verfügung als jemand, der nicht spart. Wir zeigen Ihnen gerne, "+
      "wie Sie im Alltag Geld zur Seite legen können. Unsere Finanzcoaches gehen mit Ihnen alle Einnahmen und "+
      "Ausgaben durch und ermitteln Ihr Sparpotenzial. Darüber hinaus zeigen wir Ihnen, wie Sie Ihr Erspartes "+
      "gewinnbringend anlegen, damit Sie sich auch in Zukunft Ihre Träume erfüllen können.</p> "+
      "<h2 class='title-text'>Wir helfen Ihnen, Ihr Sparpotenzial zu entdecken</h2> "+
      "<p class='p-text'>Die alltäglichen Ausgaben lassen sich bereits mit ein paar einfachen Tricks reduzieren. "+
      "Gemeinsam mit Ihnen finden wir heraus, wie Sie sparen können, ohne sich dabei stark einschränken zu "+
      "müssen. Umso mehr Geld bleibt Ihnen, um sich die wirklich grossen Wünsche zu erfüllen. </p> "+
      "<h2 class='title-text'>Sparpotenzial findet sich in so gut wie allen Bereichen des Lebens:</h2> "+
      "<p class='p-text'><b>1. Wohnen und Energie</b><br>Ganz gleich, ob Sie in einer Mietwohnung leben oder im Eigenheim: "+
      "Sparen Sie Energie, lassen sich die Nebenkosten senken. Reduzieren Sie durch einen speziellen Duschkopf "+
      "den Wasserverbrauch, können Sie bereits bis zu CHF 300.00 im Jahr sparen. Noch mehr Einsparungen sind "+
      "möglich, wenn Sie bewusst heizen und lüften.</p> "+
      "<p class='p-text'><b>2. Mobilität</b><br>Wie häufig müssen Sie wirklich mit dem Auto unterwegs sein? "+
      "Welche Wege können Sie mit dem Fahrrad oder zu Fuss zurücklegen? Ist die Nutzung der öffentlichen "+
      "Verkehrsmittel vielleicht sogar günstiger für Sie? Wir helfen Ihnen gerne dabei, Ihre Mobilitätskosten "+
      "durchzurechnen.</p> "+
      "<p class='p-text'><b>3. Freizeit und Unterhaltung</b><br>Möchten Sie eine Mitgliedschaft im Fitness-Studio "+
      "abschliessen? Prüfen Sie "+
      "doch zunächst, ob es nicht günstigere Möglichkeiten gibt, sich fit zu halten. Eventuell übernimmt Ihre "+
      "Krankenversicherung einen Anteil an einem Fitness-Abo zur Gesundheitsvorsorge. Sparen können Sie in der "+
      "Freizeit auch, wenn Sie Ihre Abos kritisch prüfen: Welche benötigen Sie wirklich, welche können Sie "+
      "kündigen? Durch die Einsparungen bleibt mehr Geld für Unternehmungen mit der Familie.</p> "+
      "<p class='p-text'><b>4. Shopping</b><br>Beim Einkaufen können Sie am meisten sparen, wenn Sie "+
      "Spontankäufe vermeiden. Machen Sie sich Gedanken darüber, was Sie wirklich brauchen. Gehen Sie nur noch "+
      "mit Einkaufszettel in den Supermarkt. Das erleichtert es, spontanen Verlockungen zu widerstehen. Stehen "+
      "grössere Anschaffungen an, muss es nicht immer Neuware sein. Schauen Sie, ob sich ein günstiges Angebot "+
      "aus zweiter Hand finden lässt.</p> "+
      "<p class='p-text'><b>5. Versicherungen</b><br>Welche Versicherungen benötigen Sie und Ihre Familie wirklich? Haben Sie "+
      "tatsächlich die günstigsten Tarife abgeschlossen oder können Sie bei einem anderen Anbieter deutlich "+
      "sparen? Unsere unabhängigen Finanzcoaches gehen Ihre Versicherungen mit Ihnen durch und finden die "+
      "besten Produkte für Ihre Bedürfnisse. </p>"
    },
    {
      title:"Eigenheim",
      urlName:"eigenheim",
      image: "url('/assets/eigenheim.jpg')",
      mobileImage: "url('/assets/eigenheim-mobile.jpg')",
      imagePosition: "50% 35%",
      content: "<h2 class='title-text'>Eigenheim: Mit guter Planung ins eigene Zuhause</h2> "+
      "<p class='p-text'>Die eigenen vier Wände bedeuten Freiheit für Sie und Ihre Familie. Auch als Altersvorsorge "+
      "und Investition in die Zukunft sind Immobilien interessant. Träumen Sie vom Eigenheim, muss zunächst die "+
      "Finanzierung geklärt werden. Unsere unabhängigen Finanzcoaches unterstützen Sie dabei, eine geeignete "+
      "Hypothek zu finden. Gerne erklären wir Ihnen auch, was Sie bei der Unterzeichnung des Kaufvertrags "+
      "beachten müssen. In unseren Ratgeber können Sie sich über die wichtigsten Details zur "+
      "Immobilienfinanzierung informieren. </p> "+
      "<h2 class='title-text'>Was Sie über die Eigenheimfinanzierung unbedingt wissen sollten</h2> "+
      "<h2 class='title-text'>1. Budgetrahmen festlegen</h2> "+
      "<p class='p-text'>Ganz gleich, ob Sie in eine Eigentumswohnung ziehen, ein Haus "+
      "kaufen oder ein Eigenheim bauen möchten: Bevor Sie auf die Suche nach einer geeigneten "+
      "Immobilienfinanzierung gehen, müssen Sie zunächst Ihren Budgetrahmen festlegen. Dieser richtet sich nach "+
      "dem vorhandenen Eigenkapital und dem jährlichen Nettoeinkommen.</p> "+
      "<p>Hypotheken decken in der Schweiz "+
      "maximal 80 Prozent des Objektwertes eines Eigenheims. Ihr Erspartes sollte also ausreichen, um "+
      "mindestens 20 Prozent der Kosten selbst decken zu können. Darüber hinaus sollten die jährlichen "+
      "Belastungen durch das Eigenheim 35 Prozent Ihres Nettoeinkommens nicht übersteigen.</p> "+
      "<h2 class='title-text'>2. Kredit beantragen</h2> "+
      "<p class='p-text'>Die Suche nach einer passenden Hypothek führt die meisten "+
      "Verbraucher zur Bank. Es gibt jedoch Alternativen: Auch Versicherungsgesellschaften, Pensionskassen oder "+
      "Ihr Arbeitgeber können zur Immobilienfinanzierung beitragen. Vielleicht können Sie auch ein "+
      "Privatdarlehen aufnehmen. Wir prüfen Ihre Möglichkeiten und finden die beste Eigenheimfinanzierung für "+
      "Sie. Ausserdem unterstützen wir Sie dabei, verschiedene Kreditangebote zu vergleichen.</p> "+
      "<p class='title-text'>Entscheiden Sie sich für eine Hypothek von der Bank, benötigen Sie in der Regel die "+
      "folgenden Unterlagen: </p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Lohnausweis und aktuelle Steuererklärung</p><p class='sub-p'><span class='line'>-</span> Betreibungsauszug</p>"+
      "<p class='sub-p'><span class='line'>-</span> Dokumente zum Objekt mit Grundriss und gegebenenfalls Verkaufsdokumentation</p>"+
      "<p class='sub-p'><span class='line'>-</span> sofern verfügbar: Grundbuchauszug, Entwurf des Kaufvertrags, Schätzung der kantonalen Gebäudeversicherung</p></p> "+
      "<h2 class='title-text'>3. Kaufvertrag prüfen</h2> "+
      "<p class='p-text'>Bevor Sie den Kaufvertrag unterzeichnen, sollten Sie ihn von einer Fachperson prüfen "+
      "lassen. Das kann zum Beispiel ein Immobilientreuhänder oder ein Anwalt übernehmen. Wir helfen Ihnen "+
      "gerne bei dieser Aufgabe.</p> "+
      "<h2 class='title-text'>4. Ausgaben im Griff behalten</h2> "+
      "<p class='p-text'>Damit Sie Ihre Hypothek nach dem Einzug ins neue Heim auch zuverlässig zurückzahlen "+
      "können, sollten Sie Ihre Ausgaben im Griff behalten. Am besten legen Sie jeden Monat einen Betrag für "+
      "die regelmässigen Kosten Ihres Eigenheims zurück. </p> "+
      "<p class='p-text'>Schweizer Banken bieten verschiedene Hypothekarmodelle an. Bei einigen ist es möglich, das "+
      "Modell während der Laufzeit zu wechseln. Auf diese Weise können Sie Ihre Zinskosten optimieren. Wir "+
      "zeigen Ihnen Ihre Möglichkeiten.</p>"
    },
    {
      title:"Geburt",
      urlName:"geburt",
      image: "url('/assets/geburt.jpg')",
      mobileImage: "url('/assets/geburt-mobile.jpg')",
      imagePosition: "50% 45%",
      content: "<h2 class='title-text'>Geburt: Kümmern Sie sich frühzeitig um die Absicherung Ihres Kindes</h2> "+
      "<p class='p-text'>Die Geburt eines Kindes ist ein unvergessliches Ereignis. Mit dem Familiennachwuchs kommen "+
      "jedoch auch viele Fragen und Herausforderungen auf die Eltern zu. Schliesslich möchten Sie Ihrem Kind "+
      "die besten Voraussetzungen bieten und eine optimale Entwicklung ermöglichen. Dazu gehört es auch, sich "+
      "frühzeitig um die nötigen Versicherungen und die finanzielle Sicherheit Ihrer Kinder zu kümmern. Wir "+
      "beraten Sie gerne und unterstützen Sie dabei, Ihr Kind bestmöglich gegen Risiken abzusichern.</p> "+
      "<h2 class='title-text'>Die nötige Ausrüstung für Ihren Nachwuchs </h2> "+
      "<p class='p-text'>Kündigt sich Nachwuchs an, werden eine Menge Neuanschaffungen nötig. Zur Erstausstattung "+
      "gehören Möbel wie Wickeltisch und Kinderkrippe, Kleidung, Lätzchen, Windeln und natürlich erstes "+
      "Spielzeug. Einen Teil der Ausrüstung bekommen Sie eventuell geschenkt oder können ihn von Verwandten und "+
      "Freunden übernehmen. Dennoch bedeutet die Geburt eines Kindes immer auch eine grosse Investition. Als "+
      "ganzheitlicher Finanzdienstleister helfen wir Ihnen, sich auf diesen Moment finanziell vorzubereiten. "+
      "Stimmen die Finanzen, können Sie der Geburt gelassener entgegensehen.</p> "+
      "<h2 class='title-text'>Vorgeburtliche Krankenversicherung </h2> "+
      "<p class='p-text'>Melden Sie Ihr Kind schon vor der Geburt bei einer Krankenkasse an, können Sie die Zeit "+
      "danach unbeschwerter geniessen. Kommt Ihr Kind krank zur Welt, ist es bereits abgesichert. Die "+
      "vorgeburtliche Anmeldung ist vor allem in der freiwilligen Zusatzversicherung von Vorteil. Anders als in "+
      "der obligatorischen Grundversicherung besteht dort nämlich keine Aufnahmepflicht. Die Krankenkassen "+
      "können die Aufnahme Ihres Kindes ohne Angabe von Gründen ablehnen. Mit einer vorgeburtlichen Anmeldung "+
      "beugen Sie daher Versicherungslücken vor. Versichern Sie Ihren Nachwuchs bei Ihrer eigenen Krankenkasse, "+
      "profitieren Sie oftmals von einem Familienrabatt. Eventuell können Sie durch den Wechsel zu einer "+
      "anderen Krankenversicherung aber noch mehr sparen. Wir helfen Ihnen, die passenden Tarife für Ihre "+
      "Familie zu finden.</p> "+
      "<h2 class='title-text'>Finanzielle Absicherung für junge Familien</h2> "+
      "<p class='p-text'>Eventualitäten vorbereitet sein. Wir beraten Sie zu Unfallversicherungen für Kinder und "+
      "Mütter, zu Invaliditäts- und Pflegekapital und Tagegeldversicherungen. Häufig ist zudem der Abschluss "+
      "von Spezialversicherungen für Aus- und Weiterbildung sinnvoll. Wir zeigen Ihnen, wie Sie Vermögen "+
      "aufbauen können, um Ihrem Kind einen idealen Start in die Zukunft zu ermöglichen. </p>"
    },
    {
      title:"Hochzeit",
      urlName:"hochzeit",
      image: "url('/assets/hochzeit.jpg')",
      mobileImage: "url('/assets/hochzeit-mobile.jpg')",
      imagePosition: "50% 25%",
      content: "<h2 class='title-text'>Hochzeit: Gut geplant den schönsten Tag im Leben geniessen</h2> "+
      "<p class='p-text'>Damit ein Brautpaar seinen grossen Tag unbeschwert geniessen kann, will eine Hochzeit gut "+
      "geplant sein. Beginnen Sie bereits frühzeitig mit den Vorbereitungen, wenn Sie Stress vermeiden wollen. "+
      "Wir unterstützen Sie gerne und klären mit Ihnen alle finanziellen und versicherungstechnischen Fragen, "+
      "die Sie vor und nach der Eheschliessung beachten müssen. Unser Ratgeber gibt Ihnen wichtige Tipps, wie "+
      "Sie das Hochzeitsfest entspannt planen können. </p> "+
      "<h2 class='title-text'>Was ist vor dem Fest zu erledigen?</h2> "+
      "<p class='p-text'>Möchten Sie den Bund fürs Leben schliessen, überlegen Sie möglichst früh, wie Sie diesen "+
      "Anlass feiern wollen. Soll die Hochzeit im engsten Familienkreis stattfinden? Oder möchten Sie die "+
      "Eheschliessung mit einem rauschenden Fest begehen? Eventuell träumen Sie auch von einer Hochzeit an "+
      "einem ganz besonderen Ort, etwa im Ausland. Eine Traumhochzeit kostet selbstverständlich Geld. Wir "+
      "unterstützen Sie dabei, Ihr Hochzeitsbudget zu planen. Unter Berücksichtigung Ihrer finanziellen "+
      "Situation und Ihrer Wünsche finden wir passende Finanzierungsmöglichkeiten und zeigen Ihnen, wie Sie "+
      "sparen können.</p> "+
      "<h2 class='title-text'>Die Hochzeits-Checkliste</h2> "+
      "<p class='p-text'>Steht das Budget, geht es an die Auswahl der Location, des Farbkonzepts und eventuell "+
      "eines Hochzeitsmottos. Auch darüber hinaus gibt es noch eine Menge zu erledigen:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Trauzeugen auswählen</p><p class='sub-p'><span class='line'>-</span> Ablaufplan, Farbschema und gegebenenfalls Motto festlegen</p>"+
      "<p class='sub-p'><span class='line'>-</span> Dienstleister für Catering, Blumenschmuck, Fahrzeuge, Musik und Fotografie buchen</p>"+
      "<p class='sub-p'><span class='line'>-</span> Hochzeitskleid und Anzug kaufen</p><p class='sub-p'><span class='line'>-</span> Trauringe auswählen</p><p class='sub-p'><span class='line'>-</span> Hochzeitstorte bestellen</p>"+
      "<p class='sub-p'><span class='line'>-</span> Gästeliste und Sitzplan festlegen</p>"+
      "<p class='sub-p'><span class='line'>-</span> Einladungen drucken und verschicken</p><p class='sub-p'><span class='line'>-</span> Menükarten, eventuell Programmhefte und Danksagungen drucken</p>"+
      "<p class='sub-p'><span class='line'>-</span> Hochzeitsreise buchen</p><p class='sub-p'><span class='line'>-</span> Geschenkliste aufsetzen und an Gäste verteilen</p><p class='sub-p'><span class='line'>-</span> Helfer für den Hochzeitstag auswählen</p>"+
      "<p class='sub-p'><span class='line'>-</span> kleine Geschenke für die Helfer kaufen</p></p> "+
      "<h2 class='title-text'>Nach der Hochzeit</h2> "+
      "<p class='p-text'>Der grosse Tag selbst wird dank guter Planung hoffentlich ein voller Erfolg. Nach der "+
      "Hochzeit geht es für viele Brautpaare direkt in die Flitterwochen. Spätestens nach der Rückkehr stehen "+
      "jedoch noch einige Aufgaben an. So sollten Sie zum Beispiel möglichst schnell die Hochzeitsgarderobe "+
      "reinigen lassen. Überlegen Sie, was mit dem Brautkleid geschehen soll: Möchten Sie es als Andenken "+
      "aufbewahren, ändern lassen und weiter tragen oder verkaufen?</p> "+
      "<p class='p-text'>Ändert sich nach der Eheschliessung der Name eines Partners, denken Sie daran, Behörden "+
      "und Ämter darüber zu informieren. Lassen Sie Ihre Ausweisdokumente entsprechend ändern und tauschen Sie "+
      "Namensschilder an Briefkästen und Türen aus. Beim Fotografen wählen Sie jetzt die schönsten "+
      "Hochzeitsfotos aus. Da meist auch Ihre Gäste Fotos machen, lohnt es sich, eine zentrale Sammelstelle "+
      "einzurichten, zum Beispiel die Upload-Funktion eines Online-Dienstes zu nutzen. Verschicken Sie Ihre "+
      "Danksagungskarten an die Hochzeitsgäste. Vergessen Sie nicht, alle offenen Rechnungen rechtzeitig zu "+
      "begleichen.</p>"
    },
    {
      title:"Konkubinat",
      urlName:"konkubinat",
      image: "url('/assets/konkubinat.jpg')",
      mobileImage: "url('/assets/konkubinat-mobile.jpg')",
      imagePosition: "50% 25%",
      content: "<h2 class='title-text'>Was Sie für das Leben im Konkubinat beachten müssen</h2> "+
      "<p class='p-text'>Ehe oder Konkubinat? Möchten Sie mit Ihrem Partner zusammenleben, stellt sich die Frage, "+
      "welche Partnerschaftsform sich am besten für Sie eignet. Im Hinblick auf Versicherungen und Finanzen "+
      "gibt es zwischen der Ehe und dem Konkubinat grosse Unterschiede. Wir beraten Sie gerne, welche Vorteile "+
      "die verschiedenen Partnerschafstformen für Sie bieten.</p> "+
      "<h2 class='title-text'>Konkubinatsvertrag</h2> "+
      "<p class='p-text'>Es empfiehlt sich, einen Konkubinatsvertrag aufzusetzen. Gesetzliche Regelungen für diesen "+
      "Vertrag gibt es nicht, der Inhalt steht Ihnen weitgehend frei. Sinnvollerweise klären Sie im "+
      "Konkubinatsvertrag, wie Sie Ihr Eigentum und Vermögen aufteilen, wie Sie sich laufende Kosten teilen "+
      "wollen, wer bei der Trennung im gemeinsamen Heim bleibt und regeln Unterhaltszahlungen sowie Sorgerecht "+
      "für gemeinsame Kinder. Der Vertrag muss nicht vom Notar beglaubigt werden, es sein denn, er enthält auch "+
      "erbvertragliche Anordnungen.</p> "+
      "<h2 class='title-text'>Konkubinat und Altersvorsorge </h2> "+
      "<p class='p-text'>Jeder Konkubinatspartner muss sich individuell um seine Altersvorsorge (AHV) kümmern. Geht "+
      "ein Partner keiner beruflichen Tätigkeit nach, kann dieser den AHV-Minimalbetrag zahlen. Im Falle einer "+
      "Trennung besteht kein Anspruch auf Ausgleich der Guthaben bei der AHV und der Pensionskasse. Unsere "+
      "unabhängigen Finanzcoaches unterstützen Sie gerne bei der Suche nach einer geeigneten Altersvorsorge. "+
      "</p> "+
      "<h2 class='title-text'>Konkubinat und Hauskauf</h2> "+
      "<p class='p-text'>Entscheiden sich Konkubinatspartner für den Kauf eines Eigenheims, müssen sie sich "+
      "Gedanken über die Aufteilung des Eigentums machen. Zur Wahl stehen das Allein-, Stockwerk- und "+
      "Miteigentum. Entsprechend der finanziellen Beteiligung der Partner wird das Eigentum im Grundbuch "+
      "eingetragen. Wir beraten Sie, welche Eigentumsform in Ihrer individuellen Situation sinnvoll ist.</p> "+
      "<h2 class='title-text'>Konkubinat und Steuern</h2> "+
      "<p class='p-text'>Konkubinatspartner werden jeweils als Einzelperson versteuert und erhalten getrennte "+
      "Steuererklärungen. Wir zeigen Ihnen, wie Sie dennoch Steuern sparen können.</p> "+
      "<h2 class='title-text'>Konkubinat und Sorgerecht</h2> "+
      "<p class='p-text'>Möchten unverheiratete Paare das gemeinsame Sorgerecht für leibliche Kinder übernehmen, "+
      "müssen Sie eine entsprechende schriftliche Erklärung aufsetzen. Dieses Dokument wird zusammen mit der "+
      "Kindesanerkennung auf dem Zivilstandesamt oder einzeln bei der Kinderschutzbehörde abgegeben. Zuvor "+
      "müssen die Eltern sich über Obhut, Betreuung und mögliche Unterhaltszahlungen im Fall einer Trennung "+
      "einigen.</p> "+
      "<h2 class='title-text'>Konkubinat und Tod eines Partners</h2> "+
      "<p class='p-text'>Besonderheiten sind beim Konkubinat auch im Todesfall eines Partners zu beachten. Der "+
      "hinterbliebene Partner erbt zum Beispiel nicht automatisch. Im Testament sind zudem zunächst die "+
      "Pflichtteile von Nachkommen oder Eltern zu berücksichtigen, bevor der Konkubinatspartner begünstigt "+
      "werden kann. Hinterbliebene Konkubinatspartner haben darüber hinaus kein Anrecht auf den begünstigten "+
      "Erbschaftsteuersatz. Eine Witwen- oder Witwerrente der AHV oder der Unfallversicherung erhalten sie "+
      "ebenfalls nicht. Daher ist es wichtig, sich frühzeitig um die Absicherung Ihres Partners zu kümmern. Wir "+
      "beraten Sie individuell und persönlich.</p>"
    },
    {
      title:"Nachlassplanung",
      urlName:"nachlassplanung",
      image: "url('/assets/nachlassplanung.jpg')",
      mobileImage: "url('/assets/nachlassplanung-mobile.jpg')",
      imagePosition: "50% 25%",
      content: "<h2 class='title-text'>Nachlassplanung: Sorgenfreier Leben durch frühzeitige Planung</h2> "+
      "<p class='p-text'>Kümmern Sie sich frühzeitig um die Nachlassplanung, vermeiden Sie Streitigkeiten zwischen "+
      "Ihren Angehörigen. Wir informieren Sie gerne über verschiedene Formen der Nachlassregelung. Gemeinsam "+
      "wählen wir die Variante aus, die Ihren Wünschen entspricht. Durch eine frühe Planung nehmen Sie den "+
      "Hinterbliebenen Sorgen ab.</p> "+
      "<h2 class='title-text'>Worüber sollten Sie sich bei der Nachlassplanung Gedanken machen?</h2> "+
      "<h2 class='title-text'>Güterstand</h2> "+
      "<p class='p-text'>Bei verheirateten Personen bestimmt der Güterstand, welches Vermögen in die Erbmasse "+
      "einfliesst. Es existieren drei Güterstände:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Errungenschaftsbeteiligung: In die Erbmasse fliesst die Hälfte des während des während des Ehelebens verdienten Vermögens beider Ehepartner sowie das individuelle Eigengut des Verstorbenen.</p>"+
      "<p class='sub-p'><span class='line'>-</span> Gütergemeinschaft: Errungenschaft und Eigengut werden zusammengezählt. Die Hälfte dieser Summe zählt als Erbmasse.</p>"+
      "<p class='sub-p'><span class='line'>-</span> Gütertrennung: Das Vermögen beider Ehepartner wird strikt getrennt, nur das Vermögen des Verstorbenen zählt als Erbmasse.</p></p>"+
      "<h2 class='title-text'>Erbfolge</h2> "+
      "<p class='p-text'>Die gesetzliche Erbfolge richtet sich nach dem Verwandschaftsgrad. Bedacht werden zunächst "+
      "der Ehepartner und die Kinder beziehungsweise die Enkel des Verstorbenen. Bei kinderlosen Paaren sieht "+
      "das Gesetz einen Erbanteil für die Eltern des Verstorbenen vor. Sind Sie mit der gesetzlichen Erbfolge "+
      "einverstanden, müssen Sie nichts weiter tun. In vielen Fällen entspricht diese jedoch nicht den Wünschen "+
      "des Erblassers. Durch ein Testament und einen Erbvertrag können Sie Ihr Erbe nach Ihren Vorstellungen "+
      "aufteilen. </p> "+
      "<h2 class='title-text'>Testament</h2> "+
      "<p class='p-text'>Per Testament können Sie Änderungen an der gesetzlichen Erbfolge vornehmen. Das "+
      "eigenhändige Testament verfassen Sie vollständig von Hand. Das Testament sollte alle Vermögenswerte "+
      "auflisten und den entsprechenden Erben zuteilen. Orientieren Sie sich dabei am besten an einem "+
      "Musterschreiben, um Formfehler zu vermeiden. Sie können auch ein öffentliches Testament verfassen. "+
      "Dieses wird in Gegenwart von zwei unabhängigen Zeugen von einem Notar verfasst und bestätigt.</p> "+
      "<h2 class='title-text'>Pflichtanteile</h2> "+
      "<p class='p-text'>Das Testament lässt Ihnen keine vollkommene Freiheit bei der Aufteilung des Erbes. Es sind "+
      "gewisse Pflichtanteile für Ehepartner und nahe Verwandte zu berücksichtigen. Der Pflichtanteil für den "+
      "Ehegatten beträgt bei Paaren mit Kindern zum Beispiel 25 Prozent. Die Kinder erhalten 37,5 Prozent des "+
      "Vermögens. Nur über die verbleibenden 37,5 Prozent dürfen Sie im Testament frei verfügen.</p> "+
      "<h2 class='title-text'>Erbvertrag</h2> "+
      "<p class='p-text'>Mittels Erbvertrag lassen sich Pflichtanteile ausser Kraft setzen. Das setzt allerdings "+
      "voraus, dass die betroffene Person dem Vertrag zustimmt. Im Erbvertrag können Sie zum Beispiel "+
      "festlegen, dass Ihr Ehepartner im Todesfall 15 Prozent der Erbmasse erhält und ein grösserer Anteil an "+
      "die Kinder geht. Die Vereinbarung wird dann von Ihnen, Ihrem Ehepartner und den Kindern unterzeichnet. "+
      "Der Erbvertrag wird von einem Notar verfasst, die Unterschrift erfolgt im Beisein zweier unabhängiger "+
      "Zeugen.</p>"
    },
    {
      title:"Pensionierung",
      urlName:"pensionierung",
      image: "url('/assets/pension.jpg')",
      mobileImage: "url('/assets/pensionierung-mobile.jpg')",
      imagePosition: "50% 30%",
      content: "<h2 class='title-text'>Pensionierung: Bereiten Sie sich auf Ihren Ruhestand vor </h2> "+
      "<p class='p-text'>Mit der Pensionierung beginnt ein neuer Lebensabschnitt. Endlich haben Sie mehr Zeit für "+
      "die Familie, für Ihre Hobbys oder für Reisen. Damit Sie sich nach Ihrer Pensionierung auch alle Träume "+
      "erfüllen können, empfiehlt es sich, bereits frühzeitig mit der Planung für den Ruhestand zu beginnen. "+
      "Unsere unabhängigen Finanzcoaches beraten Sie dabei und begleiten Sie bei der Vorbereitung auf dieses "+
      "wichtige Lebensereignis.</p> "+
      "<p class='p-text'>Welche Punkte sollten Sie vor der Pensionierung unbedingt bedenken? Unser Ratgeber zeigt "+
      "es Ihnen.</p> "+
      "<p class='p-text'>Zu den Aus- und Weiterbildungskosten zählen: </p> "+
      "<h2 class='title-text'>Prüfen Sie Ihren finanziellen Bedarf</h2> "+
      "<p class='p-text'>Um herauszufinden, wie viel Geld Sie im Alter für ein komfortables Leben brauchen, sollten "+
      "Sie Ihre voraussichtlichen Lebenshaltungskosten berechnen. In einigen Bereichen werden sich die "+
      "regelmässigen Kosten reduzieren - so sparen Sie etwa Fahrtkosten, wenn Sie nicht mehr täglich zur Arbeit "+
      "pendeln. In anderen Bereichen steigen die Ausgaben dagegen. Zum Beispiel müssen Sie mit höheren "+
      "Gesundheitskosten rechnen, wollen aber vielleicht auch mehr Geld in Ihre Freizeit investieren.</p> "+
      "<p class='p-text'>Für Angestellte setzt sich das Grundeinkommen nach der Pensionierung aus der AHV-Rente und "+
      "der Pensionskassenrente zusammen. Reicht diese Summe zur Deckung der zu erwartenden Lebenshaltungskosten "+
      "aus? Dann haben Sie die Weichen für Ihren Ruhestand richtig gestellt. Bedenken Sie allerdings, dass die "+
      "Rente nicht dem Inflationsschutz unterliegt. Die Pensionskasse muss die Rente also nicht an Teuerungen "+
      "anpassen. Wir helfen Ihnen bei der Finanzplanung und klären, wie Sie sich Ihre Rente aus der "+
      "Pensionskasse am besten auszahlen lassen: als monatliche Rente, als einmalige Kapitalauszahlung oder als "+
      "Kombination aus beidem.</p> "+
      "<h2 class='title-text'>Beugen Sie Versorgungslücken vor </h2> "+
      "<p class='p-text'>Bei jedem dritten Schweizer besteht eine Versorgungslücke. Das bedeutet, die zu erwartende "+
      "Rente reicht nicht aus, um den Lebensstandard nach der Pensionierung zu halten. Wir helfen Ihnen bei der "+
      "Vorsorgeplanung. Unsere unabhängigen Finanzcoaches zeigen Ihnen zum Beispiel, wie Sie Geld "+
      "gewinnbringend anlegen und sich ein sorgenfreies Leben im Alter ermöglichen. Darüber hinaus finden wir "+
      "Möglichkeiten, Ihre Steuern zu optimieren und auf diese Weise Geld fürs Alter zu sparen.</p> "+
      "<p class='p-text'>Eine Versorgungslücke tritt besonders schnell ein, wenn Sie oder Ihr Partner im Alter "+
      "pflegebedürftig werden. Zwar übernimmt die Grundversicherung die Kosten für die Pflege, für viele damit "+
      "verbundene Kosten, etwa für eine Haushaltshilfe, müssen Sie allerdings selbst aufkommen. Wir beraten "+
      "Sie, wie Sie auch für diesen Fall vorsorgen. </p>"
    },
    {
      title:"Reise",
      urlName:"reise",
      image: "url('/assets/reise.jpg')",
      mobileImage: "url('/assets/reise-mobile.jpg')",
      imagePosition: "50% 45%",
      content: "<h2 class='title-text'>Reise: Damit Ihr Urlaub zum Vergnügen wird </h2> "+
      "<p class='p-text'>Möchten Sie ferne Länder entdecken? Träumen Sie vom Urlaub am Meer oder wollen Sie die "+
      "Berge erkunden? Wohin Ihre Reise auch führt, wir unterstützen Sie bei der Planung und bei der "+
      "Finanzierung. Ob Abenteuerurlaub oder Familienreise, unsere Finanzcoaches zeigen Ihnen, wie Sie sich "+
      "Ihre Urlaubswünsche erfüllen können. Neben der Reisefinanzierung gibt es vor dem Urlaub aber noch viele "+
      "andere Dinge zu beachten und zu planen. Unser Ratgeber hilft Ihnen, gut vorbereitet in den Urlaub zu "+
      "starten.</p> "+
      "<h2 class='title-text'>So bereiten Sie sich optimal auf Ihren Urlaub vor</h2> "+
      "<p class='p-text'>Der erste Schritt der Reiseplanung besteht darin, das Reiseziel festzulegen. Wissen Sie "+
      "schon genau, wo die Reise hingehen soll? Oder suchen Sie noch nach dem idealen Urlaubsort? Online, in "+
      "Reisekatalogen und Reisebüros finden Sie viele Inspirationen. Entscheiden Sie ausserdem, wie Sie die "+
      "Reise verbringen wollen: Möchten Sie Ihren Urlaub individuell planen oder lieber zu einer Pauschalreise "+
      "aufbrechen? Informieren Sie sich bei möglichst unabhängigen Quellen über Reiserouten, Unterkünfte, Essen "+
      "und Freizeitmöglichkeiten vor Ort. Beachten Sie, welche Dokumente Sie für die Einreise ins Urlaubsland "+
      "benötigen: Reicht ein Reisepass oder brauchen Sie ein Visum?</p> "+
      "<h2 class='title-text'>Gut versichert in den Urlaub</h2> "+
      "<p class='p-text'>Möglichst frühzeitig vor dem Urlaub sollten Sie sich auch um die nötigen "+
      "Reiseversicherungen kümmern. Wir beraten Sie gerne. Gemeinsam mit Ihnen prüfen wir Ihren bestehenden "+
      "Versicherungsschutz und schlagen Ihnen passende Ergänzungen vor.</p> "+
      "<h2 class='title-text'>Wechselkurse und Zahlungsmöglichkeiten</h2> "+
      "<p class='p-text'>Möchten Sie Bargeld in die lokale Währung umtauschen, beachten Sie, wo Sie den günstigsten "+
      "Wechselkurs erhalten. Statt Bargeld mit sich herumzutragen, empfiehlt es sich oft, mit Kreditkarte zu "+
      "bezahlen. Heben Sie mit Ihrer normalen Bankkarte im Ausland Geld ab, kostet das nämlich oft hohe "+
      "Gebühren. Spezielle Reisekreditkarten können Sie dagegen überall auf der Welt gebührenfrei einsetzen. "+
      "</p> "+
      "<h2 class='title-text'>Packliste: Das gehört ins Reisegepäck</h2> "+
      "<p class='p-text'>Was Sie in den Urlaub mitnehmen sollten, hängt vor allem vom Reiseziel ab. Für eine "+
      "Trekking-Tour durch die Alpen benötigen Sie andere Dinge, als wenn Sie nach Südostasien fliegen. Legen "+
      "Sie vor Ihrer Reise eine Packliste an, damit Sie nichts vergessen. Folgende Dinge gehören unbedingt ins "+
      "Reisegepäck:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Ausweisdokument, Führerschein und gegebenenfalls Visum</p>"+
      "<p class='sub-p'><span class='line'>-</span> Versichertenkarte und gegebenenfalls Bescheinigung der Auslandskrankenversicherung</p><p class='sub-p'><span class='line'>-</span> Zug- oder Flugtickets</p>"+
      "<p class='sub-p'><span class='line'>-</span> Unterlagen zur Unterkunft</p><p class='sub-p'><span class='line'>-</span> EC-Karte, Kreditkarte, Bargeld und Telefonnummer der Bank zum Sperren der Karte</p>"+
      "<p class='sub-p'><span class='line'>-</span> Landkarten und Reiseführer</p><p class='sub-p'><span class='line'>-</span> Reiseapotheke mit persönlichen Medikamenten und den wichtigsten Wund-, Schmerz- und Fiebermitteln</p>"+
      "<p class='sub-p'><span class='line'>-</span> persönliche Kosmetik und Hygieneartikel</p><p class='sub-p'><span class='line'>-</span> Mobiltelefon, Kamera, Akkus und Ladekabel</p>"+
      "<p class='sub-p'><span class='line'>-</span> Spiele, Hörspiele und Bücher, mit denen sich Kinder während der Reise die Zeit vertreiben können</p></p>"
    },
    {
      title:"Scheidung",
      urlName:"scheidung",
      image: "url('/assets/scheidung.jpg')",
      mobileImage: "url('/assets/scheidung-mobile.jpg')",
      imagePosition: "50% 45%",
      content: "<h2 class='title-text'>Scheidung: Wenn die Ehe auseinandergeht</h2> "+
      "<p class='p-text'>Geht eine Ehe auseinander, ist das für alle Beteiligten eine Belastung. Neben der Frage, "+
      "wer aus der gemeinsamen Wohnung auszieht und wie das Sorgerecht für die Kinder geregelt wird, sind noch "+
      "zahlreiche andere Fragen zu klären. Eine Scheidung hat unter anderem auch enorme finanzielle "+
      "Auswirkungen. Gerne stehen wir Ihnen in dieser Situation zur Seite und übermitteln Ihnen Informationen, "+
      "wie Sie bei einer Scheidung am besten vorgehen. </p> "+
      "<h2 class='title-text'>Die Scheidung auf gemeinsames Begehren</h2> "+
      "<p class='p-text'>Statistiken zufolge lassen sich 40 Prozent der Ehepaare in der Schweiz wieder scheiden. "+
      "Wie das Scheidungsverfahren genau abläuft, hängt davon ab, ob die Partner einvernehmlich oder im Streit "+
      "auseinandergehen. Der günstigste Fall ist die sogenannte Scheidung auf gemeinsames Begehren. Bei der "+
      "einvernehmlichen Scheidung haben sich beide Parteien über alle Streitfragen bezüglich Gütertrennung, "+
      "Sorgerecht und Unterhalt geeinigt. Diese Punkte halten Sie in einer Scheidungskonvention fest. </p> "+
      "<p class='p-text'>Die Scheidungskonvention reichen Sie gemeinsam mit dem schriftlichen Scheidungsbegehren "+
      "beim zuständigen Zivilgericht Ihres Wohnkantons ein. Einige Gerichte verlangen noch weitere Unterlagen "+
      "zu den persönlichen und finanziellen Verhältnissen der Eheleute. Erkundigen Sie sich vorher, welche "+
      "Dokumente Sie einreichen müssen. Zudem ist es sinnvoll, sich auch bei einer einvernehmlichen Scheidung "+
      "von einem Anwalt beraten zu lassen. Die einvernehmliche Scheidung geht in der Regel recht zügig "+
      "vonstatten. Dem Scheidungsgesuch wird üblicherweise innerhalb von einem bis sechs Monaten stattgegeben. "+
      "</p> "+
      "<h2 class='title-text'>Die Scheidungsklage</h2> "+
      "<p class='p-text'>Verlangt nur ein Ehepartner die Scheidung, muss dieser die Scheidungsklage einreichen. Für "+
      "die strittige Scheidung wird eine zweijährige Trennungsphase vorausgesetzt. Diese entfällt, wenn das "+
      "Fortbestehen der Ehe für den klagenden Partner unzumutbar ist. Das ist etwa der Fall, wenn "+
      "Misshandlungen, mehrjähriger Ehebruch, Stalking oder schwerwiegende Belästigungen nachgewiesen werden "+
      "können.</p> "+
      "<h2 class='title-text'>Vorsorgeausgleich bei einer Scheidung</h2> "+
      "<p class='p-text'>Lassen Sie sich scheiden, werden alle während der Ehe erworbenen Ansprüche aus der "+
      "beruflichen Vorsorge geteilt. Seit 2017 gilt dies auch dann, wenn ein Ehepartner zum Zeitpunkt der "+
      "Scheidung bereits pensioniert oder invalid ist. Wir klären gerne gemeinsam mit Ihnen, welche "+
      "Auswirkungen der Vorsorgeausgleich auf Ihre Altersvorsorge hat und planen mit Ihnen Ihre Absicherung für "+
      "die Zukunft.</p>"
    },
    {
      title:"Selbstständigkeit",
      urlName:"selbststandigkeit",
      image: "url('/assets/selbst.jpg')",
      mobileImage: "url('/assets/selbs-mobile.jpg')",
      imagePosition: "50% 15%",
      content: "<h2 class='title-text'>Selbstständigkeit: Werden Sie Ihr eigener Chef!</h2> "+
      "<p class='p-text'>Sie möchten den Schritt in die Selbstständigkeit wagen? Die SSM Partner AG steht Ihnen "+
      "dabei zur Seite. Unsere Finanzcoaches unterstützen Sie beim Erstellen des Businessplans, beraten Sie bei "+
      "der Auswahl der nötigen Versicherungen und zeigen Ihnen unterschiedliche Finanzierungsmöglichkeiten auf. "+
      "So können Sie bestens vorbereitet Ihr eigenes Unternehmen starten.</p> "+
      "<h2 class='title-text'>Welche Unternehmensform eignet sich für Sie?</h2> "+
      "<p class='p-text'>Der erste Schritt auf dem Weg in die Selbstständigkeit besteht in der Auswahl der "+
      "geeigneten Unternehmensform. Möchten Sie ein Einzelunternehmen, eine Personengesellschaft, eine "+
      "Aktiengesellschaft oder eine GmbH? Wir beraten Sie und finden heraus, welche Unternehmensform zu Ihrem "+
      "Vorhaben passt.</p> "+
      "<h2 class='title-text'>Wie melden Sie Ihre Selbstständigkeit an?</h2> "+
      "<p class='p-text'>Selbstständige müssen Ihren Status von der Ausgleichskasse anerkennen lassen. Für die "+
      " Anerkennung müssen die folgenden Bedingungen erfüllt sein:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Sie arbeiten in eigenem Namen und auf eigene Rechnung.</p><p class='sub-p'><span class='line'>-</span> Sie sind in Ihrer Arbeit unabhängig.</p>"+
      "<p class='sub-p'><span class='line'>-</span> Sie tragen das wirtschaftliche Risiko selbst.</p><p class='sub-p'><span class='line'>-</span> Sie arbeiten für mehrere Auftraggeber.</p>"+
      "<p class='p-text'>Mit Hilfe von Rechnungen, Verträgen und ähnlichen Dokumenten weisen Sie nach, dass Sie "+
      "bereits selbstständig arbeiten. Bestimmte Berufe sind darüber hinaus auf Bundesebene oder kantonaler "+
      "Ebene reglementiert und erfordern eine Bewilligung der zuständigen Ämter.</p> "+
      "<p class='p-text'>Unternehmen müssen sich zudem ins Handelsregister eintragen lassen. Für selbstständige "+
      "Einzelunternehmer ist das erst nötig, wenn Ihr Umsatz einen Betrag von CHF 100'000.00 im Jahr "+
      "übersteigt.</p> "+
      "<h2 class='title-text'>Welche Versicherungen benötigen Sie als Selbstständiger?</h2> "+
      "<p class='p-text'>Als Selbstständiger sind Sie selbst für Ihre Absicherung verantwortlich. Verpflichtend "+
      "sind der Beitritt zu einer Ausgleichskasse für Beiträge zur Altersvorsorge, Invalidenversicherung und "+
      "Erwerbsersatzordnung sowie der Abschluss einer Basis-Krankenversicherung. Für Ihr Unternehmen benötigen "+
      "Sie ferner eine Betriebshaftpflicht und geeignete Versicherungen für Anlagen und Sachwerte.</p> "+
      "<p class='p-text'>Damit Sie für alle Eventualitäten bestens abgesichert sind, empfehlen sich darüber hinaus "+
      "die folgenden Versicherungen: </p> "+
      "<p class='p-text li-text'>- freiwillige Krankenzusatzversicherungen<br>- Unfallversicherung<br>- "+
      "Tagegeldversicherung<br>- private Altersvorsorge<br>- betriebliche Rechtsschutzversicherung</p> "+
      "<p class='p-text'>Abhängig von der Art des Unternehmens können weitere Versicherungen sinnvoll sein. "+
      "Beschäftigen Sie Mitarbeiter, müssen Sie diese ebenfalls absichern. Unsere unabhängigen Finanzcoaches "+
      "finden die besten Produkte für Sie. </p> "+
      "<h2 class='title-text'>Welche Steuern müssen Sie zahlen?</h2> "+
      "<p class='p-text'>Erzielen Sie mehr als CHF 100'000.00 Umsatz im Jahr, unterliegt Ihr Unternehmen der "+
      "Mehrwertsteuerpflicht und Sie müssen sich bei der Eidgenössischen Steuerverwaltung anmelden. Es gelten "+
      "nur wenige Ausnahmen.</p> "+
      "<p class='p-text'>Ist Ihr Unternehmen ins Handelsregister eingetragen, unterliegen Sie der "+
      "Buchhaltungspflicht. Einzelunternehmen und Personengesellschaften fertigen eine Steuererklärung an und "+
      "deklarieren ihren Gewinn als Einkommen. Auch Aktiengesellschaften müssen Ihren Gewinn versteuern. Da "+
      "eine AG jedoch eine unabhängige juristische Person ist, behalten die selbstständigen Unternehmer ihren "+
      "Angestelltenstatus. Wir zeigen Ihnen gerne, worauf es bei der Steuererklärung für Unternehmer ankommt. "+
      "</p>"
    },
    {
      title:"Todesfall",
      urlName:"todesfall",
      image: "url('/assets/todesfall.jpg')",
      mobileImage: "url('/assets/todesfall-mobile.jpg')",
      imagePosition: "50% 25%",
      content: "<h2 class='title-text'>Todesfall: Unterstützung und Rat bei administrativen Angelegenheiten</h2> "+
      "<p class='p-text'>Der Verlust eines nahestehenden Menschen ist eine schmerzhafte Erfahrung. Zur Trauer um "+
      "einen Angehörigen kommen zahlreiche administrative Aufgaben, die während dieser schweren Zeit zu "+
      "bewältigen sind. Unser Ratgeber fasst die wichtigsten Punkte für Sie zusammen. Gerne stehen wir Ihnen "+
      "auch persönlich zur Seite und beraten Sie bei Fragen rund um Erbschaftsabwicklung, Versicherungen und "+
      "weiteren finanziellen Angelegenheiten.</p> "+
      "<h2 class='title-text'>Todesfall melden</h2> "+
      "<p class='p-text'>Ein Todesfall ist unverzüglich beim Zivilstandesamt bzw. Bestattungsamt des Wohnorts und "+
      "Sterbeorts zu melden. Diese Unterlagen benötigen Sie:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> ärztliche Todesbescheinigung und gegebenenfalls Todesmeldung der Spital- oder Heimverwaltung</p>"+
      "<p class='sub-p'><span class='line'>-</span> Familienbüchlein</p><p class='sub-p'><span class='line'>-</span> Personalausweis oder vergleichbare Ausweisdokumente</p> "+
      "<p class='sub-p'><span class='line'>-</span> bei Ausländern: Niederlassungs- bzw. Aufenthaltsbewilligung</p></p> "+
      "<p class='p-text'>Der Todesfall wird auch dem Gemeindesteueramt gemeldet. Dieses nimmt die steuerrechtliche "+
      "Inventarisierung des Vermögens vor. Erst danach dürfen die Hinterbliebenen über das Vermögen verfügen. "+
      "</p> "+
      "<h2 class='title-text'>Im Todesfall zu informieren</h2> "+
      "<p class='p-text'>Neben Angehörigen, Freunden und Bekannten müssen auch diverse Behörden und Organisationen "+
      "über einen Todesfall informiert werden. Insbesondere der Arbeitgeber sollten Sie umgehend verständigen. "+
      "Der Arbeitgeber informiert die Unfallversicherung und in der Regel auch die Pensionskasse. Auch der AHV "+
      "muss der Tod des Versicherten gemeldet werden.</p> "+
      "<p class='p-text'>Wohnte der Verstorbene zur Miete, nehmen Sie Kontakt zum Vermieter auf und kündigen "+
      "entweder die Wohnung zum nächsten Termin oder lassen den Mietvertrag auf einen Hinterbliebenen "+
      "umschreiben. War der Verstorbene Mitglied bei Vereinen oder Institutionen, müssen die Mitgliedsverträge "+
      "gekündigt werden. Bei Ausländern ist zudem das zuständige Konsulat zu benachrichtigen.</p> "+
      "<h2 class='title-text'>Bank- und Postkonto</h2> "+
      "<p class='p-text'>Auch um das Bank- und Postkonto eines Verstorbenen müssen sich die Hinterbliebenen "+
      "kümmern. Benachrichtigen Sie Bank und Post mit Vorlage des Todesscheins. Lassen Sie sich eine "+
      "Saldobestätigung für den Todestag vorlegen. Erkundigen Sie sich, welche Unterlagen zur Umschreibung von "+
      "Konten und Guthaben nötig sind. Stornieren Sie Daueraufträge. Prüfen Sie bestehende Vollmachten und "+
      "widerrufen Sie diese gegebenenfalls.</p> "+
      "<h2 class='title-text'>AHV und Versicherungen</h2> "+
      "<p class='p-text'>Besteht Anspruch auf eine Hinterlassenenrente, wenden Sie sich möglichst schnell an die "+
      "Ausgleichskasse des Verstorbenen. Diese stellt Ihnen ein Antragsformular zu. Hat der Verstorbene bereits "+
      "eine Rente bezogen, wird die Ausgleichskasse die Rentenbezüge aufheben oder neu berechnen.</p> "+
      "<p class='p-text'>Darüber hinaus sind die Krankenkasse sowie Unfall- und Lebensversicherung des Verstorbenen "+
      "zu informieren. Prüfen Sie, welche Leistungen bei der Unfall- und Lebensversicherung abgesichert sind "+
      "und welche Unterlagen zur Auszahlung benötigt werden. Gehen Sie anschliessend die weiteren "+
      "Versicherungsverträge des Verstorbenen durch. Enden die Policen durch den Tod nicht automatisch, lassen "+
      "Sie die Versicherungen aufheben. Eventuell können Sie eine Prämienrückerstattung verlangen. Wir helfen "+
      "Ihnen gerne bei diesen Aufgaben.</p>"
    },
    {
      title:"Umzug",
      urlName:"umzug",
      image: "url('/assets/umzug.jpg')",
      mobileImage: "url('/assets/umzug-mobile.jpg')",
      imagePosition: "50% 15%",
      content:"<h2 class='title-text'>Umzug: Kommen Sie gut ins neue Zuhause</h2> "+
      "<p class='p-text'>Ein Umzug steht an und Sie freuen sich bereits aufs neue Zuhause. Bevor Sie es sich jedoch "+
      "in Ihren neuen vier Wänden gemütlich machen können, steht jede Menge Arbeit vor Ihnen. Ein Umzug will "+
      "gut geplant sein, damit am grossen Tag alles reibungslos abläuft. Unser Ratgeber zeigt Ihnen, worauf Sie "+
      "bei der Umzugsplanung achten müssen.</p> "+
      "<h2 class='title-text'>Ein bis drei Monate vor dem Umzug</h2> "+
      "<p class='p-text'>Ihre Umzugsplanung sollte so früh wie möglich beginnen, damit Sie nicht in Stress geraten. "+
      "Haben Sie ein neues Zuhause gefunden und Ihre alte Wohnung gekündigt, sollten Sie in den drei Monaten "+
      "vor dem Umzugstermin folgende Aufgaben erledigen:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> gegebenenfalls Nachmieter suchen</p><p class='sub-p'><span class='line'>-</span> Umzugsplanung und Kostenkalkulation aufstellen</p>"+
      "<p class='sub-p'><span class='line'>-</span> Kinder aus der Schule oder dem Kindergarten abmelden</p><p class='sub-p'><span class='line'>-</span> laufende Verträge und Abos auf die neue Adresse umschreiben lassen oder kündigen</p>"+
      "<p class='sub-p'><span class='line'>-</span> eventuell einen Bankwechsel vornehmen</p><p class='sub-p'><span class='line'>-</span> mit der Entrümpelung beginnen</p></p>"+
      "<h2 class='title-text'>In den vier Wochen vor dem Umzug</h2> "+
      "<p class='p-text'>Rückt der Umzugstermin näher, geht es an die eigentliche Vorbereitung. Nehmen Sie sich "+
      "ausreichend Zeit, um nicht mehr benötigte Dinge auszusortieren. Ausserdem stehen jetzt folgende Aufgaben "+
      "an: </p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Umzugshelfer und Transportmittel organisieren</p><p class='sub-p'><span class='line'>-</span> Umzugskartons und Verpackungsmaterial besorgen</p><p class='sub-p'><span class='line'>-</span> Sperrmüll beantragen</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wohnung auf die Übergabe vorbereiten und</p><p class='sub-p'><span class='line'>-</span> Wohnung auf die Übergabe vorbereiten und vertraglich vereinbarte Schönheitsreparaturen erledigen</p>"+
      "<p class='sub-p'><span class='line'>-</span> Banken und Versicherungen über den Adresswechsel informieren</p><p class='sub-p'><span class='line'>-</span> Nachsendeauftrag bei der Post stellen</p>"+
      "<p class='sub-p'><span class='line'>-</span> prüfen, wo der Umzugstransporter am neuen Wohnort parken kann und gegebenenfalls Parkgenehmigungen einholen</p>"+
      "<p class='sub-p'><span class='line'>-</span> Babysitter und Tiersitter für den Umzugstag organisieren</p>"+
      "<p class='sub-p'><span class='line'>-</span> Umzugskartons packen</p><p class='sub-p'><span class='line'>-</span>  Möbel ausmessen und Möbelstellplan für die neue Wohnung aufstellen</p></p>"+
      "<h2 class='title-text'>Am Umzugstag</h2> "+
      "<p class='p-text'>Am Umzugstag sollten Ihre Kisten und Möbel bereits für den Transport vorbereitet sein. "+
      "Ziehen Sie mit der Hilfe von Freunden um, sollten Sie auch Verpflegung für Ihre Helfer organisieren. "+
      "Weitere Aufgaben:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Briefkasten leeren</p><p class='sub-p'><span class='line'>-</span> Namensschilder am alten Wohnort abmontieren</p>"+
      "<p class='sub-p'><span class='line'>-</span> Zählerstände für Gas, Wasser, Strom in alter und neuer Wohnung notieren</p><p class='sub-p'><span class='line'>-</span> Kontrollgang durch die alte Wohnung</p></p>"
    }
  ]

}
