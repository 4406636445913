import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-lebensphase-overview',
  templateUrl: './lebensphase-overview.component.html',
  styleUrls: ['./lebensphase-overview.component.scss']
})
export class LebensphaseOverviewComponent implements OnInit {
  component;
  selectedName;
  desktop: boolean = false
  mobile: boolean = false

  constructor(private routing: Router, private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.params.subscribe(param => {
      this.selectedName = param['name'];
    })

    for (let i = 0; i < this.components.length; i++) {
      const element = this.components[i];
      if(element.urlName == this.selectedName){
        this.component = element;
      }
    }

    if (window.innerWidth < 578) {
      this.mobile = true;
    }else{
      this.desktop = true;
    }

  }

  onResize(event) {
    if (event.target.innerWidth < 578) {
      this.mobile = true;
      this.desktop = false;
    } else {
      this.mobile = false;
      this.desktop = true;
    }
  }


  components = [
    {
      title: 'Single',
      urlName: 'single',
      image: "url('/assets/single.jpg')",
      mobileImage:"url('/assets/single-mobile.jpg')",
      imagePosition: "50% 25%",
      content: "<h2 class='title-text'>Single: Finanzielle Vorsorge und Absicherung für Ihre Lebensphase</h2> "+
      "<p class='p-text'>Als Single möchten Sie Ihre Unabhängigkeit geniessen. Das gelingt am besten, wenn Sie sich "+
      "auf eine gute Absicherung verlassen können. Unsere unabhängigen Finanzcoaches beraten Sie persönlich und "+
      "individuell. Unter Berücksichtigung Ihrer Wünsche und Ihrer Lebenssituation finden wir die passenden "+
      "Versicherungen, Vorsorgeoptionen und Anlagemöglichkeiten für Sie. </p> "+
      "<h2 class='title-text'>Single ist nicht gleich Single </h2> "+
      "<p class='p-text'>Als Single leben Sie allein, ohne festen Partner. Das ist aber auch das einzige, was alle "+
      "Singles gemeinsam haben. Die konkrete Lebenssituation von Singles kann sich stark unterscheiden. "+
      "Entsprechend unterschiedliche Bedürfnisse und Wünsche haben Singles auch, wenn es um Versicherungen und "+
      "Finanzen geht. Als junger Erwachsener stehen meist Vergnügen, Unterhaltung und Selbstverwirklichung im "+
      "Vordergrund. Die Vorsorge für Gesundheit und Alter sollten Sie dennoch nicht vernachlässigen. Wir helfen "+
      "Ihnen, passende Produkte zu finden. Möchten Sie grosse Träume wie Reisen oder ein Auslandsjahr "+
      "verwirklichen, unterstützen wir Sie bei der Suche nach geeigneten Finanzierungsmöglichkeiten.</p> "+
      "<p class='p-text'>So mancher Single möchte irgendwann doch eine Familie gründen. Eine frühzeitige "+
      "Finanzplanung trägt dazu bei, dass Sie sich auch in dieser Lebenslage alle Wünsche erfüllen können. "+
      "Ältere Singles sollten für die Zeit nach der Pensionierung vorsorgen. Wie möchten Sie im Alter leben? "+
      "Welche Ziele wollen Sie im Ruhestand verfolgen? Zudem ist es sinnvoll, sich Gedanken darüber zu machen, "+
      "wer die Versorgung im Alter oder im Falle einer Krankheit übernimmt. Unsere Finanzcoaches gehen "+
      "individuell auf Ihre Fragen ein und finden massgeschneiderte Lösungen. </p> "+
      "<h2 class='title-text'>Individuelle Beratung ganz nach Ihren Wünschen</h2> "+
      "<p class='p-text'>Die Basis für unsere Beratung bilden stets Ihre Wünsche und Vorstellungen. Gemeinsam mit "+
      "Ihnen analysieren wir Ihre aktuelle Lebenssituation. Stehen Sie gerade erst am Anfang Ihrer Karriere? "+
      "Oder rückt vielleicht die Pensionierung näher? Wohnen Sie zur Miete oder im Eigenheim? Wie viel Kapital "+
      "haben Sie zur Verfügung? "+
      "</p> "+
      "<p class='p-text'>Anschliessend befassen wir uns mit Ihren Zukunftsplänen. Möchten Sie sich weiterbilden "+
      "oder ein Sabbatical einlegen? Wollen Sie den Schritt in die Selbstständigkeit wagen? Wünschen Sie sich, "+
      "in Frühpension gehen zu können? Wir zeigen Ihnen Möglichkeiten, mit denen Sie Ihre Träume in die Tat "+
      "umsetzen können. Nehmen Sie gleich Kontakt zu uns auf und vereinbaren Sie einen Termin!</p>"
    },
    {
      title: 'Kinderloses Paar',
      urlName: 'kinderloses-paar',
      image: "url('/assets/kinderloses.jpg')",
      mobileImage: "url('/assets/kinderloses-mobile.jpg')" ,
      imagePosition: "50% 30%",
      content:"<h2 class='title-text'>Kinderloses Paar: Gut vorbereitet auf die gemeinsame Zukunft</h2> "+
      "<p class='p-text'>Als Paar sind Sie ein unschlagbares Duo. Da Sie noch keine Kinder haben, sind Sie "+
      "weitgehend unabhängig. Sie reisen, unternehmen viel und geniessen Ihr gemeinsames Leben. Allerdings "+
      "sollten Sie sich auch ein wenig Zeit nehmen, um Ihre gemeinsame Zukunft zu planen. Die SSM Partner AG "+
      "unterstützt Sie dabei - mit einer unabhängigen Beratung, abgestimmt auf Ihre Wünsche.</p> "+
      "<h2 class='title-text'>Diese Fragen sind für kinderlose Paare von Bedeutung </h2> "+
      "<p class='p-text'>Bei der Planung Ihrer gemeinsamen Zukunft sollten Sie die folgenden Fragen klären: </p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Wollen Sie zusammenziehen? Kommen der Kauf oder der Bau eines Eigenheims für Sie in Frage?</p>"+
      "<p class='sub-p'><span class='line'>-</span> In welcher Partnerschaftsform möchten Sie zusammenleben? Planen Sie eine Hochzeit oder bevorzugen Sie das Konkubinat?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Planen Sie, irgendwann einmal Kinder zu bekommen?</p><p class='sub-p'><span class='line'>-</span> Welche gemeinsamen grösseren Anschaffungen planen Sie?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Möchten sich einer oder beide Partner selbstständig machen? Planen Sie eventuell sogar, eine gemeinsame Firma zu gründen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wünschen Sie eine gemeinsame Finanzplanung oder möchten Sie Ihre Finanzen getrennt voneinander halten?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie möchten Sie das Mitspracherecht in Notfällen regeln?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wer erhält die Vollmacht über Ihre Finanzprodukte?</p><p class='sub-p'><span class='line'>-</span> Wie regeln Sie es, wenn Ihre Eltern im Alter zum Pflegefall werden?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie sorgen Sie selbst fürs gemeinsame Alter vor? Wie viel Geld benötigen Sie nach der Pensionierung, um Ihren Lebensstandard zu halten und sich weiterhin Wünsche zu erfüllen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wen möchten Sie in Ihrem Nachlass bedenken? </p></p>"+
      "<h2 class='title-text'>Individuelle Beratung von unabhängigen Finanzcoaches </h2> "+
      "<p class='p-text'>Von uns erhalten Sie eine unabhängige und ganzheitliche Finanzberatung. Das bedeutet, wir "+
      "beziehen nicht nur Produkte eines Anbieters in unsere Auswahl ein, sondern haben Überblick über den "+
      "kompletten Markt. So können wir die Versicherungen und Finanzprodukte auswählen, die am besten zu Ihren "+
      "Bedürfnissen passen. Darüber hinaus geht unsere Beratung immer von Ihren Wünschen aus und bezieht Ihre "+
      "komplette Lebenssituation mit ein. "+
      "</p> "+
      "<p class='p-text'> "+
      "Abhängig von Ihren Plänen und Träumen zeigen wir Ihnen zum Beispiel, wie Sie für ein neues Auto oder "+
      "eine Reise sparen. Wir finden Finanzierungsmöglichkeiten fürs Eigenheim und zeigen Ihnen, wie Sie "+
      "sinnvoll Vermögen aufbauen können. Wir unterstützen Sie bei der Auswahl von Versicherungen und der "+
      "Altersvorsorge. Ausserdem helfen wir Ihnen, Steuern zu sparen. Möchten Sie sich individuell und "+
      "persönlich beraten lassen, nehmen Sie gleich Kontakt zu uns auf! "+
      "</p>"
    },
    {
      title: 'Student',
      urlName: 'student',
      image: "url('/assets/student.jpg')",
      mobileImage: "url('/assets/student-mobile.jpg')",
      imagePosition: "50% 25%",
      content:"<h2 class='title-text'>Studium: So behalten Studierende alle Finanzen fest im Griff </h2> "+
      "<p class='p-text'>Mit dem Studium beginnt eine spannende neue Lebensphase. Viele junge Leute ziehen jetzt "+
      "zum ersten Mal von zu Hause aus und wagen den Schritt in die Unabhängigkeit. Damit gehen allerdings auch "+
      "viele Unsicherheiten einher. Plötzlich müssen Sie sich mit finanziellen Fragen beschäftigen. Unsere "+
      "unabhängigen Finanzcoaches helfen Ihnen dabei.</p> "+
      "<h2 class='title-text'>Welche Fragen sollten Sie sich vor dem Studium stellen?</h2> "+
      "<p class='p-text'>Möchten Sie die Zeit als Student möglichst unbeschwert geniessen, sollten Sie sich "+
      "frühzeitig mit der Studienfinanzierung auseinandersetzen. Neben den Studiengebühren und Kosten für "+
      "Lehrmaterial sind auch die allgemeinen Lebenshaltungskosten zu decken. Damit Sie genug Geld zum Leben "+
      "haben und sich auch einmal etwas gönnen können, klären wir mit Ihnen zusammen folgende Fragen:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Wie möchten Sie Ihr Studium finanzieren?</p><p class='sub-p'><span class='line'>-</span> Erhalten Sie finanzielle Unterstützung von Ihren Eltern?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Können Sie eventuell ein Stipendium oder Bildungsdarlehen erhalten?</p><p class='sub-p'><span class='line'>-</span> Planen Sie ein Auslandsjahr oder möchten Sie sogar länger im Ausland studieren?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie viel Geld benötigen Sie für Ihren Lebensunterhalt?</p><p class='sub-p'><span class='line'>-</span> Wie können Sie sparen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Möchten Sie einen Nebenjob aufnehmen? Ist dieser eventuell steuerpflichtig?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Welche Versicherungen müssen Sie als Student abschliessen? </p></p>"+
      "<h2 class='title-text'>Versicherungen für Studierende</h2> "+
      "<p class='p-text'>Als Student müssen Sie unabhängig von einer weiteren Erwerbstätigkeit Beiträge zur Alters- "+
      "und Hinterlassenschaftsversicherung (AHV), Invalidenversicherung (IV) und Erwerbsersatzordnung (EO) "+
      "zahlen. Nicht erwerbstätige Studierende zahlen ab dem 1. Januar nach Vollendung des 20. Lebensjahres bis "+
      "zum 1. Januar nach Vollendung des 25. Lebensjahres den Mindestbeitrag von aktuell CHF 496.00 im Jahr. "+
      "Bei erwerbstätigen Studierenden werden die Beiträge vom Einkommen abgezogen. Sind Sie verheiratet und "+
      "Ihr Ehepartner entrichtet durch seine Arbeit bereits den doppelten Mindestbeitrag, sind Sie von der "+
      "Zahlung ausgenommen. "+
      "</p> "+
      "<p class='p-text'>Die Basis-Krankenversicherung ist auch für Studierende obligatorisch. Um Ihren "+
      "Gesundheitsschutz zu optimieren, können Sie die Grundversicherung um freiwillige Zusatzversicherungen "+
      "ergänzen. Besitzen Sie ein Auto, benötigen Sie eine Motorfahrzeug-Versicherung. Schäden am eigenen Auto "+
      "werden durch eine freiwillige Kaskoversicherung gedeckt. Eine private Haftpflichtversicherung ist nicht "+
      "vorgeschrieben, aber sehr empfehlenswert. Sie deckt von Ihnen verursachte Sach- und Personenschäden und "+
      "schützt vor hohen Schadensersatzanforderungen. "+
      "</p> "+
      "<h2 class='title-text'>Finanzplanung und Studienfinanzierung nach Ihren Wünschen </h2> "+
      "<p class='p-text'>Unsere Finanzcoaches beraten Sie produktunabhängig und unter Berücksichtigung Ihrer "+
      "Wünsche. So können wir stets die besten Produkte für Sie finden. Wir prüfen, in welchen Fällen Sie noch "+
      "über Ihre Eltern abgesichert sind, welche eigenen Versicherungen Sie benötigen und welche Tarife sich "+
      "für Sie eignen. Auch bei Fragen zur Altersvorsorge helfen wir Ihnen gerne weiter. "+
      "</p>"
    },
    {
      title: 'Expats',
      urlName:'expats',
      image: "url('/assets/expats.jpg')",
      mobileImage: "url('/assets/expats.jpg')",
      imagePosition: "50% 25%",
      content:"<h2 class='title-text'>Expats: Was Sie als Expatriate in der Schweiz wissen sollten</h2> "+
      "<p class='p-text'>Die Schweiz ist bei Arbeitnehmern aus dem Ausland nach wie vor beliebt. Kommen Sie zum "+
      "Arbeiten in die Schweiz, müssen Sie aber nicht nur Ihren Umzug regeln. Es stehen auch einige "+
      "Behördengänge an. Zudem sind Besonderheiten hinsichtlich Versicherung und Steuern zu beachten. Wir "+
      "stehen Expats als starker Partner zur Seite und sorgen dafür, dass Ihr Berufsstart in der Schweiz "+
      "gelingt. "+
      "</p> "+
      "<h2 class='title-text'>Versicherungen, Vorsorge und Finanzen: Wir helfen Ihnen weiter!</h2> "+
      "<p class='p-text'>Wer in der Schweiz lebt und arbeitet, muss einige Besonderheiten beachten. So sind zum "+
      "Beispiel auch Expats zum Abschluss einer obligatorischen Krankengrundversicherung verpflichtet. Die "+
      "Auswahl der Krankenkasse steht Ihnen jedoch frei. Wir helfen Ihnen, einen passenden Tarif zu finden und "+
      "prüfen zudem, welche freiwillige Zusatzversicherung für Sie in Frage kommt. Ebenso klären wir Ihren "+
      "weiteren Versicherungsbedarf. Ausgehend von Ihren Zielen und Wünschen unterstützen wir Sie darüber "+
      "hinaus bei der Planung Ihrer Altersvorsorge. "+
      "</p> "+
      "<p class='p-text'>Unsere Finanzcoaches helfen Ihnen auch bei Fragen zur Besteuerung weiter. Unbeschränkt "+
      "steuerpflichtig sind alle natürlichen Personen mit Wohnsitz oder steuerrechtlichem Aufenthalt in der "+
      "Schweiz. Steuerrechtlicher Aufenthalt bedeutet, dass Sie mindestens 30 Tage in der Schweiz erwerbstätig "+
      "sind oder sich mindestens 90 Tage ohne Erwerbstätigkeit in der Schweiz aufhalten. Personen mit "+
      "ausländischer Staatsangehörige, die keine Niederlassungsbewilligung besitzen und einer "+
      "nicht-selbstständigen Tätigkeit nachgehen, müssen Quellensteuer bezahlen. Darauf verzichtet wird, wenn "+
      "der jährliche Bruttolohn CHF 120'000.- übersteigt. In diesem Fall wird eine nachträgliche Veranlagung "+
      "durchgeführt. "+
      "</p> "+
      "<h2 class='title-text'>Welche Fragen sollten sich Expats stellen? </h2> "+
      "<p class='p-text'>Möchten Sie in der Schweiz arbeiten, sollten Sie sich ausserdem die folgenden Fragen "+
      "stellen: "+
      "</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Welche Art der Aufenthaltsbewilligung benötigen Sie?</p><p class='sub-p'><span class='line'>-</span> Wie finden Sie eine geeignete Wohnung?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Planen Sie, Ihre Familie in die Schweiz zu holen?</p><p class='sub-p'><span class='line'>-</span> Wie oft besuchen Sie Familienangehörige und Freunde im Heimatland?</p>"+
      "<p class='sub-p'><span class='line'>-</span> In welchem Land möchten Sie nach dem Arbeitseinsatz leben?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wo möchten Sie Ihren Ruhestand verbringen?</p><p class='sub-p'><span class='line'>-</span> Möchten Sie ein Eigenheim erwerben?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Besteht ein Doppelbesteuerungsabkommen mit Ihrem Heimatland?</p><p class='sub-p'><span class='line'>-</span> Wie bauen Sie sich ein stabiles soziales Umfeld auf?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Was müssen Sie beachten, wenn Sie Auto und Hausstand in die Schweiz überführen</p></p>"+
      "<p class='p-text'> "+
      "Bei einigen Fragen hilft bereits der Arbeitgeber weiter. Die SSM Partner AG unterstützt Expats "+
      "ebenfalls. Unsere ganzheitliche Finanzberatung berücksichtigt Ihre komplette Lebenssituation. So können "+
      "wir massgeschneiderte Lösungen für Sie finden. "+
      "</p>"
    },
    {
      title: 'Junge Familie',
      urlName: 'junge-familie',
      image: "url('/assets/junge.jpg')",
      mobileImage: "url('/assets/junge-mobile.jpg')",
      imagePosition: "50% 35%",
      content: "<h2 class='title-text'>Junge Familie: Bei der Familiengründung gut beraten </h2> "+
      "<p class='p-text'>Sie sind bereits einige Zeit mit Ihrem Partner zusammen. Nun haben Sie den Entschluss "+
      "gefasst, eine Familie zu gründen. Mit dem ersten eigenen Kind kommen zahlreiche Fragen und "+
      "Herausforderungen auf junge Eltern zu. Schliesslich möchten Sie nur das Beste für Ihr Kind. Wie sichern "+
      "Sie Ihren Nachwuchs am besten ab? Wie sorgen Sie für Ihre Familie vor? Wie sparen Sie geschickt, sodass "+
      "Sie sich im Alltag weiterhin grössere und kleinere Wünsche erfüllen können? Die SSM Partner AG "+
      "unterstützt junge Familien bei der Finanzplanung. Unsere Finanzcoaches beraten Sie produktunabhängig und "+
      "abgestimmt auf Ihre Ziele. "+
      "</p> "+
      "<h2 class='title-text'>Wichtige Fragen für junge Familien </h2> "+
      "<p class='p-text'>Bereits bevor das erste Kind auf die Welt kommt, sind einige wichtige Fragen zu klären: "+
      "</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Wie teilen Sie die Kinderbetreuung auf? Bleibt ein Elternteil zuhause, arbeiten Sie beide in Teilzeit weiter?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie wird sich Ihr Einkommen nach der Geburt und Babypause verändern?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Erhalten Sie finanzielle Unterstützung von den Grosseltern oder Paten des Kindes?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Möchten Sie eine externe Kinderbetreuung in Anspruch nehmen? Von welchen Steuervorteilen können Sie dabei profitieren</p>"+
      "<p class='sub-p'><span class='line'>-</span> Benötigen Sie eine grössere Wohnung, ein grösseres Auto? Möchten Sie vielleicht ein Eigenheim erwerben?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie versichern Sie Ihr Kind am besten? Bietet Ihre Krankenversicherung einen Familientarif? Welche Zusatzversicherungen sind sinnvoll?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie hoch fällt die Kinderzulage aus?</p><p class='sub-p'><span class='line'>-</span> Wie möchten Sie für die Ausbildung Ihrer Kinder sparen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie sieht Ihre weitere Familienplanung aus? Möchten Sie mehrere Kinder bekommen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie möchten Sie für Ihr Alter vorsorgen?</p><p class='sub-p'><span class='line'>-</span> Sind Sie bereits verheiratet oder planen Sie eine Heirat? Wie teilen Sie Ihre Güter auf? Möchten Sie einen Ehevertrag aufsetzen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie möchten Sie sich beruflich weiterentwickeln? Ziehen Sie einen Job im Ausland in Betracht, möchten Sie sich weiterbilden oder sich selbstständig machen? </p></p>"+
      "<h2 class='title-text'>Wir beraten junge Familien individuell und persönlich</h2> "+
      "<p class='p-text'>Unser Finanzcoaching basiert auf einem ganzheitlichen Ansatz. Unsere Berater gehen mit Ihnen Ihre "+
      "finanzielle Situation durch und prüfen, welche Reserven vorhanden sind. Anschliessend klären wir, welche "+
      "Wünsche und Ziele Sie verfolgen. Basierend auf Ihrer individuellen Lebenssituation finden wir dann die "+
      "passenden Versicherungs-, Vorsorge- und Finanzierungsprodukte für Ihre junge Familie. "+
      "</p>"
    },
    {
      title: 'Familie mit älteren Kindern',
      urlName: 'familie-mit-alteren-kinder',
      image: "url('/assets/familie.jpg')",
      mobileImage: "url('/assets/familie-mobile.jpg')",
      imagePosition: "50% 25%",
      content: "<h2 class='title-text'>Familie mit älteren Kindern: Optimal abgesichert in die Zukunft </h2> "+
      "<p class='p-text'>Ihre Kinder werden langsam älter und damit auch selbstständiger. Jetzt beginnt die Zeit, "+
      "in der Sie Ihre neugewonnene Freiheit als Paar geniessen können. Vielleicht überlegen Sie auch, wie Sie "+
      "sich beruflich weiterentwickeln möchten. Stehen die Kinder bereits kurz vor dem Auszug, ist auch zu "+
      "klären, wie Sie in Zukunft wohnen werden. Eventuell ist Ihr Eigenheim zu gross für Sie, auf Wohneigentum "+
      "zur Altersvorsorge möchten Sie aber dennoch nicht verzichten. Die SSM Partner AG berät Sie in allen "+
      "finanziellen Fragen, die während dieser Lebensphase auftreten. Unsere Finanzchoaches finden passende "+
      "Lösungen und Produkte, damit Sie unbeschwert Ihre Zukunft planen können. "+
      "</p> "+
      "<h2 class='title-text'>Welche Fragen sich Familien mit älteren Kindern stellen sollten</h2> "+
      "<p class='p-text'>Über diese Themen sollten sich Familien mit älteren Kindern Gedanken machen:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Welche beruflichen Ziele verfolgen Sie?</p><p class='sub-p'><span class='line'>-</span> Haben Sie bereits für die Ausbildung Ihrer Kinder vorgesorgt? Welche Möglichkeiten stehen zur Verfügung?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie ändert sich Ihre Wohnsituation durch den Auszug eines Kindes?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie möchten Sie Ihre neugewonnene Freiheit geniessen? Möchten Sie reisen oder eine neue berufliche Herausforderung suchen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Was planen Sie für die Zeit nach der Pensionierung? Wie viel Geld benötigen Sie, um sich im Alter spezielle Wünsche zu erfüllen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Möchten Sie oder Ihre Kinder einige Zeit im Ausland leben?</p><p class='sub-p'><span class='line'>-</span> Kommt eine Frühpensionierung für Sie in Frage?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Lässt sich Ihre Vermögensplanung optimieren?</p><p class='sub-p'><span class='line'>-</span> Stehen Schenkungen oder Erbschaften an?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie möchten Sie Ihr eigenes Testament regeln? </p></p>"+
      "<h2 class='title-text'>Wir helfen Ihnen, Ihre Familie optimal abzusichern</h2> "+
      "<p class='p-text'>Wie Sie sich Ihre Zukunft auch vorstellen, unsere Finanzcoaches sind für Sie da. Gemeinsam "+
      "mit Ihnen analysieren wir Ihre finanzielle Situation und prüfen, wo es Optimierungspotenzial gibt. Sind "+
      "Sie und Ihre Kinder bereits gut versichert? Können Sie durch einen Anbieterwechsel Geld sparen? Wie "+
      "legen Sie Ihr Erspartes am besten an? "+
      "</p> "+
      "<p class='p-text'>Wie Sie sich Ihre Zukunft auch vorstellen, unsere Finanzcoaches sind für Sie da. Gemeinsam "+
      "mit Ihnen analysieren wir Ihre finanzielle Situation und prüfen, wo es Optimierungspotenzial gibt. Sind "+
      "Sie und Ihre Kinder bereits gut versichert? Können Sie durch einen Anbieterwechsel Geld sparen? Wie "+
      "legen Sie Ihr Erspartes am besten an? "+
      "</p>"
    },
    {
      title: 'Patchwork Family',
      urlName: 'patchwork-family',
      image: "url('/assets/patchwork.jpg')",
      mobileImage: "url('/assets/patchwork-mobile.jpg')",
      imagePosition: "50% 35%",
      content: "<h2 class='title-text'>Patchwork-Family: Massgeschneiderte Lösungen für Ihre Familie</h2> "+
      "<p class='p-text'>Patchwork-Familien sind heute nichts Ungewöhnliches mehr. Bringen ein oder beide Partner  "+
      "Kinder mit in die neue Beziehung, gehen damit aber einige Besonderheiten einher. Die SSM Partner AG  "+
      "unterstützt Sie bei der Suche nach den optimalen Versicherungen, Vorsorge- und Finanzierungslösungen. "+
      "Wie Sie sich Ihre gemeinsame Zukunft auch vorstellen, unsere produktunabhängigen Finanzcoaches finden  "+
      "massgeschneiderte Antworten.</p> "+
      "<h2 class='title-text'>Diese Fragen beschäftigen Patchwork-Familien</h2> "+
      "<p class='p-text'>Patchwork-Familien stehen vor spezifischen Herausforderungen. Immerhin gilt es, die  "+
      "eigenen Bedürfnisse mit denen der Kinder und der Ex-Partner unter einen Hut zu bringen. Planen Sie, eine  "+
      "eigene Familie zu gründen, sind vorab unter anderem diese Fragen zu klären:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Wie möchten Sie in Zukunft zusammen leben? Möchten Sie heiraten oder entscheiden Sie sich fürs Konkubinat?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Möchten Sie zusammenziehen, eventuell sogar in eine eigene Wohnung oder ein eigenes Haus?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie teilen Sie gemeinsam angeschaffte Sachwerte untereinander auf?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wo liegt der Lebensmittelpunkt für die Kinder, welche die Partner mit in die Beziehung bringen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Welche Vereinbarungen bezüglich Unterhalt und Kinderbetreuung gibt es mit den Ex-Partnern?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Planen Sie gemeinsame Kinder?</p><p class='sub-p'><span class='line'>-</span> Wie möchten Sie Vollmachten für Finanzen und das Mitspracherecht in Notfällen regeln?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Ist eine gemeinsame Steuererklärung möglich?</p><p class='sub-p'><span class='line'>-</span> Wie möchten Sie für das gemeinsame Alter vorsorgen?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie möchten Sie sich beruflich weiterentwickeln?</p><p class='sub-p'><span class='line'>-</span> Wie können Sie für die Ausbildung Ihrer Kinder vorsorgen? </p></p>"+
      "<h2 class='title-text'>Optimal beraten in die gemeinsame Zukunft starten</h2> "+
      "<p class='p-text'>Wir unterstützen Ihre Patchwork-Family beim Projekt 'Gemeinsame Zukunft'. Zusammen mit  "+
      "Ihnen analysieren wir Ihre finanzielle Ausgangssituation. Welches Einkommen hat Ihre Familie zur  "+
      "Verfügung? Erhalten Sie eventuell Unterstützung von Eltern, Verwandten oder Paten der Kinder? Unsere "+
      "Finanzcoaches zeigen Ihnen, wie Sie am besten sparen können und so im Alltag mehr Geld zur Verfügung "+
      "haben. Ausserdem finden wir die optimalen Anlage- und Finanzierungsprodukte, damit Sie sich auch "+
      "grössere Wünsche erfüllen können. So können Sie Träume von gemeinsamen Reisen oder einem neuen Auto in "+
      "die Tat umsetzen. "+
      "</p> "+
      "<p class='p-text'>Haben Sie Fragen zur Versicherung und Altersvorsorge, helfen wir Ihnen ebenfalls gerne "+
      "weiter. Wir prüfen, ob Sie und Ihre Kinder optimal versichert sind und wann sich ein Anbieterwechsel "+
      "lohnt. Auch bei der Nachlassplanung sind wir für Sie da und sorgen dafür, dass Ihre Angehörigen gut "+
      "abgesichert sind. Unser ganzheitliches Finanzcoaching geht dabei immer von Ihren Wünschen und Zielen "+
      "aus. Wir beraten Sie persönlich und produktunabhängig. So erhalten Sie Lösungen, die genau auf Ihre "+
      "Bedürfnisse als Patchwork-Family zugeschnitten sind. Möchten Sie mehr erfahren? Dann nehmen Sie gleich "+
      "Kontakt zu uns auf! "+
      "</p>"
    },
    {
      title: 'Geschiedene und Alleinerziehende',
      urlName: 'geschiedene-und-alleinerziehende',
      image: "url('/assets/geschiendene.jpg')",
      mobileImage: "url('/assets/geschiende-mobile.jpg')",
      imagePosition: "50% 45%",
      content: "<h2 class='title-text'>Geschiedene und Alleinerziehende: Persönliche Beratung für Ihre Finanzen</h2> "+
      "<p class='p-text'>Geht eine Beziehung in die Brüche, bedeutet dies meist eine grosse seelische Belastung. "+
      "Vor allem, wenn Kinder von der Trennung betroffen sind, stehen Familien vor einer anspruchsvollen "+
      "Herausforderung. Die SSM Partner AG lässt Sie auch in dieser Lebensphase nicht allein. Wir beraten "+
      "Geschiedene und Alleinerziehende in allen finanziellen Fragen. Möchten Sie wissen, wie sich Ihr "+
      "Versicherungsbedarf und Ihre Altersvorsorge verändern, wie Sie Ihre Kinder am besten absichern und "+
      "clever sparen, helfen unsere Finanzcoaches Ihnen weiter.</p> "+
      "<h2 class='title-text'>Mit welchen Fragen sich Geschiedene und Alleinerziehende auseinandersetzen müssen "+
      "</h2> "+
      "<p class='p-text'>Trennt sich ein Paar, sind viele Fragen zu klären. Abstimmungsbedarf besteht zum Beispiel "+
      "bei der Gütertrennung, der Kinderbetreuung und den Unterhaltszahlungen. Einige wichtige Fragen im "+
      "Überblick:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Wer zieht aus der gemeinsamen Wohnung aus?</p><p class='sub-p'><span class='line'>-</span> Wie regeln Sie die Betreuung der Kinder?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wer ist zur Unterhaltszahlung verpflichtet?</p><p class='sub-p'><span class='line'>-</span> Welche Veränderungen bedeutet die Trennung hinsichtlich Ihrer Krankenversicherung?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Was verändert sich in Bezug auf Ihre Altersvorsorge?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Welche beruflichen Pläne verfolgen Sie nach der Scheidung?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie können alleinerziehende Elternteile Berufstätigkeit und Kindererziehung miteinander vereinen? Welche Betreuungslösungen kommen in Frage?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie lange bleiben die Kinder noch zuhause wohnen?</p><p class='sub-p'><span class='line'>-</span> Wie soll die Ausbildung der Kinder finanziert werden?</p>"+
      "<p class='sub-p'><span class='line'>-</span> Was verändert sich durch eine Trennung bezüglich Ihrer Nachlassplanung? Müssen Sie eventuell Ihr Testament ändern? </p></p>"+
      "<h2 class='title-text'>Optimieren Sie Ihre Finanzen</h2> "+
      "<p class='p-text'>Insbesondere Alleinerziehende müssen viele verschiedene Dinge unter einen Hut bringen. Zum "+
      "einen sollen die Kinder optimal abgesichert sein, zum anderen möchte man sich auch selbst verwirklichen. "+
      "Da viele Alleinerziehende in Teilzeit arbeiten, ist das Geld oft knapp. Der Wunsch nach gemeinsamen "+
      "Reisen und Unternehmungen mit den Kindern ist dennoch da.</p> "+
      "<p class='p-text'>Unsere Finanzcoaches helfen Ihnen, Ihre finanzielle Situation zu optimieren. Gemeinsam mit "+
      "Ihnen analysieren wir Ihre finanziellen Möglichkeiten. Wir finden heraus, wie Sie sparen und sich so den "+
      "ein oder anderen Traum erfüllen können. Sparpotenzial bietet zum Beispiel der Wechsel zu einer anderen "+
      "Versicherung. Da wir produktunabhängig arbeiten, können wir aus der Vielzahl an Angeboten diejenigen "+
      "auswählen, die am besten zu Ihnen passen. Unser ganzheitliches Finanzcoaching geht dabei immer von Ihren "+
      "Wünschen und Zielen aus und hat Ihre Zukunft im Blick. Nehmen Sie gleich Kontakt zu uns auf und lassen "+
      "sich individuell beraten!</p>"
    },
    {
      title: 'Vor der Pensionierung',
      urlName: 'vor-der-pensionierung',
      image: "url('/assets/vonder.png')",
      mobileImage: "url('/assets/vonder-mobile.jpg')",
      imagePosition: "50% 5%",
      content: "<h2 class='title-text'>Finanzplanung vor der Pensionierung: Gut abgesichert in den Ruhestand</h2> "+
      "<p class='p-text'>Auch nach der Pensionierung möchten Rentner heute noch aktiv bleiben. Das Ende des "+
      "Arbeitslebens stellt dabei den Übergang in eine neue, spannende Lebensphase dar. Frei von finanziellen "+
      "Verpflichtungen und mit den Kindern aus dem Haus können jetzt lang gehegte Träume verwirklicht werden. "+
      "Ob Sie auf Weltreise gehen wollen oder ein neues Hobby ausprobieren möchten: Die SSM Partner AG "+
      "unterstützt Sie bei Ihrer Finanzplanung. Sie verraten uns, welche Ziele Sie nach der Pensionierung "+
      "verfolgen - wir finden massgeschneiderte Lösungen.</p> "+
      "<h2 class='title-text'>Was Sie vor der Pensionierung bedenken sollten</h2> "+
      "<p class='p-text'>Damit Sie sich nach der Pensionierung selbst verwirklichen können, sind einige Fragen zu "+
      "klären. Dabei geht es nicht nur um die Finanzierung besonderer Wünsche, sondern auch um die Absicherung "+
      "der Familie. Unter anderem sollten Sie folgende Punkte bedenken:</p> "+
      "<p class='p-text li-text'><p class='sub-p'><span class='line'>-</span> Möchten Sie Ihren Job bis zur Pensionierung behalten oder planen Sie eine Frühpension? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Wollen Sie auch nach der Pensionierung einer Beschäftigung nachgehen? Was gibt es dabei zu beachten? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Haben Sie Ihre Altersvorsorge bereits geregelt? Gibt es Optimierungsbedarf? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie viel Geld benötigen Sie, um nach der Pensionierung Ihren Lebensstandard zu halten und Träume zu verwirklichen? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie sieht Ihre Wohnsituation aus? Wohnen Sie zur Miete oder im Eigenheim? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Erwarten Sie eventuell Erbschaften oder Schenkungen? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Möchten Sie einen Teil Ihres Vermögens zu Lebzeiten als Schenkung an Angehörige vermachen? Welche Vorteile bietet dies? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie können Sie nach der Pensionierung Steuern sparen? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie sieht Ihre gesundheitliche Situation aus? Sind Sie oder Ihr Partner pflegebedürftig? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Wie haben Sie für den Pflegefall vorgesorgt? </p>"+
      "<p class='sub-p'><span class='line'>-</span> Haben Sie bereits Ihre Nachlassplanung gemacht?</p></p> "+
      "<h2 class='title-text'>Unabhängige Beratung für eine optimale Finanzplanung</h2> "+
      "<p class='p-text'>Unsere produktunabhängigen Finanzcoaches sind bei allen Fragen rund um die Pensionierung "+
      "für Sie da. Wir prüfen Ihre aktuelle Altersvorsorge und zeigen, wie Sie diese noch optimieren können. "+
      "Wir finden Anlage- und Finanzierungsstrategien für ein unbeschwertes Leben nach der Pensionierung. "+
      "Gemeinsam mit Ihnen klären wir auch, wie Sie Ihre Familie am besten absichern und unterstützen Sie "+
      "dabei, Erbschaftsangelegenheiten zu regeln. Wünschen Sie die persönliche und bedarfsgerechte Beratung, "+
      "nehmen Sie gleich Kontakt zu uns auf!</p>"
    }

  ]

}
