<div class="main-wrapper">
    <div class="intro-wrapper">
        <div class="container">
            <div class="title-wrapper">
                <h2>SSM Partner mit neuer Fahrzeugflote unterwegs</h2>
                <div class="info-wrapper">
                    <div class="calendar"><img src="assets/calendar.svg" alt="">9. Dezember 2015</div>
                    <div class="user"><img src="assets/user.svg" alt="">Salome Tscheulin</div>
                    <div class="comment"><img src="assets/comment.svg" alt="">9 Kommentare</div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
         <div class="p-intro">
            <p>
                Am Dienstag, 10. November 2015 durfe die SSM Partner AG ihre neue Fahrzeugflote, bestehend aus
                dreizehn trendigen und farbenfrohen New Fiat 500 im Hammer Auto Center Emmenbrücke entgegen
                nehmen.
            </p>
            <p>
                Die flinken und wendigen Fiat 500 stehen der SSM Partner AG ab sofort tätkraftig zur
                Seite und bringen neben dem kompakten Design, welches sich spielend leicht in jede Parklücke manövrieren
                lässt, Farbe und Freude in den Arbeitsalltag der rund 15 Mitarbeitenden. Wir freuen uns riesig!
            </p>
        </div>
        <div class="slider-wrapper">
            <span class="left-arrow" (click)="slideImage(activeImage,'left')"><img src="assets/arrow-left.svg" alt=""></span>
            <div class="active-image">
                <img src={{gallery[activeImage]}} alt="">
            </div>
            <span class="right-arrow" (click)="slideImage(activeImage,'right')"><img src="assets/arrow-right.svg" alt=""></span>
            <div class="images-wrapper">
                <img src={{image}} alt="" *ngFor="let image of gallery; let i = index" [ngClass]="{'active-gallery': i == activeImage}" (click)="setActive(i)">
            </div>
        </div>
        <div class="comment-wrapper">
            <h3>Kommentare (22)</h3>
            <div class="comment-card">
                <div class=avatar-wrapper>
                    <img src="assets/user.svg" alt="">
                </div>
                <div class="comment-content">
                    <div class="header-comment">
                        <h2 class="user-name">Man Muster <span>/ Today at 12:34</span></h2>
                        <p>
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                            laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                            architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione
                            voluptatem sequi nesciunt.
                        </p>
                    </div>
                </div>
            </div>
            <div class="comment-card">
                <div class=avatar-wrapper>
                    <img src="assets/user.svg" alt="">
                </div>
                <div class="comment-content">
                    <div class="header-comment">
                        <h2 class="user-name">Man Muster <span>/ Today at 12:34</span></h2>
                        <p>
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod
                            maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.
                        </p>
                    </div>
                </div>
            </div>
            <div class="comment-card">
                <div class=avatar-wrapper>
                    <img src="assets/user.svg" alt="">
                </div>
                <div class="comment-content">
                    <div class="header-comment">
                        <h2 class="user-name">Man Muster <span>/ Today at 12:34</span></h2>
                        <p>
                            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati
                            cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id
                            est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam
                            libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod
                            maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.
                            Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut
                            et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a
                            sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis
                            doloribus asperiores repellat.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>