<div class="main-wrapper">
    <div class="intro-wrapper" (window:resize)="onResize($event)">
        <div class="img" [ngStyle]="{'background-image': image, 'background-position': imagePosition}" *ngIf="desktop">
        </div>
        <div class="img" [ngStyle]="{'background-image': mobileImage, 'background-position': imagePosition}" *ngIf="mobile">
        </div>
    </div>
    <div class="container">
        <div class="buttons-wrapper-new">
            <div class="left-button active">
                <span>Hauptsitz</span>
            </div>
            <div class="right-button" [routerLink]="['/agenture/bern']">
                <span>Bern</span>
            </div>
            <div class="right-button" [routerLink]="['/agenture/buchs']">
                <span>Buchs</span>
            </div>
            <div class="right-button" [routerLink]="['/agenture/lausanne']">
                <span>Lausanne</span>
            </div>
            <div class="right-button" [routerLink]="['/agenture/luzern']">
                <span>Luzern</span>
            </div>
            <div class="right-button" [routerLink]="['/agenture/olten']">
                <span>Olten</span>
            </div>
            <div class="right-button" [routerLink]="['/agenture/pfungen']">
                <span>Pfungen</span>
            </div>
            <div class="right-button" [routerLink]="['/agenture/st-gallen']">
                <span>St.Gallen</span>
            </div>
            <div class="right-button" [routerLink]="['/agenture/ticino']">
                <span>Ticino</span>
            </div>
            <!-- <div class="right-button" [routerLink]="['/agenture/zofingen']">
                <span>Zofingen</span>
            </div> -->
            <!-- <div class="right-button" [routerLink]="['/agenture/zug']">
                <span>Zug</span>
            </div> -->
            <div class="right-button" [routerLink]="['/agenture/zurich-nord']">
                <span>Zürich Nord</span>
            </div>
            <div class="right-button" [routerLink]="['/agenture/zurich-sud']">
                <span>Zürich Süd</span>
            </div>
            <div style="visibility: hidden;"></div>
        </div>
        <div class="head-office">
            <h4>Geschäftsleitung</h4>
            <!-- <div class="address">
                <div class="location-wrapper">
                    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [mapTypeControl]='false'
                        [zoomControl]="true" [styles]="style" [scrollwheel]="false" [streetViewControl]="false">
                
                        <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker(m.label, i)"
                            [latitude]="m.lat" [longitude]="m.lng" [label]="m.label" [markerDraggable]="m.draggable"
                            (dragEnd)="markerDragEnd(m, $event)">
                 -->
                            <!-- <agm-info-window>
                          <strong>InfoWindow content</strong>
                        </agm-info-window> -->
                
                        <!-- </agm-marker> -->
                
                        <!-- <agm-circle [latitude]="lat + 0.3" [longitude]="lng" 
                          [radius]="5000"
                          [fillColor]="'red'"
                          [circleDraggable]="true"
                          [editable]="true">
                      </agm-circle> -->
                
                    <!-- </agm-map>
                </div>
                <div class="address-left">
                    <h3 class="main">SSM Partner AG</h3>
                    <h3>Stationsstrasse 92</h3>
                    <h3>6023 Rothenburg</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:0412202050'}}">+41 41 220 20 50</a></h3>
                    <h3>Email: <a href="mailto:info@ssmpartner.ch">info@ssmpartner.ch</a></h3>
                </div>
            </div> -->
            <div class="row-card">
                <div class="card">
                    <div class="img-wrapper">
                        <img src="assets/killer.png" alt="" class="mainimg">
                        <div class="mask">
                            <p><a href="mailto:martin.killer@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                        </div>
                    </div>
                    <div class="infos">
                        <h5>Martin Killer</h5>
                        <p>CEO</p>
                    </div>
                </div>
                <div class="card">
                    <div class="img-wrapper">
                        <img src="assets/davide4.png" alt="" class="mainimg">
                        <div class="mask">
                            <p><a href="mailto:ds@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                        </div>
                    </div>
                    <div class="infos">
                        <h5>Davide Spadea</h5>
                        <p>COO</p>
                    </div>
                </div>
                <div class="card">
                    <div class="img-wrapper">
                        <img src="assets/artan.png" alt="" class="mainimg">
                        <div class="mask">
                            <p><a href="mailto:as@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                        </div>
                    </div>
                    <div class="infos">
                        <h5>Artan Selmanaj</h5>
                        <p>CHRO</p>
                    </div>
                </div>
                <div class="card">
                    <div class="img-wrapper">
                        <img src="assets/deroca.png" alt="" class="mainimg">
                        <div class="mask">
                            <p><a href="mailto:sandra.dellarocca@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                        </div>
                    </div>
                    <div class="infos">
                        <h5>Sandra Della Rocca</h5>
                        <p>CFO</p>
                    </div>
                </div>
            </div>
            <div class="row-card">
                <div class="card">
                    <div class="img-wrapper">
                        <img src="assets/azra.png" alt="" class="mainimg">
                        <div class="mask">
                            <p><a href="mailto:azra.sabotic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                        </div>
                    </div>
                    <div class="infos">
                        <h5>Azra Sabotic</h5>
                        <p>GL-Assistenz</p>
                    </div>
                </div>
                <div class="card">
                    <div class="img-wrapper">
                        <img src="assets/florent.png" alt="" class="mainimg">
                        <div class="mask">
                            <p><a href="mailto:ft@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                        </div>
                    </div>
                    <div class="infos">
                        <h5>Florent Tahiraj</h5>
                        <p>Vertriebsleiter</p>
                    </div>
                </div>
                <div class="card">
                    <div class="img-wrapper">
                        <img src="assets/manuel.png" alt="" class="mainimg">
                        <div class="mask">
                            <p><a href="mailto:mg@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                        </div>
                    </div>
                    <div class="infos">
                        <h5>Manuel Gomes</h5>
                        <p>Leiter Ausbildungen</p>
                    </div>
                </div>
                <div class="card">
                    <div class="img-wrapper">
                        <img src="assets/pero3.png" alt="" class="mainimg">
                        <div class="mask">
                            <p><a href="mailto:it@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                        </div>
                    </div>
                    <div class="infos">
                        <h5>Pero Janjic</h5>
                        <p>Leiter Digitalisierung</p>
                    </div>
                </div>
                <div class="card">
                    <div class="img-wrapper">
                        <img src="assets/bilel2.png" alt="" class="mainimg">
                        <div class="mask">
                            <p><a href="mailto:bilel.chagra@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                        </div>
                    </div>
                    <div class="infos">
                        <h5>Bilel Chagra</h5>
                        <p>Head of Marketing</p>
                    </div>
                </div>
        </div>
        <div class="tabordion">
            <!-- <div class="address">
                <div class="address-left">
                    <h3>SSM Partner AG</h3>
                    <h3>Stationsstrasse 90</h3>
                    <h3>6023 Rothenburg</h3>
                </div>
                <div class="address-left">
                    <h3>Tel. <a href="{{'tel:0412202050'}}">+41 41 220 20 50</a></h3>
                    <h3>Email: <a href="mailto:info@ssmpartner.ch">info@ssmpartner.ch</a></h3>
                </div>
            </div> -->
            <!-- <section id="section1" #sectionOne>
                <label id="option1" (click)="showtab('firstTabState')"><span
                        [ngClass]="{rotate : states.firstTabState=='open'}">+</span>
                    <p>Geschäftsleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.firstTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/artan.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #artan (click)="openInfo(artan)">
                                    <h2>Artan Selmanaj <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>HUMAN RESOURCES</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:as@ssmpartner.ch">as@ssmpartner.ch </a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/davide.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #davide (click)="openInfo(davide)">
                                    <h2>Davide Spadea <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>VERTRIEBSLEITER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ds@ssmpartner.ch">ds@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card"></div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section> -->
            <!-- <section id="section2" #sectionTwo>
                <label id="option2" (click)="showtab('secondTabState')"><span
                        [ngClass]="{rotate : states.secondTabState=='open'}">+</span>
                    <p>Kader</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.secondTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/azra.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #azra (click)="openInfo(azra)">
                                    <h2>Azra Sabotic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-m-new">CONTROLLING & PROZESSE VERMITTLERBETREUERIN</h5>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:azs@ssmpartner.ch">azs@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/florent.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #florent (click)="openInfo(florent)">
                                    <h2>Florent Tahiraj <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>LEITER AUSSENDIENST</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ft@ssmpartner.ch">ft@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/gerardo2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #gerardo (click)="openInfo(gerardo)">
                                    <h2>Gerardo Rosamilia <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KEY ACCOUNT MANAGER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gr@ssmpartner.ch">gr@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/manuel.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #manuelTwo (click)="openInfo(manuelTwo)">
                                    <h2 class="short-h">Manuel Gomes <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-m">LEITER VORSORGE UND AUSBILDUNG</h5>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:tc@ssmpartner.ch">mg@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/manuel.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #manuel (click)="openInfo(manuel)">
                                    <h2 class="short-h">Manuel Gomes <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-m">LEITER VORSORGE UND AUSBILDUNG</h5>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:tc@ssmpartner.ch">mg@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/pero.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #pero (click)="openInfo(pero)">
                                    <h2>Pero Janjic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-m-new">{{'Leiter Corporate IT & Digitalisierung' | uppercase}}</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:pero@ssmpartner.ch">pero@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/sinan.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sinan (click)="openInfo(sinan)">
                                    <h2>Sinan Bulica <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>VERKAUFSLEITER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sb@ssmpartner.ch">sb@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <section id="newSectionBuch" #sectionNewBucg>
                <label id="optionNewBuch" (click)="showtab('newTabStateBuch')"><span
                        [ngClass]="{rotate : states.newTabStateBuch=='open'}">+</span>
                    <p>Finanzen</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabStateBuch>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dorner2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:kamila.dorner@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Kamila Dorner</h2>
                                    <h5>{{'Provisionen' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/clirim2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:clirim.shabani@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Clirim Shabani</h2>
                                    <h5>{{'Provisionen' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/kaltrina.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:kaltrina.ramadani@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Kaltrina Ramadani</h2>
                                    <h5>{{'Buchhaltung' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section>
            <section id="newSectionKey" #sectionNewKey>
                <label id="optionNewKey" (click)="showtab('newTabStateKey')"><span
                        [ngClass]="{rotate : states.newTabStateKey=='open'}">+</span>
                    <p>Key Account</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabStateKey>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ghanesh.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:gth@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Tharsan Ganesh</h2>
                                    <h5>{{'Key Account' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/gerardo2.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:gr@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Gerardo Rosamilia</h2>
                                    <h5>{{'Key Account' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                                <!-- <div class="img-wrapper">
                                    <img src="assets/sajic2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:igor.sajic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Igor Sajic</h2>
                                    <h5>{{'Key Account' | uppercase}}</h5>
                                </div> -->
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section>
            <section id="sectionNewKeyOne" #sectionNewKeyOne>
                <label id="optionNewKeyOne" (click)="showtab('newTabStateKeyOne')"><span
                        [ngClass]="{rotate : states.newTabStateKeyOne=='open'}">+</span>
                    <p>Experten</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabStateKeyOne>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sajic2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:igor.sajic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Igor Sajic</h2>
                                    <h5>{{'Finanzexperten' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sandro3.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:sandro.trachsel@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Sandro Trachsel</h2>
                                    <h5>{{'Finanzexperten' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                                <!-- <div class="img-wrapper">
                                    <img src="assets/sajic2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:igor.sajic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Igor Sajic</h2>
                                    <h5>{{'Key Account' | uppercase}}</h5>
                                </div> -->
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section>
            <section id="newAgent" #newAgent>
                <label id="optionNewAgent" (click)="showtab('newTabStateAgent')"><span
                        [ngClass]="{rotate : states.newTabStateAgent=='open'}">+</span>
                    <p>Marketing & Academy</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabStateAgent>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ivo.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:ivo.branco@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Ivo Branco</h2>
                                    <h5>{{'Graphic Design & Marketing' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/junker.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:sj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Sara Junker</h2>
                                    <h5>{{'Leiterin Kompetenzzentrum' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section>
            <section id="section910" #sectionNineTen>
                <label id="option910" (click)="showtab('nineTenTabState')"><span
                        [ngClass]="{rotate : states.nineTenTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.nineTenTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dominik2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:dk@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Dominik Kabashi</h2>
                                    <h5>ADMINISTRATION</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/vivian.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:vl@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Vivian Delle Fave</h2>
                                    <h5>LEITERIN ANTRAGSVERARBEITUNG</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section8" #sectionEight>
                <label id="option8" (click)="showtab('eightTabState')"><span
                        [ngClass]="{rotate : states.eightTabState=='open'}">+</span>
                    <p>Lernende/Praktikanten</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alesia.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:alesia.nikollbibaj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Alesia Nikollbibaj</h2>
                                    <h5>{{'Lernende 1. Lehrjahr' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/fiona3.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:fiona.ferataj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Fiona Ferataj</h2>
                                    <h5>{{'Lernende 2. Lehrjahr' | uppercase}}</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <!-- <section id="newSection" #sectionNew>
                <label id="optionNew" (click)="showtab('newTabState')"><span
                        [ngClass]="{rotate : states.newTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ardrit.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:ar@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Ardrit Rushiti</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/betrim.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:bes@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Betrim Shabani</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/tiago.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:tc@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Tiago Costa Albino</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/tarzan.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:gth@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Tharsan Ganesh</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section> -->
            <!-- <section id="newSectionBuch" #sectionNewBucg>
                <label id="optionNewBuch" (click)="showtab('newTabStateBuch')"><span
                        [ngClass]="{rotate : states.newTabStateBuch=='open'}">+</span>
                    <p>Buchhaltung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabStateBuch>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dorner.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:kamila.dorner@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Kamila Dorner</h2>
                                    <h5>FINANZWESEN</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section> -->
            <!-- <section id="section910" #sectionNineTen>
                <label id="option910" (click)="showtab('nineTenTabState')"><span
                        [ngClass]="{rotate : states.nineTenTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.nineTenTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/anesa.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:anesa.seferovic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Anesa Seferovic</h2>
                                    <h5>Innendienst</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/clirim.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:clirim.shabani@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Clirim Shabani</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dominik.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:dk@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Dominik Kabashi</h2>
                                    <h5>SALES SUPPORT</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/djellona.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:djellona.hajrullahu@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Djellona Hajrullahu</h2>
                                    <h5>ADMINISTRATION</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/melani.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:mj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Melani Janosevic</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/vivian.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:vl@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Vivian Delle Fave</h2>
                                    <h5>LEITERIN ANTRAGSVERARBEITUNG</h5>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/anesa.png" alt="">
                                </div>
                                <div class="info-wrapper" #anesa (click)="openInfo(anesa)">
                                    <h2>Anesa Seferovic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-position">{{ 'Innendienst' | uppercase }}</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p class="short-email"><a href="mailto:anesa.seferovic@ssmpartner.ch">anesa.seferovic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/clirim.png" alt="">
                                </div>
                                <div class="info-wrapper" #clirim (click)="openInfo(clirim)">
                                    <h2>Clirim Shabani <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="vl:clirim.shabani@ssmpartner.ch">clirim.shabani@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dominik.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #dominik (click)="openInfo(dominik)">
                                    <h2>Dominik Kabashi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>SALES SUPPORT</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:dk@ssmpartner.ch">dk@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/djellona.png" alt="">
                                </div>
                                <div class="info-wrapper" #djellona (click)="openInfo(djellona)">
                                    <h2>Djellona Hajrullahu <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>ADMINISTRATION</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:djellona.hajrullahu@ssmpartner.ch">djellona.hajrullahu@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/djellona.png" alt="">
                                </div>
                                <div class="info-wrapper" #djellonaTwo (click)="openInfo(djellonaTwo)">
                                    <h2>Djellona Hajrullahu <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>ADMINISTRATION</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:djellona.hajrullahu@ssmpartner.ch">djellona.hajrullahu@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/melani.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #melani (click)="openInfo(melani)">
                                    <h2 class="short-h">Melani Janosevic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mj@ssmpartner.ch">mj@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/vivian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vivian (click)="openInfo(vivian)">
                                    <h2>Vivian Delle Fave <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-position">LEITERIN ANTRAGSVERARBEITUNG</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="vl:rh@ssmpartner.ch">vl@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    <!-- </article>
                </div>
            </section> -->
            <!-- <section id="section3" #sectionThree>
                <label id="option3" (click)="showtab('thirdTabState')"><span
                        [ngClass]="{rotate : states.thirdTabState=='open'}">+</span>
                    <p>Antragsverarbeitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.thirdTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/vivian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vivian (click)="openInfo(vivian)">
                                    <h2>Vivian Delle Fave <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>LEITERIN ANTRAGSVERARBEITUNG</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="vl:rh@ssmpartner.ch">vl@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">

                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <!-- <section id="section5" #sectionFive>
                <label id="option5" (click)="showtab('fifthTabState')"><span
                        [ngClass]="{rotate : states.fifthTabState=='open'}">+</span>
                    <p>Sach- & Lebensversicherungen</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <!-- <section id="section10" #section10>
                <label id="option5" (click)="showtab('tenTabState')"><span
                        [ngClass]="{rotate : states.tenTabState=='open'}">+</span>
                    <p>Office Management</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.tenTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <!-- <section id="section7" #sectionSeven>
                <label id="option7" (click)="showtab('seventhTabState')"><span
                        [ngClass]="{rotate : states.seventhTabState =='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.seventhTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alban.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:alban.kuci@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Alban Kuci</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/amini.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:as@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Amini Suliman</h2>
                                    <h5>KUNDENBERATER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/arber.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:arh@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Arber Hoxha</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ardian.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:ark@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Ardian Krasniqi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/baja.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:baja.krizevac@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Baja Krizevac</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/besir.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:besir.kastrati@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Besir Kastrati</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/diogo2.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:dsb@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Diogo Da Silva Baptista</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/duzmani.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:emanuel.duzmani@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Emanuel Duzmani</h2>
                                    <h5>KUNDENBERATER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/francesco.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:francesco.curcio@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Francesco Curcio</h2>
                                    <h5>KUNDENBERATER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ibrahim.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:io@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Ibrahim Onac</h2>
                                    <h5>KUNDENBERATER</h5>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/laska.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:laska.robert@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Laska Robert</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/leotrim2.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:les@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Leotrim Selmanaj</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sara2.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:sasj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Sara Junker</h2>
                                    <h5>KUNDENBERATERIN</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/silviu.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:silviu.toma@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Silviu Mihai Toma</h2>
                                    <h5>KUNDENBERATER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:sergio.linares@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Sergio Linares</h2>
                                    <h5>KUNDENBERATER</h5>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/vincenzo.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:cv@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Vincenzo Colucci</h2>
                                    <h5>KUNDENBERATER</h5>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alban.png" alt="">
                                </div>
                                <div class="info-wrapper" #albanTwo (click)="openInfo(albanTwo)">
                                    <h2>Alban Kuci <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:alban.kuci@ssmpartner.ch">alban.kuci@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/amini.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #amini (click)="openInfo(amini)">
                                    <h2>Amini Suliman <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:as@ssmpartner.ch">as@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/arber.png" alt="">
                                </div>
                                <div class="info-wrapper" #arber (click)="openInfo(arber)">
                                    <h2 class="short-h new">Arber Hoxha <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:arh@ssmpartner.ch">arh@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/ardian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ardian (click)="openInfo(ardian)">
                                    <h2 class="short-h new">Ardian Krasniqi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ark@ssmpartner.ch">ark@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/ardian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ardianTwo (click)="openInfo(ardianTwo)">
                                    <h2 class="short-h new">Ardian Krasniqi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ark@ssmpartner.ch">ark@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/baja.png" alt="">
                                </div>
                                <div class="info-wrapper" #baja (click)="openInfo(baja)">
                                    <h2>Baja Krizevac <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:baja.krizevac@ssmpartner.ch">baja.krizevac@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/besir.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #besir (click)="openInfo(besir)">
                                    <h2 class="short-h new">Besir Kastrati <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:besir.kastrati@ssmpartner.ch">besir.kastrati@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/diogo2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #diogo (click)="openInfo(diogo)">
                                    <h2 class="short-h new">Diogo Da Silva Baptista <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:dsb@ssmpartner.ch">dsb@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/duzmani.png" alt="">
                                </div>
                                <div class="info-wrapper" #duzmani (click)="openInfo(duzmani)">
                                    <h2>Emanuel Duzmani<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper short-l-m">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a
                                                    href="mailto:emanuel.duzmani@ssmpartner.ch">emanuel.duzmani@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/francesco.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #francesco (click)="openInfo(francesco)">
                                    <h2>Francesco Curcio<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper short-l-m">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a
                                                    href="mailto:francesco.curcio@ssmpartner.ch">francesco.curcio@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/ibrahim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ibrahim (click)="openInfo(ibrahim)">
                                    <h2>Ibrahim Onac <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:io@ssmpartner.ch">io@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/ibrahim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ibrahimTwoo (click)="openInfo(ibrahimTwoo)">
                                    <h2>Ibrahim Onac <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:io@ssmpartner.ch">io@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/laska.png" alt="">
                                </div>
                                <div class="info-wrapper" #laskaTwo (click)="openInfo(laskaTwo)">
                                    <h2>Laska Robert<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:laska.robert@ssmpartner.ch">laska.robert@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/leotrim2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #leotrimTwo (click)="openInfo(leotrimTwo)">
                                    <h2>Leotrim Selmanaj<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:les@ssmpartner.ch">les@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sara2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #saraTwo (click)="openInfo(saraTwo)">
                                    <h2>Sara Junker <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATERIN</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ose@ssmpartner.ch">sasj@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/silviu.png" alt="">
                                </div>
                                <div class="info-wrapper" #silviu (click)="openInfo(silviu)">
                                    <h2 class="short-h new">Silviu Mihai Toma <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:silviu.toma@ssmpartner.ch">silviu.toma@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sergio (click)="openInfo(sergio)">
                                    <h2>Sergio Linares <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sergio.linares@ssmpartner.ch">sergio.linares@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card" style="float: left;">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sergioTwo (click)="openInfo(sergioTwo)">
                                    <h2>Sergio Linares <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sergio.linares@ssmpartner.ch">sergio.linares@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card" style="float: left; margin-left:4%;">
                                <div class="img-wrapper">
                                    <img src="assets/vincenzo.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vincenzo (click)="openInfo(vincenzo)">
                                    <h2>Vincenzo Colucci <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:cv@ssmpartner.ch">cv@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/vincenzo.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vincenzoTwo (click)="openInfo(vincenzoTwo)">
                                    <h2>Vincenzo Colucci <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:cv@ssmpartner.ch">cv@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card tc-card">
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mathias.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #mathias (click)="openInfo(mathias)">
                                    <h2>Mathias Cukic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mathias.cukic@ssmpartner.ch">mathias.cukic@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mauro.png" alt="">
                                </div>
                                <div class="info-wrapper" #mauro (click)="openInfo(mauro)">
                                    <h2>Mauro Caruso <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mauro.caruso@ssmpartner.ch">mauro.caruso@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sara2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #saraTwo (click)="openInfo(saraTwo)">
                                    <h2>Sara Junker <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATERIN</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ose@ssmpartner.ch">sasj@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sergio (click)="openInfo(sergio)">
                                    <h2>Sergio Linares <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sergio.linares@ssmpartner.ch">sergio.linares@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    <!-- </article>
                </div>
            </section> -->
            <!-- <section id="section8" #sectionEight>
                <label id="option8" (click)="showtab('eightTabState')"><span
                        [ngClass]="{rotate : states.eightTabState=='open'}">+</span>
                    <p>Lernende/Praktikanten</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/fiona.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:fiona.ferataj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Fiona Ferataj</h2>
                                    <h5>Lernende/Praktikanten</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/giorgia.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:ger@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Giorgia Raspa</h2>
                                    <h5>LEHRLING</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/fiona.png" alt="">
                                </div>
                                <div class="info-wrapper" #giorgia (click)="openInfo(giorgia)">
                                    <h2>Fiona Ferataj <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>Lernende/Praktikanten</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:fiona.ferataj@ssmpartner.ch">fiona.ferataj@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/giorgia.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #giorgia (click)="openInfo(giorgia)">
                                    <h2>Giorgia Raspa <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>LEHRLING</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ger@ssmpartner.ch">ger@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                        </div> -->
                    <!-- </article>
                </div>
            </section> -->
        </div>

        </div>
    </div>
    <!-- <div class="central">
        <div class="container">
            <h2>Unsere Leidenschaft zeichnet uns aus.</h2>
        </div>
    </div>
    <div class="container">
        <div class="agenture">
            <h3>Agenturen</h3>
            <div class="ag-wrapper">
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/pfungen']">
                        <h2>Agentur Pfungen</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/buchs']">
                        <h2>Agentur Buchs</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/bern']">
                        <h2>Agentur Bern</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="ag-wrapper">
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/olten']">
                        <h2>Agentur Olten</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/st-gallen']">
                        <h2>Agentur St. Gallen</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/ticino']">
                        <h2>Agentur Ticino</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="ag-wrapper">
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/zofingen']">
                        <h2>Agentur Zofingen</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/zurich-sud']">
                        <h2>Agentur Zürich Süd</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/zurich-nord']">
                        <h2>Agentur Zürich Nord</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="ag-wrapper">
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/zug']">
                        <h2>Agentur Zug</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag">
                </div>
                <div class="ag">
                </div>
            </div>
        </div>
    </div> -->
</div>