import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  gallery: any[] = ["assets/1.jpg","assets/2.jpg","assets/3.jpg","assets/2.jpg"]
  activeImage: number = 0
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  slideImage(index,side){
    if(side == 'right'){
      this.activeImage = index + 1
      if(this.activeImage >= this.gallery.length){
        this.activeImage = 0
      }
    }else{
      this.activeImage = index - 1
      if(this.activeImage < 0){
        this.activeImage = this.gallery.length - 1
      }
    }
  }
  setActive(i){
    this.activeImage = i
  }
}
