<div class="main-wrapper">
    <div class="intro-default">
        <div class="container">
            <div class="image-wrapper">
                <img src="assets/maska.svg" alt="">
            </div>
        </div>
    </div>
    <div class="container">
        <h1 class="title-page">Academy</h1>
        <div class="main">
            <div class="section">
                <h2>Die SSM-Academy: Digitales Lernprogramm für unsere Mitarbeitenden </h2>
                <p>Der Versicherungs- und Finanzmarkt ist komplex und befindet sich im ständigen Wandel. Um unseren
                    Kunden die beste Beratung bieten zu können, bedarf es einer soliden Ausbildung und ständigen
                    Weiterbildung unserer Mitarbeitenden. Zu diesem Zweck nutzen wir innovative Technologien wie die
                    SSM-Academy. Das digitale Lernprogramm stellt breitgefächerte Informationen zur Verfügung.</p>
            </div>
            <div class="tabordion">
                <section id="section1" #sectionOne>
                    <label id="option1" (click)="showtab('firstTabState')"><span
                            [ngClass]="{rotate : states.firstTabState=='open'}">+</span>
                        <p>Bestens informiert über Versicherung, Vorsorge und Finanzen</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.firstTabState>
                        <article>
                            <p>Wir möchten unseren Kunden individuell und bedarfsgerecht beraten. Unser Ziel besteht
                                darin, für jede Lebensphase und jede Lebenssituation massgeschneiderte Lösungen und
                                Strategien zu finden. Entsprechend benötigen unsere Finanzcoaches einen umfassenden
                                Überblick über die verfügbaren Anbieter und Produkte der Versicherungs- und
                                Finanzbranche. Mit der SSM-Academy bleiben unsere Mitarbeitenden immer auf dem neuesten
                                Stand. Das digitale Lernprogramm stellt zahlreiche Informationen zum Selbststudium
                                bereit.
                            </p>
                            <p>Die Grundlage für ein ganzheitliches Finanzcoaching bildet eine ausführliche Analyse. Die
                                SSM-Academy bietet die richtigen Werkzeuge. Das Programm beinhaltet unter anderem unser
                                Vorsorge-Analyse-Tool. Hervorragend geschult, können unsere Finanzcoaches und
                                Backend-Mitarbeiter auf alle Eventualitäten und Bedürfnisse eingehen. </p>
                        </article>
                    </div>
                </section>
                <section id="section2" #sectionTwo>
                    <label id="option2" (click)="showtab('secondTabState')"><span
                            [ngClass]="{rotate : states.secondTabState=='open'}">+</span>
                        <p>Leadership-Kompetenzen ausbauen</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.secondTabState>
                        <article>
                            <p>Nicht nur unsere Kunden sollen ihre Ziele und Träume verwirklichen können. Wir möchten,
                                dass auch unsere Mitarbeitenden ihre Pläne in die Tat umsetzen können. Wir unterstützen
                                und fördern berufliches Engagement aktiv. Durch kontinuierliche Weiterbildung helfen wir
                                unseren Mitarbeitenden dabei, beruflich voran zu kommen. Auch für diesen Zweck ist die
                                SSM-Academy ein wichtiges Werkzeug. Ein Fokus liegt auf dem Ausbau der
                                Leadership-Fähigkeiten.</p>
                            <p>Führungskräfte müssen Abläufe planen, organisieren und kontrollieren können. Echtes
                                Leadership erfordert jedoch noch weitere Fähigkeiten. Leadership bedeutet, Menschen mit
                                Visionen inspirieren und motivieren zu können, ein kreatives und sinnerfüllendes
                                Arbeitsumfeld zu schaffen, in dem alle Teammitglieder mit Begeisterung arbeiten. Die
                                SSM-Academy hilft unseren Mitarbeitenden dabei, diese Fähigkeiten auszubauen und in eine
                                Führungsposition aufzusteigen. </p>
                        </article>
                    </div>
                </section>
                <section id="section3" #sectionThree>
                    <label id="option3" (click)="showtab('thirdTabState')"><span
                            [ngClass]="{rotate : states.thirdTabState=='open'}">+</span>
                        <p>SSM-Academy: Jederzeit für Sie verfügbar</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.thirdTabState>
                        <article>
                            <p>Als digitales Lernprogramm ist die SSM-Academy besonders flexibel einsetzbar. Sie steht
                                unseren Mitarbeitenden unabhängig von Zeit und Ort zur Verfügung und ermöglicht eine
                                Weiterbildung gemäss der eigenen Wünsche und Fähigkeiten.</p>
                        </article>
                    </div>
                </section>
            </div>
        </div>

        <div class="sidebar">
            <h2>Informationen anfordern</h2>
            <form class="form-wrapper" [formGroup]="financeForm" action="" *ngIf="!formSidebarSuccess">
                <div class="field">
                    <input formControlName="vorname" type="text" placeholder="Vorname"
                        [ngClass]="{'invalid': (f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)">
                        <span *ngIf="f.vorname.errors.required">Bitte Vorname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="nachname" type="text" placeholder="Nachname"
                        [ngClass]="{'invalid': (f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)">
                        <span *ngIf="f.nachname.errors.required">Bitte Nachname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="email" type="text" placeholder="E-Mail"
                        [ngClass]="{'invalid': (f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)">
                        <span *ngIf="f.email.errors.required">Bitte Email angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="telefon" type="text" placeholder="Telefon"
                        [ngClass]="{'invalid': (f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)">
                        <span *ngIf="f.telefon.errors.required">Bitte Telefon angeben</span>
                    </div>
                </div>
                <div class="checkbox-wrapper">
                    <div class="row">
                        <label class="checkbox">Krankenkasse
                            <input type="checkbox">
                            <span class="checkmark" #krankenkasse
                                (click)="selectType('Krankenkasse', krankenkasse)"></span>
                        </label>
                        <label class="checkbox">Sachversicherung
                            <input type="checkbox">
                            <span class="checkmark" #sachversicherung
                                (click)="selectType('Sachversicherung', sachversicherung)"></span>
                        </label>
                    </div>
                    <div class="row">
                        <label class="checkbox">Unfallversicherung
                            <input type="checkbox">
                            <span class="checkmark" #unfallversicherung
                                (click)="selectType('Unfallversicherung', unfallversicherung)"></span>
                        </label>
                        <label class="checkbox">Vorsorge
                            <input type="checkbox">
                            <span class="checkmark" #vorsorge (click)="selectType('Vorsorge', vorsorge)"></span>
                        </label>
                    </div>
                </div>
                <div class="field area">
                    <textarea formControlName="message" cdkAutosizeMinRows="110" placeholder="Nachricht"
                        cdkAutosizeMaxRows="120"
                        [ngClass]="{'invalid': (f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)}"></textarea>
                    <div class="error-msg"
                        *ngIf="(f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)">
                        <span *ngIf="f.message.errors.required">Bitte Nachricht eingeben</span>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <button class="classic-button" (click)="sendSidebar()">SENDEN</button>
                </div>
            </form>
            <div class="success-mess" *ngIf="formSidebarSuccess">
                Nachricht wurde erfolgreich übermittelt.
            </div>
        </div>
    </div>
</div>