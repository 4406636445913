<div class="main-wrapper">
    <div class="intro-wrapper" (window:resize)="onResize($event)">
        <div class="img" [ngStyle]="{'background-image': component.image, 'background-position': component.imagePosition}" *ngIf="desktop">
        </div>
        <div class="img" [ngStyle]="{'background-image': component.mobileImage, 'background-position': component.imagePosition}" *ngIf="mobile">
        </div>
    </div>
    <div class="container">
        <h1 class="page-title">{{component.title}}</h1>
        <div class="text-wrapper shared" [innerHTML]="component.content">
        </div>
        <div class="sidebar">
            <app-formular></app-formular>
        </div>
    </div>
</div>