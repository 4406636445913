import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-karriere',
  templateUrl: './karriere.component.html',
  styleUrls: ['./karriere.component.scss']
})
export class KarriereComponent implements OnInit {
  innendienst: boolean = false
  aussendienst: boolean = true
  financeForm: FormGroup
  financeTrue: boolean = false
  fileToUpload: File = null;
  fileUpload: boolean = false
  fileName: string = ''
  fileType: string = ''
  startUpload: boolean = false
  formSidebarSuccess: boolean = false
  constructor(private mailService: MailService, private scroll: ScrollService, private ng2ImgMax: Ng2ImgMaxService) { }

  ngOnInit(): void {
    this.financeForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]),
      vorname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      nachname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      telefon: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*[0-9+ /-]*$"), Validators.minLength(2)]),
      message: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });
    this.scroll.setScroll()
  }

  get f() {
    return this.financeForm.controls;
  }

  changeTab(tab) {
    if (tab == 'innendienst') {
      this.innendienst = true;
      this.aussendienst = false;
    }
    if (tab == 'aussendienst') {
      this.innendienst = false;
      this.aussendienst = true;
    }
  }

  async handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (this.fileToUpload != null) {
      this.fileUpload = true
      this.fileType = this.fileToUpload.type
      if (this.fileToUpload.type.includes('image')) {
        if (this.fileToUpload.size > 2000000) {
          this.startUpload = true
        }
        await this.ng2ImgMax.compressImage(this.fileToUpload, 2).subscribe(
          result => {
            this.fileToUpload = new File([result], result.name);
            this.startUpload = false
            this.fileName = result.name
            console.info(this.fileToUpload);
          },
          error => {
            console.log('Oh no!', error);
          }
        );
      }else{
        this.fileName = this.fileToUpload.name
      }
    }
  }

  sendSidebar() {
    this.financeTrue = true
    let formData = new FormData
    let name = this.f.vorname.value + ' ' + this.f.nachname.value

    formData.append('email', this.f.email.value),
      formData.append('name', name),
      formData.append('telefon', this.f.telefon.value),
      formData.append('message', this.f.message.value),
      formData.append('fileType', this.fileType),
      formData.append('career', 'true'),
      formData.append('file', this.fileToUpload),

      this.financeTrue = true;
    if (this.financeForm.valid) {
      console.log('success')
      this.financeTrue = false;
      this.formSidebarSuccess = true;
      this.mailService.sendMail(formData).catch(error => {
        if (error) {
          this.formSidebarSuccess = false;
          console.log(error)
        }
      });
    } else {
      console.log("error");
    }
  }

}
