import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  lebenNavigation:boolean = true
  financeNavigation:boolean = false
  uberNavigation:boolean = false
  activeOptions: string = 'Mein Leben'
  @ViewChild("mobCheck") divView: ElementRef;
  landingPage: boolean = false

  constructor(private router : Router, private location: Location) { }

  ngOnInit(): void {
    if(this.location.path() != '/karriere-pusher'){
      this.landingPage = false;
    }else{
      this.landingPage = true;
    }
  }

  openSelect(element:HTMLElement){
    if(element.classList.contains('open')){
      element.classList.remove("open");
    }
    else{
      element.classList.add("open");
    }
  }

  closeMenu(){
    this.divView.nativeElement.checked = false
  }
  
  setNavigation(option,element:HTMLElement){
    if(option == 'leben'){
      this.lebenNavigation = true;
      this.financeNavigation = false;
      this.uberNavigation = false;
      this.activeOptions ='Mein Leben'
    }
    if(option == 'finance'){
      this.lebenNavigation = false;
      this.financeNavigation = true;
      this.uberNavigation = false;
      this.activeOptions = 'Meine Finanzen'
    }
    if(option == 'uber'){
      this.lebenNavigation = false;
      this.financeNavigation = false;
      this.uberNavigation = true;
      this.activeOptions = 'Über Uns'
    }
    element.classList.remove("open");
  }
}
