import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        'height': '0px',
      })),
      state('open', style({
        'max-height': '800px',
      })),
      transition('open <=> closed', animate(400)),
    ])
  ]
})
export class ImpressumComponent implements OnInit {
  firstTabState = 'closed'
  secondTabState = 'closed'
  thirdTabState = 'closed'
  fourthTabState = 'closed'

  constructor(private scroll: ScrollService) { }

  ngOnInit(): void {
    this.scroll.setScroll()
  }

  showtab(type) {
    if (type == 'one') {
      this.firstTabState = this.firstTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
    }
    if (type == 'two') {
      this.secondTabState = this.secondTabState === 'closed' ? 'open' : 'closed';
      this.firstTabState = 'closed'
      this.thirdTabState = 'closed'
      this.fourthTabState = 'closed'
    }
    if (type == 'three') {
      this.thirdTabState = this.thirdTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.firstTabState = 'closed'
      this.fourthTabState = 'closed'
    }
    if (type == 'four') {
      this.fourthTabState = this.fourthTabState === 'closed' ? 'open' : 'closed';
      this.secondTabState = 'closed'
      this.thirdTabState = 'closed'
      this.firstTabState = 'closed'
    }
  }

}
