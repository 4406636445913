<div class="main-wrapper">
    <div class="intro-default">
        <div class="container">
            <div class="section-default">
                <h2>Bestens vorbereitet auf alle Lebensereignisse</h2>
                <p>Hochzeit, Geburt, Selbstständigkeit: Im Leben erwarten Sie immer wieder Ereignisse, die guter
                    Vorbereitung bedürfen. Häufig können Sie jedoch nicht alle Faktoren selbst beeinflussen. Und selbst
                    bei guter Planung kommen einige Ereignisse vollkommen unvorbereitet auf Sie zu. Damit Sie sich auf
                    alle Lebensereignisse einstellen können, bieten wir Ihnen als ganzheitlicher Finanzcoach einen
                    umfassenden Ratgeber an.</p>
            </div>
            <div class="image-wrapper">
                <img src="assets/maska.svg" alt="">
            </div>
        </div>
    </div>
    <div class="container">
        <div class="theme-cards-wrapper">
            <div class="theme-card" [routerLink]="['/lebensereignis/bewerbung']">
                <div class="img-wrapper">
                    <img src="assets/bewerbung-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Bewerbung</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/geburt']">
                <div class="img-wrapper">
                    <img src="assets/geburt-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Geburt</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/ausbildung']">
                <div class="img-wrapper">
                    <img src="assets/ausbildung-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Ausbildung</h2>
                </div>
            </div>

            <div class="theme-card" [routerLink]="['/lebensereignis/reise']">
                <div class="img-wrapper">
                    <img src="assets/reise-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Reise</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/umzug']">
                <div class="img-wrapper">
                    <img src="assets/umzug-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Umzug</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/autokauf']">
                <div class="img-wrapper">
                    <img src="assets/autokauf-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Autokauf</h2>
                </div>
            </div>

            <div class="theme-card" [routerLink]="['/lebensereignis/clever-sparen']">
                <div class="img-wrapper">
                    <img src="assets/cleversparen-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Clever Sparen</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/konkubinat']">
                <div class="img-wrapper">
                    <img src="assets/konkubinat-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Konkubinat</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/hochzeit']">
                <div class="img-wrapper">
                    <img src="assets/hochzeit-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Hochzeit</h2>
                </div>
            </div>

            <div class="theme-card" [routerLink]="['/lebensereignis/eigenheim']">
                <div class="img-wrapper">
                    <img src="assets/eigenheim-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Eigenheim</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/selbststandigkeit']">
                <div class="img-wrapper">
                    <img src="assets/selbst-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Selbstständigkeit</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/scheidung']">
                <div class="img-wrapper">
                    <img src="assets/scheidung-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Scheidung</h2>
                </div>
            </div>

            <div class="theme-card" [routerLink]="['/lebensereignis/pensionierung']">
                <div class="img-wrapper">
                    <img src="assets/pension-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Pensionierung</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/nachlassplanung']">
                <div class="img-wrapper">
                    <img src="assets/nachlassplanung-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Nachlassplanung</h2>
                </div>
            </div>
            <div class="theme-card" [routerLink]="['/lebensereignis/todesfall']">
                <div class="img-wrapper">
                    <img src="assets/todesfall-thumb.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>Todesfall</h2>
                </div>
            </div>
        </div>
    </div>
</div>