<div class="main-wrapper">

    <div class="intro-wrapper">
        <div class="container">
            <div class="section-one">
                <h2>SSM Partner<br>Your Value in Life</h2>
                <p> Das ganzheitliche Finanzcoaching der SSM Partner AG basiert stets auf Ihren ganz persönlichen
                    Zielen, Wünschen und Bedürfnissen. Statt vorgefertigter Pakete erhalten Sie optimal auf Ihre
                    Lebensphase abgestimmte Lösungen und Strategien. Tritt einmal ein unvorhergesehenes Lebensereignis
                    ein, stehen wir als starker Partner an Ihrer Seite.
                </p>
                <div class="separate"></div>
                <button class="classic-button" [routerLink]="['/kontakt']">KONTAKTIEREN SIE UNS</button>
            </div>
            <div class="image-wrapper">
                <img src="assets/mask-home.svg" alt="">
            </div>
        </div>
    </div>

    <div class="container">
        <div class="cards-wrapper" (window:resize)="onResize($event)">
            <div class="card card-default" *ngIf="!mobile">
                <div class="icon-wrapper">
                    <img src="assets/goal.svg" alt="">
                </div>
                <h3>Lebensereignis</h3>
                <p>
                    Hochzeit, Geburt, Selbstständigkeit: Im Leben erwarten Sie immer wieder Ereignisse, die guter
                    Vorbereitung bedürfen. Häufig können Sie jedoch nicht alle Faktoren selbst beeinflussen. Und selbst
                    bei guter Planung kommen einige vollkommen unvorbereitet auf Sie zu. Damit Sie sich auf
                    alle Lebensereignisse einstellen können, bieten wir Ihnen als ganzheitlicher Finanzcoach einen
                    umfassenden Ratgeber an.
                </p>
                <div class="separate"></div>
                <h5 [routerLink]="['/lebensereignis']">Mehr erfahren</h5>
                <img class="arrow" src="assets/right-arrow.svg" alt="" [routerLink]="['/lebensereignis']">
            </div>
            <div class="card card-default" *ngIf="!mobile">
                <div class="icon-wrapper">
                    <img src="assets/theater.svg" alt="">
                </div>
                <h3>Lebensphase</h3>
                <p>
                    Jede Lebensphase bringt Veränderungen mit sich. Mit dem Übergang in einen neuen
                    Lebensabschnitt ändern sich auch Ihre Bedürfnisse. Wir als Unternehmen und ganzheitlicher
                    Finanzcoach freuen uns, Sie in jeder Lebensphase begleiten zu dürfen. Gemeinsam mit Ihnen arbeiten
                    wir Ihre Wünsche und Ziele heraus und finden Dienstleister und Angebote, die zu Ihnen passen.
                </p>
                <div class="separate"></div>
                <h5 [routerLink]="['/lebensphase']">Mehr erfahren</h5>
                <img class="arrow" src="assets/right-arrow.svg" alt="" [routerLink]="['/lebensphase']">
            </div>
            <div class="card card-default" *ngIf="!mobile">
                <div class="icon-wrapper">
                    <img src="assets/museum.svg" alt="">
                </div>
                <h3>Haus der Finanzen</h3>
                <p>
                    Eine langfristig stabile Finanzplanung benötigt ein solides Fundament. Den Bereich der
                    Finanzen können Sie sich dabei ähnlich einem Haus vorstellen. Das Bild vom Haus der Finanzen
                    verdeutlicht, aus welchen Bausteinen sich die ganzheitliche Finanzplanung zusammenfügt. Das fängt
                    beim Sparen an und geht bis zum Vermögensaufbau.
                </p>
                <div class="separate"></div>
                <h5 [routerLink]="['/finanzen']">Mehr erfahren</h5>
                <img class="arrow" src="assets/right-arrow.svg" alt="" [routerLink]="['/finanzen']">
            </div>


            <swiper *ngIf="mobile" class="swiper-container" fxFlex="auto" [config]="config" [disabled]="disabled">
                <div class="card card-default swiper-slide">
                    <div class="icon-wrapper">
                        <img src="assets/goal.svg" alt="">
                    </div>
                    <h3>Lebensereignis</h3>
                    <p>
                        Hochzeit, Geburt, Selbstständigkeit: Im Leben erwarten Sie immer wieder Ereignisse, die guter
                        Vorbereitung bedürfen. Häufig können Sie jedoch nicht alle Faktoren selbst beeinflussen. Und
                        selbst
                        bei guter Planung kommen einige Ereignisse vollkommen unvorbereitet auf Sie zu. Damit Sie sich
                        auf
                        alle Lebensereignisse einstellen können, bieten wir Ihnen als ganzheitlicher Finanzcoach einen
                        umfassenden Ratgeber an.
                    </p>
                    <div class="separate"></div>
                    <h5 [routerLink]="['/lebensereignis']">Mehr erfahren</h5>
                    <img class="arrow" src="assets/right-arrow.svg" alt="" [routerLink]="['/lebensereignis']">
                </div>
                <div class="card card-default swiper-slide">
                    <div class="icon-wrapper">
                        <img src="assets/theater.svg" alt="">
                    </div>
                    <h3>Lebensphase</h3>
                    <p>
                        Jede Lebensphase bringt Veränderungen mit sich. Mit dem Übergang in einen neuen
                        Lebensabschnitt ändern sich auch Ihre Bedürfnisse. Wir als Unternehmen und ganzheitlicher
                        Finanzcoach freuen uns, Sie in jeder Lebensphase begleiten zu dürfen. Gemeinsam mit Ihnen
                        arbeiten
                        wir Ihre Wünsche und Ziele heraus und finden Dienstleister und Angebote, die zu Ihnen passen.
                    </p>
                    <div class="separate"></div>
                    <h5 [routerLink]="['/lebensphase']">Mehr erfahren</h5>
                    <img class="arrow" src="assets/right-arrow.svg" alt="" [routerLink]="['/lebensphase']">
                </div>
                <div class="card card-default swiper-slide">
                    <div class="icon-wrapper">
                        <img src="assets/museum.svg" alt="">
                    </div>
                    <h3>Haus der Finanzen</h3>
                    <p>
                        Eine langfristig stabile Finanzplanung benötigt ein solides Fundament. Den Bereich der
                        Finanzen können Sie sich dabei ähnlich einem Haus vorstellen. Das Bild vom Haus der Finanzen
                        verdeutlicht, aus welchen Bausteinen sich die ganzheitliche Finanzplanung zusammenfügt. Das
                        fängt
                        beim Sparen an und geht bis zum Vermögensaufbau.
                    </p>
                    <div class="separate"></div>
                    <h5 [routerLink]="['/finanzen']">Mehr erfahren</h5>
                    <img class="arrow" src="assets/right-arrow.svg" alt="" [routerLink]="['/finanzen']">
                </div>
            </swiper>
        </div>

        <div class="text-default text-custom p" style="width:100%;">
            <h2 style="text-align: center;">SSM Partner AG - Ganzheitliches Finanzcoaching</h2>
            <p> Welche Versicherungen benötigen Sie? Wie sorgen Sie sinnvoll fürs Alter vor? Wie können Sie Geld
                anlegen,
                um sich grosse und kleinere Wünsche zu erfüllen? Wer vor diesen Fragen steht, stellt schnell fest: Die
                Finanzbranche ist äusserst komplex. Der Finanzmarkt bietet eine Vielfalt von Produkten. Gerade diese
                Vielfalt verunsichert viele Verbraucher.<br><br> Als Laie lässt es sich kaum herausfinden, welches
                Produkt
                tatsächlich am besten zu den eigenen Bedürfnissen passt. Diese Unsicherheit führt wiederum zu
                Desinteresse. Der Markt scheint unüberblickbar, daher beschäftigt man sich nicht weiter mit den eigenen
                Finanzen. Das bedeutet allerdings den Verzicht auf viele Chancen! Wir von der SSM Partner AG sehen es
                daher als unsere Mission an, Transparenz in die Finanzwelt zu bringen.<br><br>Unser Ansatz ist das
                ganzheitliche Finanzcoaching. Das Ziel: Unseren Kunden die Finanzwelt einfach und mit technologisch
                hochwertigen Systemen zu erklären.</p>
            <h2 style="text-align: center;margin-top:35px;"> Unsere Leistungen</h2>
            <p>Wir möchten Ihnen nicht einfach nur Produkte verkaufen. Vielmehr möchten wir Versicherungs-, Vorsorge-
                und Finanzierungslösungen finden, die wirklich zu Ihren Zielen passen. Unsere Berater arbeiten daher
                produktunabhängig. So können sie aus der Vielzahl aller Angebote ein massgeschneidertes Paket für jeden
                Kunden zusammenstellen.</p>
        </div>
        <h2 class="page-title ">Unsere Leistungen im Überblick</h2>
        <div class="tabordion">
            <section id="section1" #sectionOne>
                <label id="option1" (click)="showtab('one')"><span [ngClass]="{rotate : firstTabState=='open'}">+</span>
                    <p>Vorsorge und Versicherung für Privatkunden </p>
                </label>
                <div class="article-wrapper" [@openClose]=firstTabState>
                    <article>
                        <p>Wir beraten Sie auf Grundlage Ihrer Wünsche und Ziele und stellen Ihnen ein optimales
                            Versicherungspaket der besten Dienstleister zusammen.
                        </p>
                    </article>
                </div>
            </section>
            <section id="section2" #sectionTwo>
                <label id="option2" (click)="showtab('two')"><span
                        [ngClass]="{rotate : secondTabState=='open'}">+</span>
                    <p>Vorsorge und Versicherung für Unternehmen</p>
                </label>
                <div class="article-wrapper" [@openClose]=secondTabState>
                    <article>
                        <p>Für unsere Geschäftskunden kombinieren wir die besten Vorsorge- und Versicherungslösungen für
                            einen sicheren und reibungslosen Geschäftsprozess.</p>
                    </article>
                </div>
            </section>
            <section id="section3" #sectionThree>
                <label id="option3" (click)="showtab('three')"><span
                        [ngClass]="{rotate : thirdTabState=='open'}">+</span>
                    <p>Finanzierungsmodelle und Investments</p>
                </label>
                <div class="article-wrapper" [@openClose]=thirdTabState>
                    <article>
                        <p>Gemeinsam mit Ihnen planen wir Ihre Finanzierungsoptionen, damit Sie sich alle Wünsche
                            erfüllen können, vom Eigenheim bis zur Unternehmensexpansion.</p>
                    </article>
                </div>
            </section>
        </div>

        <div class="arrow-wrapper">
            <div class="single-arrow-wrapper" (click)="makeActive('one')" *ngIf="!firstActive">
                <img class="number" src="assets/1.svg" alt="">
                <img class="over-icon" src="assets/consulting-gray.svg" alt="">
            </div>

            <div class="single-arrow-wrapper active" *ngIf="firstActive">
                <img class="number" src="assets/1-active.svg" alt="">
                <img class="over-icon" src="assets/consulting.svg" alt="">
            </div>

            <div class="single-arrow-wrapper" (click)="makeActive('two')" *ngIf="!secondActive">
                <img class="number" src="assets/2.svg" alt="">
                <img class="over-icon" src="assets/analysis-gray.svg" alt="">
            </div>

            <div class="single-arrow-wrapper active" *ngIf="secondActive">
                <img class="number" src="assets/2-active.svg" alt="">
                <img class="over-icon" src="assets/analysis-white.svg" alt="">
            </div>

            <div class="single-arrow-wrapper" (click)="makeActive('three')" *ngIf="!thirdActive">
                <img class="number" src="assets/3.svg" alt="">
                <img class="over-icon" src="assets/strategy-gray.svg" alt="">
            </div>

            <div class="single-arrow-wrapper active" *ngIf="thirdActive">
                <img class="number" src="assets/3-active.svg" alt="">
                <img class="over-icon" src="assets/strategy-white.svg" alt="">
            </div>

            <div class="single-arrow-wrapper" (click)="makeActive('four')" *ngIf="!fourthActive">
                <img class="number" src="assets/4.svg" alt="">
                <img class="over-icon" src="assets/contract-gray.svg" alt="">
            </div>

            <div class="single-arrow-wrapper active" *ngIf="fourthActive">
                <img class="number" src="assets/4-active.svg" alt="">
                <img class="over-icon" src="assets/contract-white.svg" alt="">
            </div>
        </div>
        <div class="text-default text-custom" *ngIf="firstActive">
            <h2>Beratung</h2>
            <p>Gemeinsam mit Ihnen erörtern wir Ihrer berufliche und private Situation evaluieren Ihre Ziele</p>
        </div>

        <div class="text-default text-custom" *ngIf="secondActive">
            <h2>Analyse</h2>
            <p>Wir vergleichen nach Ihren Möglichkeiten und Zielsetzung die aktuellen Bedingungen und Preise der
                verschiedenen Produkte und periorisieren diese gemäss Ihren Zielsetzungen. </p>
        </div>

        <div class="text-default text-custom" *ngIf="thirdActive">
            <h2>Strategie</h2>
            <p>Wir unterbreiten Ihnen unabhängig und neutral mögliche Lösungen und passen diese an Ihre Bedürfnisse an.
            </p>
        </div>

        <div class="text-default text-custom" *ngIf="fourthActive">
            <h2>Angebote</h2>
            <p>Sie erhalten von uns kostenfreie Angebote zu allen Ihren Versicherungs- und Vorsorgefragen.</p>
        </div>


        <div class="section-cards-wrapper">
            <h2>Warum SSM Partner?</h2>
            <div class="row" *ngIf="!mobile">
                <div class="section-card card-default">
                    <div class="card-icon-wrapper">
                        <img src="assets/like.svg" alt="">
                    </div>
                    <p>Unkompliziert zum passenden Versicherungs- oder Vorsorgeportfolio</p>
                </div>
                <div class="section-card card-default">
                    <div class="card-icon-wrapper">
                        <img src="assets/transparency.svg" alt="">
                    </div>
                    <p>Breitabgestützte Vergleichsmöglichkeiten und Transparenz</p>
                </div>
                <div class="section-card card-default">
                    <div class="card-icon-wrapper">
                        <img src="assets/shield.svg" alt="">
                    </div>
                    <p>Ausgezeichnete Netzwerkverbindungen für Ihre Sicherheit</p>
                </div>
            </div>
            <div class="row" *ngIf="!mobile">
                <div class="section-card card-default">
                    <div class="card-icon-wrapper">
                        <img src="assets/consultation.svg" alt="">
                    </div>
                    <p class="margin">Kompetente Beratung und Informationen</p>
                </div>
                <div class="section-card card-default">
                    <div class="card-icon-wrapper">
                        <img src="assets/save-money.svg" alt="">
                    </div>
                    <p class="margin">Interessante und realistische Finanzierungsmodelle</p>
                </div>
                <div class="section-card card-default">
                    <div class="card-icon-wrapper">
                        <img src="assets/analysis.svg" alt="">
                    </div>
                    <p class="margin">Zielgerichtete Strategien für Sicherheit und Investitionen</p>
                </div>
            </div>
            <div class="row" *ngIf="!mobile">
                <div class="section-card card-default">
                    <div class="card-icon-wrapper">
                        <img src="assets/skills.svg" alt="">
                    </div>
                    <p>Flexibles und spezialisiertes Beraterteam für den besonderen Fachanspruch</p>
                </div>
                <div class="section-card card-default">
                    <div class="card-icon-wrapper">
                        <img src="assets/clock.svg" alt="">
                    </div>
                    <p class="margin-m">Schnelle Reaktionszeiten</p>
                </div>
                <div class="section-card card-default">
                    <div class="card-icon-wrapper">
                        <img src="assets/hurricane.svg" alt="">
                    </div>
                    <p class="margin">Unkomplizierte Unterstützung im Schadenfall</p>
                </div>
            </div>

            <swiper *ngIf="mobile" class="swiper-container" fxFlex="auto" [config]="config" [disabled]="disabled">

                <div class="row swiper-slide">
                    <div class="section-card card-default">
                        <div class="card-icon-wrapper">
                            <img src="assets/like.svg" alt="">
                        </div>
                        <p>Unkompliziert zum passenden Versicherungs- oder Vorsorgeportfolio</p>
                    </div>
                    <div class="section-card card-default">
                        <div class="card-icon-wrapper">
                            <img src="assets/transparency.svg" alt="">
                        </div>
                        <p>Breitabgestützte Vergleichsmöglichkeiten und Transparenz</p>
                    </div>
                    <div class="section-card card-default">
                        <div class="card-icon-wrapper">
                            <img src="assets/shield.svg" alt="">
                        </div>
                        <p>Ausgezeichnete Netzwerkverbindungen für Ihre Sicherheit</p>
                    </div>
                </div>
                <div class="row swiper-slide">
                    <div class="section-card card-default">
                        <div class="card-icon-wrapper">
                            <img src="assets/consultation.svg" alt="">
                        </div>
                        <p class="margin">Kompetente Beratung und Informationen</p>
                    </div>
                    <div class="section-card card-default">
                        <div class="card-icon-wrapper">
                            <img src="assets/save-money.svg" alt="">
                        </div>
                        <p class="margin">Interessante und realistische Finanzierungsmodelle</p>
                    </div>
                    <div class="section-card card-default">
                        <div class="card-icon-wrapper">
                            <img src="assets/analysis.svg" alt="">
                        </div>
                        <p class="margin">Zielgerichtete Strategien für Sicherheit und Investitionen</p>
                    </div>
                </div>
                <div class="row swiper-slide">
                    <div class="section-card card-default">
                        <div class="card-icon-wrapper">
                            <img src="assets/skills.svg" alt="">
                        </div>
                        <p>Flexibles und spezialisiertes Beraterteam für den besonderen Fachanspruch</p>
                    </div>
                    <div class="section-card card-default">
                        <div class="card-icon-wrapper">
                            <img src="assets/clock.svg" alt="">
                        </div>
                        <p class="margin-m">Schnelle Reaktionszeiten</p>
                    </div>
                    <div class="section-card card-default">
                        <div class="card-icon-wrapper">
                            <img src="assets/hurricane.svg" alt="">
                        </div>
                        <p class="margin">Unkomplizierte Unterstützung im Schadenfall</p>
                    </div>
                </div>
            </swiper>
        </div>
        <div class="contact-wrapper">
            <h2>Haben Sie eine Frage?</h2>
            <p>Wir freuen uns auf Ihren Kontakt</p>
            <div class="contact-icon-wrapper">
                <img *ngIf="phoneSection" (click)="chooseSection('phone')" src="assets/phone-active.svg" alt="">
                <img *ngIf="!phoneSection" (click)="chooseSection('phone')" src="assets/phone-gray.svg" alt="">
                <img *ngIf="contactSection" (click)="chooseSection('contact')" src="assets/mail-active.svg" alt="">
                <img *ngIf="!contactSection" (click)="chooseSection('contact')" src="assets/email-gray.svg" alt="">
                <!-- <img (click)="chooseSection('chat')" src="assets/chat-gray.svg" alt=""> -->
            </div>
            <form class="form-default-wrapper" *ngIf="contactSection&&!formSuccess" action="" [formGroup]="contactForm">
                <div class="input-wrapper">
                    <div class="field">
                        <input formControlName="name" type="text" placeholder="Name"
                            [ngClass]="{'invalid': (f.name.invalid && f.name.touched) || (f.name.invalid && formTrue)}">
                        <div class="error-msg"
                            *ngIf="(f.name.invalid && f.name.touched) || (f.name.invalid && formTrue)">
                            <span *ngIf="f.name.errors.required">Bitte Name angeben</span>
                        </div>
                    </div>
                    <div class="field">
                        <input formControlName="telefon" type="text" placeholder="Telefon"
                            [ngClass]="{'invalid': (f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && formTrue)}">
                        <div class="error-msg"
                            *ngIf="(f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && formTrue)">
                            <span *ngIf="f.telefon.errors.required">Bitte Telefon angeben</span>
                        </div>
                    </div>
                </div>
                <div class="input-wrapper area">
                    <textarea formControlName="message" cdkAutosizeMinRows="110" placeholder="Nachricht"
                        cdkAutosizeMaxRows="120"
                        [ngClass]="{'invalid': (f.message.invalid && f.message.touched) || (f.message.invalid && formTrue)}"></textarea>
                    <div class="error-msg"
                        *ngIf="(f.message.invalid && f.message.touched) || (f.message.invalid && formTrue)">
                        <span *ngIf="f.message.errors.required">Bitte Nachricht eingeben</span>
                    </div>
                </div>
                <button class="classic-button" (click)="sendContact()">SENDEN</button>
            </form>
            <div class="success-mess" *ngIf="formSuccess&&contactSection">
                Nachricht wurde erfolgreich übermittelt.
            </div>
            <div class="phone-wrapper" *ngIf="phoneSection">
                <div class="phone-card card-default">
                    <p><a href="{{'tel:0412202050'}}">+41 41 220 20 50</a></p>
                </div>
                <div class="section">
                    <p>Montag bis Freitag</p>
                    <p>08:00 - 12:00 & 13:00 - 17:00</p>
                </div>
            </div>
            <div class="cleaner"></div>
        </div>
    </div>
</div>