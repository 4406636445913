<div class="footer" *ngIf="!landingPage">
    <div class="container">
        <div class="footer-wrapper">
            <div class="footer-menu">
                <div class="left-side">
                    <h2>Your Value in <span>Life</span></h2>
                    <div class="p-wrapper">
                        <p class="green">Für Sie erreichbar</p>
                        <p class="bold">Montag bis Freitag: 08:00 Uhr bis 12:00 Uhr und 13:00 Uhr bis 17:00 Uhr.</p>
                        <p>Für alle anderen Uhrzeiten und Wochentage kontaktieren Sie uns bitte per E-Mail oder über das Kontaktormular.</p>
                        <button class="kontakt" [routerLink]="['/kontakt']">Kontakt aufnehmen</button>
                    </div>
                    <div class="p-wrapper">
                        <p class="green">Folge uns auf unseren Sozialen Medien</p>
                        
                        <div class="social-links">
                            <span><img src="assets/linkedin-green.svg" alt=""></span>
                            <span><img src="assets/youtube-green.svg" alt=""></span>
                            <span><img src="assets/instagram-green.svg" alt=""></span>
                            <span><img src="assets/tiktok-gren.svg" alt=""></span>
                        </div>
                    </div>
                    <!-- <div class="p-wrapper">
                        <img src="assets/call-answer.svg" alt="">
                        <p>Telefon: <a href="{{'tel:0412202050'}}">+41 41 220 20 50</a></p>
                    </div>
                    <div class="p-wrapper">
                        <img src="assets/mail-black-envelope-symbol.svg" alt="">
                        <p><a href="mailto:info@ssmpartner.ch">info@ssmpartner.ch</a></p>
                    </div>
                    <h5>Für Sie erreichbar</h5>
                    <p>Montag bis Freitag: 08:00 Uhr bis 12:00 Uhr und 13:00 Uhr bis 17:00 Uhr. Alle übrigen Uhrzeiten
                        und Wochentage per Email oder
                        über das Kontaktformular unseren Webseite.
                    </p>
                    <div class="footer-logo-wrapper">
                        <img src="assets/cicero.png" alt="">
                    </div> -->
                </div>
                <div class="right-side">
                    <div class="contact-wrapper">
                        <div class="logo">
                            <img src="assets/white-logo.svg" alt="">
                        </div>
                        <div class="cicero">
                            <img src="assets/cicero.png" alt="">
                        </div>
                        <!-- <h2>Kontaktieren Sie Uns</h2> -->
                        <!-- <form class="form-default-wrapper" [formGroup]="footerForm" *ngIf="!formSuccess" action="">
                            <div class="input-wrapper">
                                <div class="field">
                                    <input formControlName="name" type="text" placeholder="Name" [ngClass]="{'invalid': (f.name.invalid && f.name.touched) || (f.name.invalid && formTrue)}">
                                    <div class="error-msg"
                                        *ngIf="(f.name.invalid && f.name.touched) || (f.name.invalid && formTrue)">
                                        <span *ngIf="f.name.errors.required">Bitte Name angeben</span>
                                    </div>
                                </div>
                                <div class="field">
                                    <input formControlName="telefon" type="text" placeholder="Telefon" [ngClass]="{'invalid': (f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && formTrue)}">
                                    <div class="error-msg"
                                        *ngIf="(f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && formTrue)">
                                        <span *ngIf="f.telefon.errors.required">Bitte Telefon angeben</span>
                                    </div>
                                </div>
                            </div>
                            <div class="input-wrapper area">
                                <textarea formControlName="message" cdkAutosizeMinRows="110" cdkAutosizeMaxRows="120"
                                    placeholder="Nachricht" [ngClass]="{'invalid': (f.message.invalid && f.message.touched) || (f.message.invalid && formTrue)}"></textarea>
                                <div class="error-msg"
                                    *ngIf="(f.message.invalid && f.message.touched) || (f.message.invalid && formTrue)">
                                    <span *ngIf="f.message.errors.required">Bitte Nachricht eingeben</span>
                                </div>
                            </div>
                            <button class="classic-button" (click)="send()">SENDEN</button>
                        </form> -->
                        <div class="cleaner"></div>
                    </div>
                    <!-- <div class="success-mess" *ngIf="formSuccess">
                        Nachricht wurde erfolgreich übermittelt.
                    </div> -->
                </div>
            </div>
            <div class="down-footer-wrapper">
                <ul>
                    <li><a [routerLink]="['/impressum']" routerLinkActive="active-link">Impressum</a></li>
                    <li><a [routerLink]="['/impressum']" routerLinkActive="active-link">Datenschutz</a></li>
                </ul>
                <div class="copyright">
                    Copyright © 2024 SSM Partner AG
                </div>
            </div>
        </div>
    </div>

</div>