<div class="main-wrapper">
    <div class="intro-wrapper" (window:resize)="onResize($event)">
        <div class="img" [ngStyle]="{'background-image': image, 'background-position': imagePosition}" *ngIf="desktop">
        </div>
        <div class="img" [ngStyle]="{'background-image': mobileImage, 'background-position': imagePosition}" *ngIf="mobile">
        </div>
    </div>
    <div class="container">
        <div class="buttons-wrapper-new">
            <div class="left-button" [routerLink]="['/uber-uns']">
                <span>Hauptsitz</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='bern'}" [routerLink]="['/agenture/bern']">
                <span>Bern</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='buchs'}" [routerLink]="['/agenture/buchs']">
                <span>Buchs</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='lausanne'}" [routerLink]="['/agenture/lausanne']">
                <span>Lausanne</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='luzern'}" [routerLink]="['/agenture/luzern']">
                <span>Luzern</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='olten'}" [routerLink]="['/agenture/olten']">
                <span>Olten</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='pfungen'}" [routerLink]="['/agenture/pfungen']">
                <span>Pfungen</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='st-gallen'}" [routerLink]="['/agenture/st-gallen']">
                <span>St.Gallen</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='ticino'}" [routerLink]="['/agenture/ticino']">
                <span>Ticino</span>
            </div>
            <!-- <div class="right-button" [ngClass]="{'active': selectedName=='zofingen'}" [routerLink]="['/agenture/zofingen']">
                <span>Zofingen</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='zug'}" [routerLink]="['/agenture/zug']">
                <span>Zug</span>
            </div> -->
            <div class="right-button" [ngClass]="{'active': selectedName=='zurich-nord'}" [routerLink]="['/agenture/zurich-nord']">
                <span>Zürich Nord</span>
            </div>
            <div class="right-button" [ngClass]="{'active': selectedName=='zurich-sud'}" [routerLink]="['/agenture/zurich-sud']">
                <span>Zürich Süd</span>
            </div>
            <div style="visibility: hidden;"></div>
        </div>
        
        <div class="head-office">
            <h4>Agenturleiter</h4>
            <div class="address">
                <div class="location-wrapper">
                    <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [mapTypeControl]='false'
                        [zoomControl]="true" [styles]="style" [scrollwheel]="false" [streetViewControl]="false">
                
                        <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker(m.label, i)"
                            [latitude]="m.lat" [longitude]="m.lng" [label]="m.label" [markerDraggable]="m.draggable"
                            (dragEnd)="markerDragEnd(m, $event)">
                
                            <!-- <agm-info-window>
                          <strong>InfoWindow content</strong>
                        </agm-info-window> -->
                
                        </agm-marker>
                
                        <!-- <agm-circle [latitude]="lat + 0.3" [longitude]="lng" 
                          [radius]="5000"
                          [fillColor]="'red'"
                          [circleDraggable]="true"
                          [editable]="true">
                      </agm-circle> -->
                
                    </agm-map>
                </div>
                <div class="address-left" *ngIf="selectedName == 'main'">
                    <h3 class="main">SSM Partner AG</h3>
                    <h3>Stationsstrasse 92</h3>
                    <h3>6023 Rothenburg</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:0412202050'}}">+41 41 220 20 50</a></h3>
                    <h3>Email: <a href="mailto:info@ssmpartner.ch">info@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'luzern'">
                    <h3 class="main">Agentur Luzern</h3>
                    <h3>Stationsstrasse 90</h3>
                    <h3>6023 Rothenburg</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:0412202050'}}">+41 41 220 20 50</a></h3>
                    <h3>Email: <a href="mailto:info@ssmpartner.ch">info@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'olten'">
                    <h3 class="main">Agentur Olten</h3>
                    <h3>Solothurnerstrasse 257</h3>
                    <h3>4600 Olten</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:0412202050'}}">+41 41 220 20 50</a></h3>
                    <h3>Email: <a href="mailto:info@ssmpartner.ch">olten@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'st-gallen'">
                    <h3 class="main">Agentur St.Gallen</h3>
                    <h3>Im Stadtwald 5</h3>
                    <h3>9400 Rorschach</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:0718400224'}}">+41 71 840 02 24</a></h3>
                    <h3>Tel. <a href="{{'tel:0718400204'}}">+41 71 840 02 04</a></h3>
                    <h3>Email: <a href="mailto:stgallen@ssmpartner.ch">stgallen@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'zofingen'">
                    <h3 class="main">Agentur Zofingen</h3>
                    <h3>Untere Brühlstrasse 13</h3>
                    <h3>4800 Zofingen</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:0628344010'}}">+41 62 834 40 10</a></h3>
                    <h3>Email: <a href="mailto:zofingen@ssmpartner.ch">zofingen@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'zurich-sud'">
                    <h3 class="main">Agentur Zürich Süd</h3>
                    <h3>Soodstrasse 52</h3>
                    <h3>8134 Adliswil</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:044440008'}}">+41 44 440 00 08</a></h3>
                    <h3>Email: <a href="mailto:admin.zh-sud@ssmpartner.ch">admin.zh-sud@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'zurich-nord'">
                    <h3 class="main">Agentur Zürich Nord</h3>
                    <h3>Bodensäckerstrasse 3</h3>
                    <h3>8975 Spreitenbach</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:0415002849'}}">+41 43 541 37 90</a></h3>
                    <h3>Email: <a href="mailto:zh-nord@ssmpartner.ch">zh-nord@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'zug'">
                    <h3 class="main">Agentur Zug</h3>
                    <h3>Sennweidstrasse 45</h3>
                    <h3>6312 Steinhausen</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:0417811174'}}">+41 41 781 11 74</a></h3>
                    <h3>Email: <a href="mailto:zug@ssmpartner.ch">zug@ssmpartner.ch</a></h3>
                    <h3 class="margin">Verkaufsstelle Schaffhausen</h3>
                    <h3>Kronnengässchen 3</h3>
                    <h3>8200 Schaffhausen</h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'pfungen'">
                    <h3 class="main">Agentur Pfungen</h3>
                    <h3>Wanistrasse 7</h3>
                    <h3>8422 Pfungen</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:041523156161'}}">+41 52 315 61 61</a></h3>
                    <h3>Email: <a href="mailto:pfungen@ssmpartner.ch">pfungen@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'bern'">
                    <h3 class="main">Agentur Bern</h3>
                    <h3>Bahnhofstrasse 5</h3>
                    <h3>3322 Urtenen-Schönbühl</h3>
                    <h3>Email: <a href="mailto:bern@ssmpartner.ch">bern@ssmpartner.ch</a></h3>
                </div> 
                <div class="address-left" *ngIf="selectedName == 'lausanne'">
                    <h3 class="main">Agentur Lausanne</h3>
                    <h3>Chemin des Lentillières 15</h3>
                    <h3>1023 Crissier</h3>
                    <h3>Email: <a href="mailto:backoffice.fr@ssmpartner.ch">backoffice.fr@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'buchs'">
                    <h3 class="main">Agentur Buchs</h3>
                    <h3>Dällikerstrasse 30</h3>
                    <h3>8107 Buchs ZH</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:041439310438'}}">+41 43 931 04 38</a></h3>
                    <h3>Email: <a href="mailto:regensdorf@ssmpartner.ch">regensdorf@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left" *ngIf="selectedName == 'ticino'">
                    <h3 class="main">Agentur Ticino</h3>
                    <h3>Via Silvio Calloni 1A</h3>
                    <h3>6900 Lugano</h3>
                    <h3 class="margin">Tel. <a href="{{'tel:041919933507'}}">+41 91 993 35 07</a></h3>
                    <h3>Email: <a href="mailto:tessin@ssmpartner.ch">tessin@ssmpartner.ch</a></h3>
                </div>
            </div>
            <ng-container *ngIf="selectedName == 'pfungen'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/semih3.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:semih.karakas@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Semih Karakas</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'luzern'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/florent.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:sb@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Florent Tahiraj</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'buchs'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/guxim2.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:guxim.ramizi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Guxim Ramizi</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'bern'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/agron2.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:agron.selmanaj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Agron Selmanaj</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'lausanne'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/mariana.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:marianna.todorovic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Marianna Todorovic</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'olten'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/bello2.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:emanuele.bello@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Emanuele Bello</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'st-gallen'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/miki3.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:miroslav.stevic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Miroslav Stevic</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'ticino'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/centrella.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:gerardo.centrella@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Gerardo Centrella</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'zofingen'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/sandro3.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:sandro.trachsel@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Sandro Trachsel</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                    <div class="card"></div>
                    <div class="card"></div>
                    <div class="card"></div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'zurich-sud'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/luci.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:gutierrez@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Luciano Gutiérrez</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                    <div class="card"></div>
                    <div class="card"></div>
                    <div class="card"></div>
                    <div class="card"></div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'zurich-nord'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/usa.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:besnik.uka@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Besnik Uka</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedName == 'zug'">
                <div class="row-card">
                    <div class="card">
                        <div class="img-wrapper">
                            <img src="assets/kamil3.png" alt="" class="mainimg">
                            <div class="mask">
                                <p><a href="mailto:kamil.malinowski@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                            </div>
                        </div>
                        <div class="infos">
                            <h5>Kamil Malinowski</h5>
                            <p>AGENTURLEITER</p>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>



        <div class="tabordion" *ngIf="selectedName == 'pfungen'">
            
            <section id="section111" #sectionElevenOne>
                <label id="option111" (click)="showtab('elevenOneTabState')"><span
                        [ngClass]="{rotate : states.elevenOneTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/erdal3.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:erdal.karakas@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Erdal Karakas</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section113" #sectionElevenThree>
                <label id="option113" (click)="showtab('elevenThreeTabState')"><span
                        [ngClass]="{rotate : states.elevenThreeTabState=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/scandurro3.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:massimo.scandurro@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Massimo Scandurro</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section114" #sectionElevenFour>
                <label id="option114" (click)="showtab('elevenFourTabState')"><span
                        [ngClass]="{rotate : states.elevenFourTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenFourTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/busla.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:büsra.saygili@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Büsra Saygili</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>


        <div class="tabordion" *ngIf="selectedName == 'buchs'">
            
            <section id="section1110" #sectioNewTwoNew>
                <label id="option111" (click)="showtab('sectioNewTwoNew')"><span
                        [ngClass]="{rotate : states.sectioNewTwoNew=='open'}">+</span>
                    <p>Verkaufsleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectioNewTwoNew>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/lushe.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:lushe.murati@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Lushe Hajrie Murati</h2>
                                    <h5>VERKAUFSLEITERIN</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section190" #sectionNineOneZeroNew>
                <label id="option16" (click)="showtab('sectionNineOneZeroNew')"><span
                        [ngClass]="{rotate : states.sectionNineOneZeroNew=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectionNineOneZeroNew>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/adisa2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:adisa.musa@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Adisa Musa</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/efe.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:adelis.efe@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Adelis Efe</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/almedina.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:almedina.bajrami@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Almedina Bajrami</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/belal.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:belal.ahmed@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Belal Ahmed</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/zekiri.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:eshtref.zekiri@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Eshtref Zekiri</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/flamur2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:flamur.murati@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Flamur Murati</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/limani.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:kjazim.limani@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Kjazim Limani</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/marigona.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:marigona.murati@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Marigona Murati</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/shke.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:shkelzen.murati@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Shkelzen Murati</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/zejno2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:zejno.musliu@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Zejno Musliu</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section1142" #sectionElevenFourTwo>
                <label id="option1142" (click)="showtab('elevenFourTwoTabState')"><span
                        [ngClass]="{rotate : states.elevenFourTwoTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenFourTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/demir2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:abdiu.demir@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Abdiu Demir</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div class="tabordion" *ngIf="selectedName == 'luzern'">
            
            <section id="newSection" #sectionNew>
                <label id="optionNew" (click)="showtab('newTabState')"><span
                        [ngClass]="{rotate : states.newTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/betrim2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:bes@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Betrim Shabani</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/tiago2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:tc@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Tiago Costa Albino</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section>

            <section id="section7" #sectionSeven>
                <label id="option7" (click)="showtab('seventhTabState')"><span
                        [ngClass]="{rotate : states.seventhTabState =='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.seventhTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/gashi2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:adrian.gashi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Adrian Gashi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/muff.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:alexander.muff@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Alexander Muff</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/amini.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:as@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Amini Suliman</h2>
                                    <h5>KUNDENBERATER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/duzmani2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:emanuel.duzmani@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Emanuel Duzmani</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/curcio2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:francesco.curcio@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Francesco Curcio</h2>
                                    <h5>KUNDENBERATER</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                            <div class="card"></div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alban.png" alt="">
                                </div>
                                <div class="info-wrapper" #albanTwo (click)="openInfo(albanTwo)">
                                    <h2>Alban Kuci <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:alban.kuci@ssmpartner.ch">alban.kuci@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/amini.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #amini (click)="openInfo(amini)">
                                    <h2>Amini Suliman <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:as@ssmpartner.ch">as@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/arber.png" alt="">
                                </div>
                                <div class="info-wrapper" #arber (click)="openInfo(arber)">
                                    <h2 class="short-h new">Arber Hoxha <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:arh@ssmpartner.ch">arh@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/ardian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ardian (click)="openInfo(ardian)">
                                    <h2 class="short-h new">Ardian Krasniqi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ark@ssmpartner.ch">ark@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/ardian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ardianTwo (click)="openInfo(ardianTwo)">
                                    <h2 class="short-h new">Ardian Krasniqi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ark@ssmpartner.ch">ark@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/baja.png" alt="">
                                </div>
                                <div class="info-wrapper" #baja (click)="openInfo(baja)">
                                    <h2>Baja Krizevac <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:baja.krizevac@ssmpartner.ch">baja.krizevac@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/besir.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #besir (click)="openInfo(besir)">
                                    <h2 class="short-h new">Besir Kastrati <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:besir.kastrati@ssmpartner.ch">besir.kastrati@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/diogo2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #diogo (click)="openInfo(diogo)">
                                    <h2 class="short-h new">Diogo Da Silva Baptista <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:dsb@ssmpartner.ch">dsb@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/duzmani.png" alt="">
                                </div>
                                <div class="info-wrapper" #duzmani (click)="openInfo(duzmani)">
                                    <h2>Emanuel Duzmani<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper short-l-m">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a
                                                    href="mailto:emanuel.duzmani@ssmpartner.ch">emanuel.duzmani@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/francesco.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #francesco (click)="openInfo(francesco)">
                                    <h2>Francesco Curcio<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper short-l-m">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a
                                                    href="mailto:francesco.curcio@ssmpartner.ch">francesco.curcio@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/ibrahim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ibrahim (click)="openInfo(ibrahim)">
                                    <h2>Ibrahim Onac <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:io@ssmpartner.ch">io@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/ibrahim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ibrahimTwoo (click)="openInfo(ibrahimTwoo)">
                                    <h2>Ibrahim Onac <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:io@ssmpartner.ch">io@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/laska.png" alt="">
                                </div>
                                <div class="info-wrapper" #laskaTwo (click)="openInfo(laskaTwo)">
                                    <h2>Laska Robert<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:laska.robert@ssmpartner.ch">laska.robert@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/leotrim2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #leotrimTwo (click)="openInfo(leotrimTwo)">
                                    <h2>Leotrim Selmanaj<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:les@ssmpartner.ch">les@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sara2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #saraTwo (click)="openInfo(saraTwo)">
                                    <h2>Sara Junker <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATERIN</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ose@ssmpartner.ch">sasj@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/silviu.png" alt="">
                                </div>
                                <div class="info-wrapper" #silviu (click)="openInfo(silviu)">
                                    <h2 class="short-h new">Silviu Mihai Toma <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:silviu.toma@ssmpartner.ch">silviu.toma@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sergio (click)="openInfo(sergio)">
                                    <h2>Sergio Linares <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sergio.linares@ssmpartner.ch">sergio.linares@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card" style="float: left;">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sergioTwo (click)="openInfo(sergioTwo)">
                                    <h2>Sergio Linares <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sergio.linares@ssmpartner.ch">sergio.linares@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card" style="float: left; margin-left:4%;">
                                <div class="img-wrapper">
                                    <img src="assets/vincenzo.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vincenzo (click)="openInfo(vincenzo)">
                                    <h2>Vincenzo Colucci <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:cv@ssmpartner.ch">cv@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/vincenzo.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vincenzoTwo (click)="openInfo(vincenzoTwo)">
                                    <h2>Vincenzo Colucci <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:cv@ssmpartner.ch">cv@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card tc-card">
                            </div>
                        </div> -->
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mathias.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #mathias (click)="openInfo(mathias)">
                                    <h2>Mathias Cukic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mathias.cukic@ssmpartner.ch">mathias.cukic@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mauro.png" alt="">
                                </div>
                                <div class="info-wrapper" #mauro (click)="openInfo(mauro)">
                                    <h2>Mauro Caruso <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mauro.caruso@ssmpartner.ch">mauro.caruso@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sara2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #saraTwo (click)="openInfo(saraTwo)">
                                    <h2>Sara Junker <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATERIN</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ose@ssmpartner.ch">sasj@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sergio (click)="openInfo(sergio)">
                                    <h2>Sergio Linares <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sergio.linares@ssmpartner.ch">sergio.linares@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </article>
                </div>
            </section>
        </div>


        <div class="tabordion" *ngIf="selectedName == 'bern'">
            <section id="section161" #sectionSixOne1>
                <label id="option161" (click)="showtab('fifthTwoNewTabState')"><span
                        [ngClass]="{rotate : states.fifthTwoNewTabState=='open'}">+</span>
                    <p>Verkaufsleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthTwoNewTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alban3.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:alban.selmanaj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Alban Selmanaj</h2>
                                    <h5>VERKAUFSLEITER</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section1612" #sectionSixOne1>
                <label id="option1612" (click)="showtab('fifthTwoNew1TabState')"><span
                        [ngClass]="{rotate : states.fifthTwoNew1TabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthTwoNew1TabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/nrece2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:nrece.gjykaj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Nrecë Gjykaj</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section1131" #sectionNewElevenThree>
                <label id="option1131" (click)="showtab('newElevenThreeTabState')"><span
                        [ngClass]="{rotate : states.newElevenThreeTabState=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newElevenThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/shala.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:adrijan.shala@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Adrijan Shala</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/belmin.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:belmin.bajrami@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Belmin Bajrami</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/hasan.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:hasan.elmali@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Hasan Elmali</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/leotrim2.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:leotrim.selmanaj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Leotrim Selmanaj</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/simeun.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:marko.simeunovic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Marko Simeunovic</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/qun.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:qun.shahini@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Qun Shahini</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/prince2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:prince.clever@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Prince Clever</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
        </div>


        <div class="tabordion" *ngIf="selectedName == 'lausanne'">
            
            <section id="sectionLu2" #newLuzernTwoTabState>
                <label id="optionLu2" (click)="showtab('newLuzernTwoTabState')"><span
                        [ngClass]="{rotate : states.newLuzernTwoTabState=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newLuzernTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/almir.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:almir.ragibovic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Almir Ragibovic</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:ali.dilard@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Ali Dilard</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/female.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:anis.rejaibi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Anis Rejaibi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/female.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:amine.wail@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Amine Wail</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:arthur.gueneau@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Arthur Gueneau</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:benjamin.benatti@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Benjamin Benatti</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:bilal.salmi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Bilal Salmi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/bilal2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:bilal.bougtib@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Bilal Bougtib</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/emilia.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:emilia.mathieu@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Emilia Mathieu</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/eymen.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:eymen.ramdani@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Eymen Ramdani</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/galtian.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:galtian.brevillier@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Galtian Brevillier</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:freire.nelson@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Freire Nelson</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/hamza.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:hamza.zeidan@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Hamza Zeidan</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/hasenne.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:hassene.aouabdi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Hassene Aouabdi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/iness.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:iness.amiche@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Iness Amiche</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/jasmine.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:jasmine.lacovetti@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Jasimne Lacovetti</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/jawad3.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:jawad.akouchiou@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Jawad Akouchiou</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/jules.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:jules.gonzalez@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Jules Gonzalez</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/female.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:kenza.bentaleb@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Kenza Bentaleb</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:mohamed.badri@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Mohamed Badri</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/female.png" alt="" class="mainimg female">
                                    <div class="mask">
                                        <p><a href="mailto:myriam.rayyad@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Myriam Rayyad</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/nabil2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:nabil.boudia@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Nabil Merad Boudia</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:nelson.freire@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Nelson Freire</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:orian.benouaret@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Orian Benouaret</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:sami.bessai@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Sami Bessai</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:timotei.rey@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Timotei Rey</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/zied3.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:zied.daldoul@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Zied Daldoul</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:yani.ait.hamadouche@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Yani Aït Hamadouche</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:yassin.ghandri@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Yassin Ghandri</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:yoann.benhammou@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Yoann Benhammou</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section6223" #sectionSixTwoThreetwo>
                <label id="option6234" (click)="showtab('firstTabState')"><span
                        [ngClass]="{rotate : states.firstTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.firstTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/banu2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:banu.dogan@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Banu Dogan</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>


        <div class="tabordion" *ngIf="selectedName == 'olten'">
            
            <section id="section1110" #sectioNewTwo>
                <label id="option111" (click)="showtab('sectioNewTwo')"><span
                        [ngClass]="{rotate : states.sectioNewTwo=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectioNewTwo>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/davidem.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:davide.mariano@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Davide Mariano</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section103" #sectionNewThree>
                <label id="option103" (click)="showtab('sectionNewThree')"><span
                        [ngClass]="{rotate : states.sectionNewThree=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectionNewThree>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alessandro.jpg" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:alessandro.tornesello@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Alessandro Tornesello</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/evolo.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:cristian.evolo@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Cristian Evolo</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/scioscia.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:fabio.scioscia@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Fabio Scioscia</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/martin.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:martin.steindorfer@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Martin Steindorfer</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/michele2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:michele.riccardo@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Michele Riccardo</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/nderim.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:nderim.saciri@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Nderim Saciri</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/nedim2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:nedim.brzina@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Nedim Brzina</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:rabih.bahsoun@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Rabih Bahsoun</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/remzii.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:remzi.can@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Remzi Can</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:sandro.lapini@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Sandro Lapini</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section114" #sectionElevenFour>
                <label id="option114" (click)="showtab('elevenFourTabState')"><span
                        [ngClass]="{rotate : states.elevenFourTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenFourTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:luis.mestre@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Luis Mestre</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>




        <div class="tabordion" *ngIf="selectedName == 'st-gallen'">
            
            <!-- <section id="section170" #sectionSevenOneZero>
                <label id="option16" (click)="showtab('sevenZeroTabState')"><span
                        [ngClass]="{rotate : states.sevenZeroTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sevenZeroTabState>
                    <article>
                        <div class="cards-wrapper">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">

                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <section id="section190" #sectionNineOneZero>
                <label id="option16" (click)="showtab('nineZeroTabState')"><span
                        [ngClass]="{rotate : states.nineZeroTabState=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.nineZeroTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/bykov2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:dominik.bykov@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Dominik Bykov</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/haris.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:haris.redzic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Haris Redzic</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ilijaz2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:ilijaz.alijagic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Ilijaz Alijagic</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/josefine.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:josefine.fryberg@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Josefine Fryberg</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section180" #sectionEightOneZero>
                <label id="option16" (click)="showtab('eightZeroTabState')"><span
                        [ngClass]="{rotate : states.eightZeroTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightZeroTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/veranda.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:vn@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Veranda Nasufi</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div class="tabordion" *ngIf="selectedName == 'ticino'">
            
            <section id="sectionTi2" #newTicinoTwoTabState>
                <label id="sectionTi2" (click)="showtab('newTicinoTwoTabState')"><span
                        [ngClass]="{rotate : states.newTicinoTwoTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTicinoTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/pansardi2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:massimo.pansardi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Massimo Pansardi</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="sectionTi4" #newTicinoFoueTabState>
                <label id="sectionTi4" (click)="showtab('newTicinoFoueTabState')"><span
                        [ngClass]="{rotate : states.newTicinoFoueTabState=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTicinoFoueTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/lagana2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:andrea.lagana@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Andrea Lagana</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/roncon3.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:davide.roncon@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Davide Roncon</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:mattia.stagliano@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Mattia Stagliano</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/deluca.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:gianni.de-luca@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Michael De Luca</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="sectionTi3" #newTicinoThreeTabState>
                <label id="option19" (click)="showtab('newTicinoThreeTabState')"><span
                        [ngClass]="{rotate : states.newTicinoThreeTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTicinoThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dinardo.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:giovanna.dinardo@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Giovanna Di Nardo</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>


        <div class="tabordion" *ngIf="selectedName == 'zofingen'">
            
            <section id="newSectionKeyt" #sectionNewKeyt>
                <label id="optionNewKeyt" (click)="showtab('newTabStateKeyt')"><span
                        [ngClass]="{rotate : states.newTabStateKeyt=='open'}">+</span>
                    <p>Key Account</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabStateKeyt>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sajic2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:igor.sajic@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Igor Sajic</h2>
                                    <h5>{{'Key Account' | uppercase}}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section>
            <section id="section71" #sectionOneSeven>
                <label id="option71" (click)="showtab('sevenOneTabState')"><span
                        [ngClass]="{rotate : states.sevenOneTabState=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sevenOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/brunoc.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:bruno.cardoso@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Bruno Cardoso</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/konstantin.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:konstantin.kuzmanovski@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Konstantin Kuzmanovski</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
            <!-- <section id="section62" #sectionSixTwo>
                <label id="option62" (click)="showtab('sixthTwoTabState')"><span
                        [ngClass]="{rotate : states.sixthTwoTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sixthTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/patrizia2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:pf@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Patrizia Fagone</h2>
                                    <h5>INNENDIENST</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
        </div>

        <div class="tabordion" *ngIf="selectedName == 'zurich-sud'">
            
            <section id="section16" #sectionSixOne>
                <label id="option16" (click)="showtab('fifthTwoTabState')"><span
                        [ngClass]="{rotate : states.fifthTwoTabState=='open'}">+</span>
                    <p>Verkaufsleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/rade.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:djuric@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Radivoje Djuric</h2>
                                    <h5>VERKAUFSLEITER</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <!-- <section id="section17" #sectionSevenOne>
                <label id="option16" (click)="showtab('fifthThreeTabState')"><span
                        [ngClass]="{rotate : states.fifthThreeTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/raphael2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #raphael (click)="openInfo(raphael)">
                                    <h2 class="short-h">Raphael Nick Zumsteg <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:zumsteg@ssmpartner.ch">zumsteg@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <section id="section18" #sectionEightOne>
                <label id="option18" (click)="showtab('fifthFourTabState')"><span
                        [ngClass]="{rotate : states.fifthFourTabState=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthFourTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/aid.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:aid.kryeziu@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Aid Kryeziu</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/kurz.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:daniel.kurz@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Daniel Kurz</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/flavio.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:flavio.hofstadt@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Flavio Hofstadt</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/genni.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:gennaro.stanzione@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Gennaro Stanzione</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/luisa.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:gabriel.dominguez@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Luis Angel Ayuso Rosique</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mehmet3.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:unlutepe@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Mehmet Ünlütepe</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/oppido.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:oppido@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Raffaele Oppido</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/santigao.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:santiago.gonzales@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Santiago Gonzalez</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section19" #sectionNineOne>
                <label id="option19" (click)="showtab('fifthFiveTabState')"><span
                        [ngClass]="{rotate : states.fifthFiveTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthFiveTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/lejla2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:lejla.kryeziu@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Lejla Kryeziu</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>

            <section id="section8s" #sectionEights>
                <label id="option8s" (click)="showtab('eightTabStates')"><span
                        [ngClass]="{rotate : states.eightTabStates=='open'}">+</span>
                    <p>Lernende/Praktikanten</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightTabStates>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/adamg.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:agg@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Adam Gabriel Gutiérrez</h2>
                                    <h5>{{'Lernende 2. Lehrjahr' | uppercase}}</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>


        
        <div class="tabordion" *ngIf="selectedName == 'zurich-nord'">
            
            <section id="section1110" #sectionNineThreeThreeThree>
                <label id="option111" (click)="showtab('sectionNineThreeThreeThree')"><span
                        [ngClass]="{rotate : states.sectionNineThreeThreeThree=='open'}">+</span>
                    <p>Verkaufsleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectionNineThreeThreeThree>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dedeler2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:adnan.dedeler@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Adnan Dedeler</h2>
                                    <h5>VERKAUFSLEITER</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section1110" #sectionNineThreeThreeTwo>
                <label id="option111" (click)="showtab('sectionNineThreeThreeTwo')"><span
                        [ngClass]="{rotate : states.sectionNineThreeThreeTwo=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectionNineThreeThreeTwo>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mirsad.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:mirsad.ademi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Mirsad Ademi</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/adhurim.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:adhurim.selmani@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Adhurim Selmani</h2>
                                    <h5>TEAMLEITER</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section91" #sectionEightOne>
                <label id="option91" (click)="showtab('eightOneTabState')"><span
                        [ngClass]="{rotate : states.eightOneTabState=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ana2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:ana.serrano@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Ana Raquel Noites Serra</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/demi.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:albion.demi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Albion Demi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dika.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:andi.dika@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Andi Dika</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/arba.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:arbelinda.bislimi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Arbelinda Bislimi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dak.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:arben.daki@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Arben Daki</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/doko.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:ardit.doko@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Ardit Doko</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/bilge.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:dilsah.bilge@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Dilsah Bilge</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:edis.mesan@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Edis Mesan</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mustafa.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:egzon.mustafa@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Egzon Mustafa</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/osmani.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:endrit.osmani@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Endrit Osmani</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:erdin.ametovski@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Erdin Ametovski</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/lips.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:fabio.lips@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Fabio Lips</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:fahim.hashimi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Fahim Hashimi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/naef2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:kevin.naef@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Kevin Naef</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/jaime2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:jaime.ansbach@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Jaime Ansbach</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/jamain.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:jamain.azigbo@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Jamain Azigbo</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:jose.delgado@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>José Delgado</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/female.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:leona.ajdari@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Leona Ajdari</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/krasniqi.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:loerat.krasniqi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Loerat Krasniqi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/luna.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:luna.akin@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Luna Akin</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/marya.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:maryam.ahmadi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Maryam Ahmadi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/miriam2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:miriam.rahmanzadeh@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Miriam Rahmanzadeh</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/berro.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:moussa.berro@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Moussa Berro</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/fazili.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:mohammad.shair.fazili@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Mohammad Shair Fazili</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/male.png" alt="" class="mainimg male">
                                    <div class="mask">
                                        <p><a href="mailto:nico.ruefenacht@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Nico Rüfenacht</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ruhollah2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:ruhollah.ahmadi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Ruhollah Ahmadi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/safina.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:safina.saido@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Safina Saido</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sinanf2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:sasan.efati@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Sasan Efati</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sinanf.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:sinan.fetahi@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Sinan Fetahi</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section82" #sectionEightTwo>
                <label id="option82" (click)="showtab('eightTwoTabState')"><span
                        [ngClass]="{rotate : states.eightTwoTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/besa.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:besa.dushaj@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Besa Dushaj</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/bisaku.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:robertina.bisaku@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Robertina Bisaku</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div class="tabordion" *ngIf="selectedName == 'zug'">
            
            <!-- <section id="section101" #sectionTenOne>
                <label id="option101" (click)="showtab('tenOneTabState')"><span
                        [ngClass]="{rotate : states.tenOneTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.tenOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/angelika.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #angelika (click)="openInfo(angelika)">
                                    <h2>Angelika Meurer <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 style="margin-bottom: 3px;">{{'Verkaufsstellenleiterin' | uppercase}} <br> {{'Schaffhausen' | uppercase}}</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:angelika.meurer@ssmpartner.ch">angelika.meurer@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <section id="section103" #sectionTenThree>
                <label id="option103" (click)="showtab('tenThreeTabState')"><span
                        [ngClass]="{rotate : states.tenThreeTabState=='open'}">+</span>
                    <p>Berater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.tenThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/agni.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:agnieszka.sankowska@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Agnieszka Sankowska</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/csaba2.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:csaba.sebestyen@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Csaba Sebestyen</h2>
                                    <h5>FINANZCOACH</h5>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section104" #sectionTenFour>
                <label id="option104" (click)="showtab('tenFourTabState')"><span
                        [ngClass]="{rotate : states.tenFourTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.tenFourTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/nicole.png" alt="" class="mainimg">
                                    <div class="mask">
                                        <p><a href="mailto:nicole.aregger@ssmpartner.ch"><img src="assets/white-email.svg" alt=""></a></p>
                                    </div>
                                </div>
                                <div class="info-wrapper">
                                    <h2>Nicole Aregger</h2>
                                    <h5>BACKOFFICE</h5>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>
    </div>
    <!-- <div class="central">
        <div class="container">
            <h2>Unsere Leidenschaft zeichnet uns aus.</h2>
        </div>
    </div> -->
    <!-- <div class="container">
        <div class="agenture">
            <h3>Agenturen</h3>
            <div class="ag-wrapper">
                <div class="ag">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/uber-uns-test']">
                        <h2>Agentur Hauptsitz</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag" *ngIf="selectedName != 'buchs'">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/buchs']">
                        <h2>Agentur Buchs</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag" *ngIf="selectedName != 'bern'">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/bern']">
                        <h2>Agentur Bern</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div> -->
            <!-- <div class="ag-wrapper"> -->
                <!-- <div class="ag" *ngIf="selectedName != 'olten'">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/olten']">
                        <h2>Agentur Olten</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div> -->
                <!-- <div class="ag" *ngIf="selectedName != 'st-gallen'">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/st-gallen']">
                        <h2>Agentur St. Gallen</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div>
                <div class="ag" *ngIf="selectedName != 'ticino'">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/ticino']">
                        <h2>Agentur Ticino</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div> -->
            <!-- </div> -->
            <!-- <div class="ag-wrapper"> -->
                <!-- <div class="ag" *ngIf="selectedName != 'zofingen'">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/zofingen']">
                        <h2>Agentur Zofingen</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div> -->
                <!-- <div class="ag" *ngIf="selectedName != 'zurich-sud'">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/zurich-sud']">
                        <h2>Agentur Zürich Süd</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div> -->
                <!-- <div class="ag" *ngIf="selectedName != 'zurich-nord'">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/zurich-nord']">
                        <h2>Agentur Zürich Nord</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div> -->
            <!-- </div> -->
            <!-- <div class="ag-wrapper"> -->
                <!-- <div class="ag" *ngIf="selectedName != 'zug'">
                    <div class="img-wrapper">
                        <img src="assets/scheidung-thumb.jpg" alt="">
                    </div>
                    <div class="info-wrapper" [routerLink]="['/agenture/zug']">
                        <h2>Agentur Zug</h2>
                        <img src="assets/white-right.svg" alt="">
                    </div>
                </div> -->
                <!-- <div class="ag">
                </div>
                <div class="ag">
                </div> -->
            <!-- </div> -->
            <!-- </div>
        </div>
    </div> -->
</div>
