import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';
import { ScrollService } from 'src/app/services/scroll.service';


@Component({
  selector: 'app-academy',
  templateUrl: './academy.component.html',
  styleUrls: ['./academy.component.scss'],
  animations: [
    trigger('openClose', [
      state('closed', style({
        'height': '0px',
      })),
      state('open', style({
        'max-height': '800px',
      })),
      transition('open <=> closed', animate(400)),
    ])
  ]
})
export class AcademyComponent implements OnInit {
  financeForm: FormGroup
  kinderForm: FormGroup
  financeTrue: boolean = false
  kinderTrue: boolean = false
  formSidebarSuccess: boolean = false
  formKinderSuccess: boolean = false
  types: any[] = []
  states = {
    'firstTabState' : 'closed',
    'secondTabState' : 'closed',
    'thirdTabState' : 'closed'
  }


  constructor(private mailService: MailService, private scroll: ScrollService) { }

  ngOnInit(): void {
    this.scroll.setScroll()
    this.financeForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]),
      vorname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      nachname: new FormControl('', [Validators.required, Validators.minLength(2)]),
      telefon: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*[0-9+ /-]*$"), Validators.minLength(2)]),
      message: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });
  }

  get f() {
    return this.financeForm.controls;
  }

  showtab(type) {
    for (var key in this.states) {
      if(key == type){
        this.states[key]= this.states[key] === 'closed' ? 'open' : 'closed';
      }else{
        this.states[key] = 'closed'
      }
    }
  }

  selectType(type, element:HTMLElement) {
    if (this.types.length > 0) {
      if(this.types.includes(type)){
        const index = this.types.indexOf(type);
        this.types.splice(index, 1);
        element.classList.remove("checked");
      }else{
        this.types.push(type)
        element.classList.add("checked");
      }
    } else {
      this.types.push(type)
      element.classList.add("checked");
    }
  }

  sendSidebar() {
    this.financeTrue = true
    let formData = new FormData
    let types = this.types.join()
    let name = this.f.vorname.value + ' ' + this.f.nachname.value

    formData.append('email', this.f.email.value),
      formData.append('name', name),
      formData.append('telefon', this.f.telefon.value),
      formData.append('message', this.f.message.value),
      formData.append('types', types),

    this.financeTrue = true;
    if (this.financeForm.valid) {
      console.log('success')
      this.financeTrue = false;
      this.formSidebarSuccess = true;
      this.mailService.sendMail(formData).catch(error => {
        if (error) {
          this.formSidebarSuccess = false;
          console.log(error)
        }
      });
    } else {
      console.log("error");
    }
  }
}
