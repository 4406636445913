<div class="main-wrapper">
    <div class="intro-default">
        <div class="container">
            <div class="section-default">
                <h2>News</h2>
            </div>
            <div class="image-wrapper">
                <img src="assets/ssm-vosorge-hero.png" alt="">
            </div>
        </div>
    </div>
     <div class="container">
        <div class="news-wrapper">
            <div class="news">
                <div class="news-image-wrapper">
                    <img src="assets/1.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <div class="calendar"><img src="assets/calendar.svg" alt="">9. Dezember 2015</div>
                    <div class="user"><img src="assets/user.svg" alt="">Salome Tscheulin</div>
                    <div class="comment"><img src="assets/comment.svg" alt="">9 Kommentare</div>
                </div>
                <div class="content-wrapper">
                    <h2>SSM Partner mit neuer Fahrzeugflote unterwegs</h2>
                    <p>Am Dienstag, 10. November 2015 durfe die SSM Partner AG ihre neue Fahrzeugflote, bestehend aus
                        dreizehn trendigen und farbenfrohen New Fiat 500 im Hammer Auto Center Emmenbrücke entgegen
                        nehmen. Die flinken und wendigen Fiat 500 stehen der SSM Partner AG ab sofort tätkraftig zur
                        Seite und bringen neben dem kompakten Design, welches sich spielend leicht.</p>
                </div>
                <button [routerLink]="['/post']" class="news-button">Weiterlesen <img src="assets/right-arrow.svg" alt=""></button>
            </div>
            <div class="news">
                <div class="news-image-wrapper">
                    <img src="assets/2.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <div class="calendar"><img src="assets/calendar.svg" alt="">9. Dezember 2015</div>
                    <div class="user"><img src="assets/user.svg" alt="">Salome Tscheulin</div>
                    <div class="comment"><img src="assets/comment.svg" alt="">9 Kommentare</div>
                </div>
                <div class="content-wrapper">
                    <h2>Einweihungsfeier in Ebikon</h2>
                    <p>Seit Anfang 2015 haben wir uns an zentralen Lage ih Ebikon eingelebt. Die grosszügigen, modernen
                        Büroräurme ermöglichen uns unsere Urtenmenskultur weiter auszubauen und unsere Beratungen
                        vermehrt auch vor Ort wahrzunehmen. Diesen Meilenstein feierten wir mit allen Menschen, die uns
                        tagtäglich in unserem Tun unterstützen. Unser Dank gilt allen Mitarbeitenden, Anwohnern,
                        Kundenberatern und Freunden. Schauen Sie
                    </p>
                </div>
                <button [routerLink]="['/post']" class="news-button">Weiterlesen <img src="assets/right-arrow.svg" alt=""></button>
            </div>
            <div class="news">
                <div class="news-image-wrapper">
                    <img src="assets/3.jpg" alt="">
                </div>
                <div class="info-wrapper">
                    <div class="calendar"><img src="assets/calendar.svg" alt="">9. Dezember 2015</div>
                    <div class="user"><img src="assets/user.svg" alt="">Salome Tscheulin</div>
                    <div class="comment"><img src="assets/comment.svg" alt="">9 Kommentare</div>
                </div>
                <div class="content-wrapper">
                    <h2>Kick-off meeting 2015</h2>
                    <p>Mitten in der Leuchstadt Luzern fand unser diesjähriges Kick-off meeting statt. Sämtliche
                        Vertriebspartner u.a. auch Visana, Helsana und Concordia folgten der Einladung. Unser Motivator
                        und Vertriebsleiter Davide Spadea eröffnete den Event mit einigen Eckzahlen und wichtigen Informationen zur Hochsaison.
                    </p>
                </div>
                <button [routerLink]="['/post']" class="news-button">Weiterlesen <img src="assets/right-arrow.svg" alt=""></button>
            </div>
            <div class="news">
                    <div class="news-image-wrapper">
                        <img src="assets/1.jpg" alt="">
                    </div>
                    <div class="info-wrapper">
                        <div class="calendar"><img src="assets/calendar.svg" alt="">9. Dezember 2015</div>
                        <div class="user"><img src="assets/user.svg" alt="">Salome Tscheulin</div>
                        <div class="comment"><img src="assets/comment.svg" alt="">9 Kommentare</div>
                    </div>
                    <div class="content-wrapper">
                        <h2>SSM Partner mit neuer Fahrzeugflote unterwegs</h2>
                        <p>Am Dienstag, 10. November 2015 durfe die SSM Partner AG ihre neue Fahrzeugflote, bestehend aus
                            dreizehn trendigen und farbenfrohen New Fiat 500 im Hammer Auto Center Emmenbrücke entgegen
                            nehmen. Die flinken und wendigen Fiat 500 stehen der SSM Partner AG ab sofort tätkraftig zur
                            Seite und bringen neben dem kompakten Design, welches sich spielend leicht.</p>
                    </div>
                    <button [routerLink]="['/post']" class="news-button">Weiterlesen <img src="assets/right-arrow.svg" alt=""></button>
                </div>
                <div class="news">
                    <div class="news-image-wrapper">
                        <img src="assets/2.jpg" alt="">
                    </div>
                    <div class="info-wrapper">
                        <div class="calendar"><img src="assets/calendar.svg" alt="">9. Dezember 2015</div>
                        <div class="user"><img src="assets/user.svg" alt="">Salome Tscheulin</div>
                        <div class="comment"><img src="assets/comment.svg" alt="">9 Kommentare</div>
                    </div>
                    <div class="content-wrapper">
                        <h2>Einweihungsfeier in Ebikon</h2>
                        <p>Seit Anfang 2015 haben wir uns an zentralen Lage ih Ebikon eingelebt. Die grosszügigen, modernen
                            Büroräurme ermöglichen uns unsere Urtenmenskultur weiter auszubauen und unsere Beratungen
                            vermehrt auch vor Ort wahrzunehmen. Diesen Meilenstein feierten wir mit allen Menschen, die uns
                            tagtäglich in unserem Tun unterstützen. Unser Dank gilt allen Mitarbeitenden, Anwohnern,
                            Kundenberatern und Freunden. Schauen Sie
                        </p>
                    </div>
                    <button [routerLink]="['/post']" class="news-button">Weiterlesen <img src="assets/right-arrow.svg" alt=""></button>
                </div>
                <div class="news">
                    <div class="news-image-wrapper">
                        <img src="assets/3.jpg" alt="">
                    </div>
                    <div class="info-wrapper">
                        <div class="calendar"><img src="assets/calendar.svg" alt="">9. Dezember 2015</div>
                        <div class="user"><img src="assets/user.svg" alt="">Salome Tscheulin</div>
                        <div class="comment"><img src="assets/comment.svg" alt="">9 Kommentare</div>
                    </div>
                    <div class="content-wrapper">
                        <h2>Kick-off meeting 2015</h2>
                        <p>Mitten in der Leuchstadt Luzern fand unser diesjähriges Kick-off meeting statt. Sämtliche
                            Vertriebspartner u.a. auch Visana, Helsana und Concordia folgten der Einladung. Unser Motivator
                            und Vertriebsleiter Davide Spadea eröffnete den Event mit einigen Eckzahlen und wichtigen Informationen zur Hochsaison.
                        </p>
                    </div>
                    <button [routerLink]="['/post']" class="news-button">Weiterlesen <img src="assets/right-arrow.svg" alt=""></button>
                </div>
        </div>

    </div>
</div>