import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-formular',
  templateUrl: './formular.component.html',
  styleUrls: ['./formular.component.scss']
})
export class FormularComponent implements OnInit {
  financeForm: FormGroup
  financeTrue: boolean = false
  formSidebarSuccess: boolean = false

  constructor(private mailService: MailService) { }

  ngOnInit(): void {
    this.financeForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]),
      vorname: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9.']*$"), Validators.minLength(2)]),
      nachname: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9.']*$"), Validators.minLength(2)]),
      telefon: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(2)]),
      message: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-z0-9. _]*[A-Za-z0-9][A-Za-z0-9. _]*$"), Validators.minLength(2)]),
    });
  }

  get f() {
    return this.financeForm.controls;
  }

  sendSidebar() {
    this.financeTrue = true
    let formData = new FormData
    let name = this.f.vorname.value + ' ' + this.f.nachname.value

    formData.append('email', this.f.email.value),
      formData.append('name', name),
      formData.append('telefon', this.f.telefon.value),
      formData.append('message', this.f.message.value)

    this.financeTrue = true;
    if (this.financeForm.valid) {
      console.log('success')
      this.financeTrue = false;
      this.formSidebarSuccess = true;
      this.mailService.sendMail(formData).catch(error => {
        if (error) {
          this.formSidebarSuccess = false;
          console.log(error)
        }
      });
    } else {
      console.log("error");
    }
  }
  
}
