<div class="nav" *ngIf="!landingPage">
    <div class="separator">
    </div>
    <div class="nav-wrapper">
        <div class="menu">
            <div class="logo-wrapper" [routerLink]="['/home']">
                <img src="assets/logo-green.svg" alt="">
                <div class="cleaner"></div>
            </div>
            <div class="menu-nav">
                <ul *ngIf="lebenNavigation">
                    <li><a [routerLink]="['/home']" routerLinkActive="active-link">HOME</a></li>
                    <!-- <div class="custom-select-wrapper">
                        <div class="custom-select" #select>
                            <div class="custom-select__trigger" (click)="openSelect(select)">
                                UNTERNEHMEN
                                <img src="assets/arr-green.svg" alt="">
                            </div>
                            <div class="custom-options">
                                <span class="custom-option" (click)="setNavigation('leben', select)">Mein Leben</span>
                                <span class="custom-option" (click)="setNavigation('finance',select)">Meine Finanzen</span>
                                <span class="custom-option" (click)="setNavigation('uber', select)">Über Uns</span>
                            </div>
                        </div>
                    </div> -->
                    <li #select><a (click)="openSelect(select)">UNTERNEHMEN <img src="assets/arr-green.svg" alt=""></a>
                        <div class="custom-options">
                            <span class="custom-option" [routerLink]="['/werte']">WERTE</span>
                            <span class="custom-option" [routerLink]="['/uber-uns']">TEAM</span>
                        </div>
                    </li>
                    <!-- <li>UNTERNEHMEN <img src="assets/arr-green.svg" alt=""></li> -->
                    <li #selectTwo><a (click)="openSelect(selectTwo)">MEINE FINANZEN <img src="assets/arr-green.svg" alt=""></a>
                        <div class="custom-options">
                            <span class="custom-option" [routerLink]="['/lebensereignis']">LEBENSEREIGNIS</span>
                            <span class="custom-option" [routerLink]="['/lebensphase']">LEBENSPHASE</span>
                            <span class="custom-option" [routerLink]="['/analyse']">ANALYSE</span>
                            <span class="custom-option" [routerLink]="['/HAUS DER FINANZEN']">HAUS DER FINANZEN</span>
                        </div>
                    </li>
                    <li><a [routerLink]="['/lebensphase']" routerLinkActive="active-link">ACADEMY</a></li>
                    <li><a [routerLink]="['/karriere']" routerLinkActive="active-link">KARRIERE</a></li>
                    <!-- <li><a [routerLink]="['/news']" routerLinkActive="active-link">NEWS</a></li> -->
                    <li class="kontakt"><a [routerLink]="['/kontakt']" routerLinkActive="active-link">Kontakt aufnehmen</a></li>
                </ul>
                <ul *ngIf="financeNavigation" style="margin-left:8px;">
                    <li><a [routerLink]="['/home']" routerLinkActive="active-link">HOME</a></li>
                    <li><a [routerLink]="['/analyse']" routerLinkActive="active-link">ANALYSE</a></li>
                    <li><a [routerLink]="['/finanzen']" routerLinkActive="active-link">HAUS DER FINANZEN</a></li>
                    <li><a [routerLink]="['/karriere']" routerLinkActive="active-link">KARRIERE</a></li>
                    <!-- <li><a [routerLink]="['/news']" routerLinkActive="active-link">NEWS</a></li> -->
                    <li><a [routerLink]="['/kontakt']" routerLinkActive="active-link">KONTAKT</a></li>
                </ul>
                <ul *ngIf="uberNavigation" style="margin-left:24px;">
                    <li><a [routerLink]="['/home']" routerLinkActive="active-link">HOME</a></li>
                    <li><a [routerLink]="['/werte']" routerLinkActive="active-link">WERTE</a></li>
                    <li><a [routerLink]="['/academy']" routerLinkActive="active-link">ACADEMY</a></li>
                    <li><a [routerLink]="['/uber-uns']" routerLinkActive="active-link">TEAM</a></li>
                    <li><a [routerLink]="['/karriere']" routerLinkActive="active-link">KARRIERE</a></li>
                    <!-- <li><a [routerLink]="['/news']" routerLinkActive="active-link">NEWS</a></li> -->
                    <li><a [routerLink]="['/kontakt']" routerLinkActive="active-link">KONTAKT</a></li>
                </ul>
                <!-- <div class="custom-select-wrapper">
                    <div class="custom-select" #select>
                        <div class="custom-select__trigger" (click)="openSelect(select)"><span
                                *ngIf="activeOptions == 'Mein Leben'"><img class="down-icon" src="assets/life.svg"
                                    alt=""> Mein
                                Leben</span><span *ngIf="activeOptions == 'Meine Finanzen'"><img class="down-icon"
                                    src="assets/bank.svg" alt="">
                                Meine Finanzen</span><span *ngIf="activeOptions == 'Über Uns'"><img class="down-icon"
                                    src="assets/company-head.svg" alt=""> Über Uns</span>
                            <img src="assets/down-arrow.svg" alt="">
                        </div>
                        <div class="custom-options">
                            <span class="custom-option" (click)="setNavigation('leben', select)">Mein Leben</span>
                            <span class="custom-option" (click)="setNavigation('finance',select)">Meine Finanzen</span>
                            <span class="custom-option" (click)="setNavigation('uber', select)">Über Uns</span>
                        </div>
                    </div>
                </div> -->
                <div class="cleaner"></div>
            </div>
            <div id="menuToggle">
                <input type="checkbox" #mobCheck />
                <span class="line"></span>
                <span class="line"></span>
                <span class="line"></span>
                <ul id="menu">
                    <!-- <div class="custom-select-mob" #selectMob>
                        <div class="custom-select__trigger" (click)="openSelect(selectMob)"><span
                                *ngIf="activeOptions == 'Mein Leben'"> Mein
                                Leben</span><span *ngIf="activeOptions == 'Meine Finanzen'">
                                Meine Finanzen</span><span *ngIf="activeOptions == 'Über Uns'"> Über Uns</span>
                            <img src="assets/down-arrow.svg" alt="">
                        </div>
                        <div class="custom-options">
                            <span class="custom-option" (click)="setNavigation('leben', selectMob)">Mein Leben</span>
                            <span class="custom-option" (click)="setNavigation('finance',selectMob)">Meine Finanzen</span>
                            <span class="custom-option" (click)="setNavigation('uber', selectMob)">Über Uns</span>
                        </div>
                    </div> -->
                    <div>
                        <li><a [routerLink]="['/home']" (click)="closeMenu()" routerLinkActive="active-link">HOME</a></li>
                        <li><a [routerLink]="['/werte']" (click)="closeMenu()" routerLinkActive="active-link">WERTE</a></li>
                        <li><a [routerLink]="['/uber-uns']" (click)="closeMenu()" routerLinkActive="active-link">TEAM</a></li>
                        <li><a [routerLink]="['/lebensereignis']" (click)="closeMenu()" routerLinkActive="active-link">LEBENSEREIGNIS</a></li>
                        <li><a [routerLink]="['/lebensphase']" (click)="closeMenu()" routerLinkActive="active-link">LEBENSPHASE</a></li>
                        <li><a [routerLink]="['/karriere']" (click)="closeMenu()" routerLinkActive="active-link">KARRIERE</a></li>
                        <li><a [routerLink]="['/analyse']" (click)="closeMenu()" routerLinkActive="active-link">ANALYSE</a></li>
                        <li><a [routerLink]="['/finanzen']" (click)="closeMenu()" routerLinkActive="active-link">HAUS DER FINANZEN</a></li>
                        <!-- <li><a [routerLink]="['/news']" (click)="closeMenu()" routerLinkActive="active-link">NEWS</a></li> -->
                        <li><a [routerLink]="['/kontakt']" (click)="closeMenu()" routerLinkActive="active-link">KONTAKT</a></li>
                    </div>
                    <!-- <div *ngIf="financeNavigation">
                        <li><a [routerLink]="['/home']" (click)="closeMenu()" routerLinkActive="active-link">HOME</a></li>
                        <li><a [routerLink]="['/analyse']" (click)="closeMenu()" routerLinkActive="active-link">ANALYSE</a></li>
                        <li><a [routerLink]="['/finanzen']" (click)="closeMenu()" routerLinkActive="active-link">HAUS DER FINANZEN</a></li>
                        <li><a [routerLink]="['/karriere']" (click)="closeMenu()" routerLinkActive="active-link">KARRIERE</a></li>
                        <li><a [routerLink]="['/news']" (click)="closeMenu()" routerLinkActive="active-link">NEWS</a></li>
                        <li><a [routerLink]="['/kontakt']" (click)="closeMenu()" routerLinkActive="active-link">KONTAKT</a></li>
                    </div>
                    <div *ngIf="uberNavigation">
                        <li><a [routerLink]="['/home']" (click)="closeMenu()" routerLinkActive="active-link">HOME</a></li>
                        <li><a [routerLink]="['/werte']" (click)="closeMenu()" routerLinkActive="active-link">WERTE</a></li>
                        <li><a [routerLink]="['/academy']" (click)="closeMenu()" routerLinkActive="active-link">ACADEMY</a></li>
                        <li><a [routerLink]="['/uber-uns']" (click)="closeMenu()" routerLinkActive="active-link">TEAM</a></li>
                        <li><a [routerLink]="['/karriere']" (click)="closeMenu()" routerLinkActive="active-link">KARRIERE</a></li>
                        <li><a [routerLink]="['/news']" (click)="closeMenu()" routerLinkActive="active-link">NEWS</a></li>
                        <li><a [routerLink]="['/kontakt']" (click)="closeMenu()" routerLinkActive="active-link">KONTAKT</a></li>
                    </div> -->
                    <div class="contact-menu">
                        <p><img src="assets/telephone-header.svg" alt="" style="margin-left:2px;"><a href="{{'tel:0412202050'}}">+41 (0)41 220 20 50</a></p>
                        <p><img src="assets/email-header.svg" alt=""> <a href="mailto:info@ssmpartner.ch">info@ssmpartner.ch</a></p>
                    </div>
                </ul>
            </div>
            <div class="cleaner"></div>
        </div>
    </div>
</div>