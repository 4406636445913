<div class="main-wrapper">
    <div class="intro-default">
        <div class="container">
            <div class="image-wrapper">
                <img src="assets/maska.svg" alt="">
            </div>
        </div>
    </div>
    <div class="container">
        <h1 class="title-page">Haus der Finanzen</h1>
        <div class="main">
            <div class="section">
                <h2>Haus der Finanzen</h2>
                <p>Eine langfristig stabile Finanzplanung benötigt ein solides Fundament. Den Bereich der Finanzen
                    können Sie sich dabei ähnlich einem Haus vorstellen. Das Bild vom Haus der Finanzen verdeutlicht,
                    aus welchen Bausteinen sich die ganzheitliche Finanzplanung zusammenfügt. Das fängt beim Sparen an
                    und geht bis zum Vermögensaufbau.</p>
            </div>
            <div class="tabordion">
                <section id="section1" #sectionOne>
                    <label id="option1" (click)="showtab('firstTabState')"><span
                            [ngClass]="{rotate : states.firstTabState=='open'}">+</span>
                        <p>Das Fundament: Ihr Sparpotenzial</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.firstTabState>
                        <article>
                            <p>Ohne Fundament hat ein Haus keinen festen Stand und fällt irgendwann in sich zusammen.
                                Ähnlich sieht es mit Ihren Finanzen aus. Ganz gleich, ob Unternehmen oder Privatperson:
                                Die eigene Finanzplanung baut immer auf einem soliden Polster auf. Ein wichtiger
                                Baustein im ganzheitlichen Finanzcoaching besteht daher darin, Ihr persönliches
                                Sparpotenzial zu ermitteln.</p>
                        </article>
                    </div>
                </section>
                <section id="section2" #sectionTwo>
                    <label id="option2" (click)="showtab('secondTabState')"><span
                            [ngClass]="{rotate : states.secondTabState=='open'}">+</span> <p>Das Erdgeschoss: Ihre
                        Absicherung</p></label>
                    <div class="article-wrapper" [@openClose]=states.secondTabState>
                        <article>
                            <p>Ein weiterer grundlegender Baustein für die ganzheitliche Finanzplanung besteht in der
                                passenden Absicherung. Für Privatpersonen bedeutet dies, den richtigen Gesundheits- und
                                Unfallschutz zu finden sowie Versicherung für Sachwerte und für den Rechtsschutz
                                abschzuschliessen. Unternehmen benötigen die entsprechenden Unternehmensversicherungen.
                                Wir beraten Sie produktunabhängig und finden heraus, welche Versicherung sich am besten
                                für Ihre Bedürfnisse eignet.</p>
                        </article>
                    </div>
                </section>
                <section id="section3" #sectionThree>
                    <label id="option3" (click)="showtab('thirdTabState')"><span
                            [ngClass]="{rotate : states.thirdTabState=='open'}">+</span> <p>Die obere Etage: Ihre Vorsorge</p></label>
                    <div class="article-wrapper" [@openClose]=states.thirdTabState>
                        <article>
                            <p>Damit Sie auch im Alter oder bei Erwerbsunfähigkeit Ihren Lebensstandard halten können,
                                benötigen Sie eine umfassende Vorsorge. Wir zeigen Ihnen nicht nur, wie Sie sich selbst
                                am besten absichern, sondern finden auch die ideale Vorsorge für Ihre Kinder. Durch eine
                                auf die persönlichen Anforderungen abgestimmte Todesfall-Vorsorge sind auch Ihre
                                Angehörigen langfristig gut versorgt. Unternehmen müssen sich um die Absicherung Ihrer
                                Angestellten kümmern. Wir unterstützen Sie dabei, zum Beispiel durch spezifische
                                Lösungen für die betriebliche Altersvorsorge für Mitarbeiter im In- und Ausland.</p>
                        </article>
                    </div>
                </section>
                <section id="section4" #sectionFour>
                    <label id="option4" (click)="showtab('fourthTabState')"><span
                            [ngClass]="{rotate : states.fourthTabState=='open'}">+</span> <p>Das Dach: Der Vermögensaufbau</p></label>
                    <div class="article-wrapper" [@openClose]=states.fourthTabState>
                        <article>
                            <p>Steht Ihre Absicherung und Ihre Vorsorge, können Sie sich um den Vermögensaufbau kümmern.
                                Um das Haus der Finanzen mit einem dichten Dach abzuschliessen, erarbeiten wir gemeinsam
                                mit Ihnen Ihre Anlageziele. Basierend auf Ihren Wünschen stellen wir Ihnen
                                Investitionsmöglichkeiten vor, mit denen Sie von finanzieller Sicherheit und zugleich
                                von Steuervorteilen profitieren. So können Sie sich unbeschwert auf die Zukunft freuen.
                            </p>
                        </article>
                    </div>
                </section>
            </div>
        </div>

        <div class="sidebar">
            <h2>Informationen anfordern</h2>
            <form class="form-wrapper" [formGroup]="financeForm" action="" *ngIf="!formSidebarSuccess">
                <div class="field">
                    <input formControlName="vorname" type="text" placeholder="Vorname"
                        [ngClass]="{'invalid': (f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)">
                        <span *ngIf="f.vorname.errors.required">Bitte Vorname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="nachname" type="text" placeholder="Nachname"
                        [ngClass]="{'invalid': (f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)">
                        <span *ngIf="f.nachname.errors.required">Bitte Nachname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="email" type="text" placeholder="E-Mail"
                        [ngClass]="{'invalid': (f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)">
                        <span *ngIf="f.email.errors.required">Bitte Email angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="telefon" type="text" placeholder="Telefon"
                        [ngClass]="{'invalid': (f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)">
                        <span *ngIf="f.telefon.errors.required">Bitte Telefon angeben</span>
                    </div>
                </div>
                <div class="checkbox-wrapper">
                    <div class="row">
                        <label class="checkbox">Krankenkasse
                            <input type="checkbox">
                            <span class="checkmark" #krankenkasse
                                (click)="selectType('Krankenkasse', krankenkasse)"></span>
                        </label>
                        <label class="checkbox">Sachversicherung
                            <input type="checkbox">
                            <span class="checkmark" #sachversicherung
                                (click)="selectType('Sachversicherung', sachversicherung)"></span>
                        </label>
                    </div>
                    <div class="row">
                        <label class="checkbox">Unfallversicherung
                            <input type="checkbox">
                            <span class="checkmark" #unfallversicherung
                                (click)="selectType('Unfallversicherung', unfallversicherung)"></span>
                        </label>
                        <label class="checkbox">Vorsorge
                            <input type="checkbox">
                            <span class="checkmark" #vorsorge (click)="selectType('Vorsorge', vorsorge)"></span>
                        </label>
                    </div>
                </div>
                <div class="field area">
                    <textarea formControlName="message" cdkAutosizeMinRows="110" placeholder="Nachricht"
                        cdkAutosizeMaxRows="120"
                        [ngClass]="{'invalid': (f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)}"></textarea>
                    <div class="error-msg"
                        *ngIf="(f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)">
                        <span *ngIf="f.message.errors.required">Bitte Nachricht eingeben</span>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <button class="classic-button" (click)="sendSidebar()">SENDEN</button>
                </div>
            </form>
            <div class="success-mess" *ngIf="formSidebarSuccess">
                Nachricht wurde erfolgreich übermittelt.
            </div>
        </div>
    </div>
</div>