<div class="main-wrapper">
    <div class="intro-wrapper">
        <div class="img">
        </div>
    </div>
    <div class="container">
        <h1 class="page-title">Karriere</h1>
        <div class="buttons-wrapper">
            <div class="left-button" [ngClass]="{'active':aussendienst}" (click)="changeTab('aussendienst')">
                <img src="assets/aussendient-white.svg" alt="" *ngIf="aussendienst">
                <img src="assets/aussendienst-gray.svg" alt="" *ngIf="!aussendienst">
                <span>Aussendienst</span>
            </div>
            <div class="right-button" [ngClass]="{'active':innendienst}" (click)="changeTab('innendienst')">
                <img src="assets/innendienst-white.svg" alt="" *ngIf="innendienst">
                <img src="assets/innendienst-gray.svg" alt="" *ngIf="!innendienst">
                <span>Innendienst</span>
            </div>
        </div>
        <div class="text-wrapper">
            <div *ngIf="aussendienst">
                <h2 class="title-text">Karriere als
                    Finanzcoach im Aussendienst bei der <br>SSM Partner AG</h2>
                <p class="p-text">Der Finanzmarkt ist äusserst komplex und befindet sich in ständiger Entwicklung. Viele
                    Verbraucher fühlen sich dadurch verunsichert. Die SSM Partner AG hat es sich zum Ziel gesetzt, als
                    ganzheitlicher Finanzcoach Transparenz in den unübersichtlichen Markt der Versicherungs- und
                    Finanzierungsangebote zu bringen. Als Mitarbeiter im Aussendienst leisten Sie dazu einen
                    wesentlichen Beitrag. Sie helfen unseren Kunden, die besten Lösungen für ihre Bedürfnisse zu finden.
                </p>
                <h2 class="title-text">Starten Sie durch -
                    als Finanzcoach bei der <br>SSM Partner AG</h2>
                <p class="p-text">Als Finanzcoach der SSM Partner AG können Sie Ihren Arbeitsalltag weitgehend frei
                    gestalten. Dabei profitieren Sie von transparenten Verdienst- und Karrieremöglichkeiten. Wir möchten
                    allen unseren Mitarbeitern die gleichen Aufstiegschancen bieten. Ihre Leistung bestimmt das Tempo,
                    mit dem Sie in unserem Unternehmen vorankommen. Leistungsabhängige Boni verbessern Ihr Gehalt und
                    motivieren durch das hohe Verdienstpotenzial.
                </p>
                <h2 class="title-text">Arbeiten Sie in einem
                    Markt mit enormem Wachstumspotenzial</h2>
                <p class="p-text">Die ganzheitliche Finanzberatung sichert Ihnen einen Job mit Zukunft. Sie arbeiten auf
                    einem
                    Markt mit enormem Wachstumspotenzial. Steigende Versicherungsprämien und Steuern, Schwankungen auf
                    den Kapitalmärkten und die Vielzahl verschiedener Anlagemöglichkeiten verunsichern die Verbraucher.
                    Der Bedarf für eine persönliche und bedarfsgerechte Beratung wächst.
                </p>
                <p class="p-text">Als Spezialist mit gesamtheitlichem Blick auf die Finanzmärkte erkennen Sie die
                    Bedürfnisse und Wünsche unserer Kunden. Basierend auf den individuellen Anforderungen kombinieren
                    Sie Finanz- und Versicherungsprodukte, die optimal zur jeweiligen Lebenssituation passen. Mit Ihrem
                    Fachwissen tragen Sie dazu bei, die optimale Absicherung für jeden Menschen und jede Familie zu
                    finden.
                </p>
                <h2 class="title-text">Ideale
                    Einstiegschancen</h2>
                <p class="p-text">Bei der SSM Partner AG finden Sie die optimalen Bedingungen für Ihren Berufseinstieg
                    als Finanzcoach vor. Die Grundlage dafür bilden fundierte Aus- und Weiterbildungsprogramme, durch
                    die Ihr Fachwissen immer auf dem aktuellsten Stand ist. So bieten Sie unsere Kunden eine optimale
                    Beratung und fördern zugleich Ihre eigene Karriere. Für Ihre persönliche Entwicklung können Sie
                    zudem auf die Unterstützung erfahrener Führungskräfte zählen.
                </p>
                <p class="p-text">
                    Haben Sie Interesse, als Finanzcoach oder in einer Führungsposition zum Erfolg der SSM Partner AG
                    beizutragen? Dann bewerben Sie sich direkt online! Wir freuen uns auf Ihre Bewerbungsunterlagen.
                </p>
            </div>

            <div *ngIf="innendienst">
                <h2 class="title-text">Karriere im Innendienst - Arbeiten Sie
                    im Backoffice der SSM Partner AG </h2>
                <p class="p-text">Bei der ganzheitlichen Finanzberatung steht der Mensch im Mittelpunkt. Dazu zählen
                    sowohl unsere Kunden als auch unser Team. Unser Erfolg baut auf der Stärke jedes einzelnen
                    Mitarbeiters auf. Die Arbeit im Backoffice der SSM Partner AG bietet Ihnen daher die idealen
                    Voraussetzungen, Ihre Talente und Fähigkeiten einzubringen. Wir unterstützen und fördern Ihr
                    berufliches Engagement. Denn nur im Team können wir unsere Mission erfüllen.
                </p>
                <h2 class="title-text">Spannende Herausforderungen warten auf
                    Sie</h2>
                <p class="p-text">Da wir etwas bewegen wollen, möchten wir auch jedem unserer Mitarbeiter optimale
                    Möglichkeiten zur Weiterentwicklung bieten. Von Beginn an arbeiten Sie bei der SSM Partner AG mit
                    einem hohen Mass an Eigenverantwortung. Sie erwartet ein abwechslungsreicher Arbeitsalltag mit
                    spannenden Herausforderungen. Die Arbeit in professionellen Teams ermöglicht es Ihnen, Ihre
                    individuellen Talente zu fördern. Dank unserer Arbeitszeitmodelle können Sie dabei persönliche und
                    berufliche Selbstverwirklichung in Einklang bringen.
                </p>
                <h2 class="title-text">Vielfältige Entwicklungsmöglichkeiten
                </h2>
                <p class="p-text">Wir glauben, dass Menschen an ihren Aufgaben wachsen. Von Anfang an erhalten unsere
                    Mitarbeiter im Innendienst daher grossen Gestaltungsspielraum. Die Zusammenarbeit im Team fördert
                    den Wissens- und Erfahrungsaustausch zwischen Mitarbeitern mit unterschiedlichen Hintergründen.
                    Unterstützt durch erfahrene Führungskräfte verbessern Sie Ihre persönliche und fachliche Kompetenz.
                    Weiterbildungsprogramme halten Ihr Fachwissen auf dem neuesten Stand.
                </p>
                <h2 class="title-text">Ihre Benefits</h2>
                <p class="p-text">Unser Ziel ist es, unsere Mitarbeiter bei der beruflichen und persönlichen Entwicklung
                    optimal zu unterstützen. Daher stehen wir Ihnen in allen Lebensphasen zur Seite. Attraktive Vorteile
                    wie markt- und leistungsorientierte Gehälter, ein motivierendes Arbeitsumfeld und Aufstiegschancen
                    in anspruchsvolle Führungspositionen tragen zu Ihrer Zufriedenheit bei. Auf Ihrer Zufriedenheit
                    basiert letztlich unser Erfolg.
                </p>
                <h2 class="title-text">Bewerben Sie sich jetzt!</h2>
                <p class="p-text">Bei der SSM Partner AG können Sie Ihre Talente in unterschiedlichen Bereichen
                    einbringen. Unterstützen Sie unsere Aussendienstmitarbeiter bei der Terminplanung und der Beratung
                    unserer Kunden. Übernehmen Sie abwechslungsreiche Aufgaben im Bereich Kommunikation und tragen zur
                    positiven Aussendarstellung unseres Unternehmens bei. Überarbeiten und aktualisieren Sie interne
                    Abläufe. In allen Tätigkeitsfeldern sind Ihre Kreativität und Ihr Fachwissen gefragt.
                </p>
                <p class="p-text">
                    Haben Sie Interesse an einer Karriere im Backoffice der SSM Partner AG? Wir freuen uns auf Ihre
                    Bewerbung!
                </p>
            </div>
        </div>
        <div class="sidebar">
            <h2>Jetzt bewerben</h2>
            <form class="form-wrapper" [formGroup]="financeForm" action="" *ngIf="!formSidebarSuccess">
                <div class="field">
                    <input formControlName="vorname" type="text" placeholder="Vorname"
                        [ngClass]="{'invalid': (f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)">
                        <span *ngIf="f.vorname.errors.required">Bitte Vorname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="nachname" type="text" placeholder="Nachname"
                        [ngClass]="{'invalid': (f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)">
                        <span *ngIf="f.nachname.errors.required">Bitte Nachname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="email" type="text" placeholder="E-Mail"
                        [ngClass]="{'invalid': (f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)">
                        <span *ngIf="f.email.errors.required">Bitte Email angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="telefon" type="text" placeholder="Telefon"
                        [ngClass]="{'invalid': (f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)">
                        <span *ngIf="f.telefon.errors.required">Bitte Telefon angeben</span>
                    </div>
                </div>
                <div class="field area">
                    <textarea formControlName="message" cdkAutosizeMinRows="110" placeholder="Nachricht"
                        cdkAutosizeMaxRows="120"
                        [ngClass]="{'invalid': (f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)}"></textarea>
                    <div class="error-msg"
                        *ngIf="(f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)">
                        <span *ngIf="f.message.errors.required">Bitte Nachricht eingeben</span>
                    </div>
                </div>
                <div class="field file">
                    <input type="file" id="file" (change)="handleFileInput($event.target.files)" />
                    <!-- <img src="assets/pdf.svg" class="pdf" alt="" *ngIf="fileUpload"> -->
                    <label for="file" *ngIf="!fileUpload">Datei anhängen</label>
                    <label for="file" *ngIf="fileUpload&&!startUpload">{{fileName}}</label>
                    <label for="file" *ngIf="startUpload">Lade Daten</label>
                </div>
                <div class="btn-wrapper">
                    <button class="classic-button" (click)="sendSidebar()">SENDEN</button>
                </div>
            </form>
            <div class="success-mess" *ngIf="formSidebarSuccess">
                Nachricht wurde erfolgreich übermittelt.
            </div>
        </div>
    </div>
</div>