import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FinanceComponent } from './finance/finance.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { PostComponent } from './post/post.component';
import { LebensereignisComponent } from './lebensereignis/lebensereignis.component';
import { LebensphaseComponent } from './lebensphase/lebensphase.component';
import { KarriereComponent } from './karriere/karriere.component';
import { AnalyseComponent } from './analyse/analyse.component';
import { WerteComponent } from './werte/werte.component';
import { AcademyComponent } from './academy/academy.component';
import { LebensphaseOverviewComponent } from './lebensphase-overview/lebensphase-overview.component';
import { LebensereignisOverviewComponent } from './lebensereignis-overview/lebensereignis-overview.component';
import { KarrierePusherComponent } from './karriere-pusher/karriere-pusher.component';
import { AboutNewComponent } from './about-new/about-new.component';
import { AgentureComponent } from './agenture/agenture.component';



const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component:HomeComponent },
  { path: 'uber-uns', component:AboutNewComponent, data: { key: '' } },
  // { path: 'uber-uns-test', component:AboutNewComponent, data: { key: '' } },
  { path: 'uber-uns/:name', component:AboutComponent, data: { key: '' } },
  { path: 'agenture/:name', component:AgentureComponent, data: { key: '' } },
  { path: 'finanzen', component:FinanceComponent, data: { key: '' } },
  { path: 'news', component:NewsComponent, data: { key: '' } },
  { path: 'kontakt', component:ContactComponent, data: { key: '' } },
  { path: 'impressum', component:ImpressumComponent, data: { key: '' } },
  { path: 'post', component:PostComponent, data: { key: '' } },
  { path: 'lebensereignis', component:LebensereignisComponent, data: { key: '' } },
  { path: 'lebensphase', component:LebensphaseComponent, data: { key: '' } },
  { path: 'lebensphase/:name', component:LebensphaseOverviewComponent, data: { key: '' } },
  { path: 'lebensereignis/:name', component:LebensereignisOverviewComponent, data: { key: '' } },
  { path: 'karriere', component:KarriereComponent, data: { key: '' } },
  { path: 'analyse', component:AnalyseComponent, data: { key: '' } },
  { path: 'werte', component:WerteComponent, data: { key: '' } },
  { path: 'academy', component:AcademyComponent, data: { key: '' } },
  { path: 'karriere-pusher', component:KarrierePusherComponent, data: { key: '' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
