<div class="container">
    <div class="title-wrapper">
        <h1>Karriere-Pusher</h1>
        <div class="line"></div>
        <p>Zeit endlich Deiner Berufung nachzukommen!</p>
    </div>
    <div class="intro-wrapper flex">
        <div class="left-side" (click)="openVideoModal('assets/landing-page/sinan.mp4')">
            <img src="assets/landing-page/video-thumbnail.jpg" alt="" class="img">
            <img src="assets/landing-page/play.svg" alt="" class="play">
        </div>
        <div class="right-side">
            <h4>Hast Du studiert und Dich stetig weitergebildet?</h4>
            <h4>Unt trotz allem wird Dir das nicht honoriert?</h4>
            <h4><b>Schluss damit! Komm zum Winner-Team und verdiene endlich das, was Dir zusteht!</b></h4>
            <h4>Sieh Dir die Möglichkeit an, um mehr aus Deinem Berufsleben zu machen!</h4>
            <div class="button-wrapper">
                <button type="button" (click)="openModalStep()">JETZT KOSTENLOSES<br> KENNENLERNGESPRÄCH
                    VEREINBAREN</button>
            </div>
        </div>
    </div>
    <div class="central-wrapper">
        <h4>Hör auf Dich aufzuregen, weil Du wenig verdienst, obwohl Dir mehr zu steht!</h4>
        <h4 class="gold">Ändere es - hier und jetzt!</h4>
        <p>Wie sieht Dein erträumtes Leben aus - und: Wie sieht zurzeit Deine Realität aus? Du weisst, dass es
            Möglichkeiten, um nach Deinen Vorstellungen hält. Fehlt Dir der richtige Partner, der Dir aufzeigt,
            wie Du
            das alles erreichen kannst?</p>
        <p>Mach Dir keinen Kopf mehr darüber. Vielen Leuten geht es genau gleich, nur Du hast bereits den Mut gehabt,
            den
            ersten Schritt zu machen. Dafür gibt es auch bestimmt einen Grund! Das Einzige was vielleicht noch fehlt,
            ist nur
            ein bisschen Orientierung und einen Wegbegleiter. Und genau diesen Karrierekompass bekommst Du!</p>
        <div class="gallery-wrapper" (window:resize)="onResize($event)">
            <img src="assets/landing-page/bild1.jpg" alt="" *ngIf="!mobile">
            <img src="assets/landing-page/bild2.jpg" alt="" *ngIf="!mobile">
            <img src="assets/landing-page/bild3.jpg" alt="" *ngIf="!mobile">
            <img src="assets/landing-page/bild4.jpg" alt="" *ngIf="!mobile">

            <swiper *ngIf="mobile" class="swiper-container" fxFlex="auto" [config]="config" [disabled]="disabled">
                <img src="assets/landing-page/bild1.jpg" alt="" class="swiper-slide">
                <img src="assets/landing-page/bild2.jpg" alt="" class="swiper-slide">
                <img src="assets/landing-page/bild3.jpg" alt="" class="swiper-slide">
                <img src="assets/landing-page/bild4.jpg" alt="" class="swiper-slide">
            </swiper>
        </div>
    </div>
    <div class="checkpoints-wrapper flex">
        <div class="left">
            <h3>Melde Dich jetzt und ...</h3>
        </div>
        <div class="right">
            <div class="checkpoint">
                <img src="assets/landing-page/ckeckmark.svg" alt="">
                <p>erfahre, wie Du Deine Traumkarriere starten kannst!</p>
            </div>
            <div class="checkpoint">
                <img src="assets/landing-page/ckeckmark.svg" alt="">
                <p>hebe Dich auf das nächste Level!</p>
            </div>
            <div class="checkpoint">
                <img src="assets/landing-page/ckeckmark.svg" alt="">
                <p>verdiene das, was Dir zusteht!</p>
            </div>
            <div class="checkpoint">
                <img src="assets/landing-page/ckeckmark.svg" alt="">
                <p>lerne Dich schnell und zielorientiert zu entscheiden!</p>
            </div>
            <div class="checkpoint">
                <img src="assets/landing-page/ckeckmark.svg" alt="">
                <p>inspiriere und zeige anderen auf, wie man zu einem Gewinner wird!</p>
            </div>
            <div class="checkpoint">
                <img src="assets/landing-page/ckeckmark.svg" alt="">
                <p>werde ein Leader!</p>
            </div>
            <div class="checkpoint">
                <img src="assets/landing-page/ckeckmark.svg" alt="">
                <p>lebe das Leben, wo von Du immer geträumt hast!</p>
            </div>
        </div>
    </div>
    <div class="card-wrapper">
        <h4>Du denkst das ist Bullshit?<br>Dies haben einige auch gedacht, bis sie nun selbst nach kurzer Zeit 5-stellig
            verdienen!</h4>
        <h3>Zudem erhältst Du...</h3>
        <div class="icon-div-wrapper" (window:resize)="onResize($event)">
            <div class="icons-wrapper" *ngIf="!mobile">
                <div class="icon-wrapper">
                    <div class="icon">
                        <img src="assets/landing-page/stats.svg" alt="">
                    </div>
                    <div class="icon-content">
                        eine komplette Aus- und Weiterbildung im Bereich Finanzen
                    </div>
                </div>
                <div class="icon-wrapper">
                    <div class="icon">
                        <img src="assets/landing-page/leadership.svg" alt="">
                    </div>
                    <div class="icon-content">
                        kompetente Ansprechpartner sowie einen persönlichen Coach
                    </div>
                </div>
                <div class="icon-wrapper">
                    <div class="icon">
                        <img src="assets/landing-page/software.svg" alt="">
                    </div>
                    <div class="icon-content">
                        Zugriff auf das innovativste und modernste Verwaltungsprogramm in dieser Branche
                    </div>
                </div>
            </div>
            <div class="icons-wrapper" *ngIf="!mobile">
                <div class="icon-wrapper">
                    <div class="icon">
                        <img src="assets/landing-page/turn.svg" alt="">
                    </div>
                    <div class="icon-content">
                        die Möglichkeit Dein Alltag flexibel zu gestalten
                    </div>
                </div>
                <div class="icon-wrapper">
                    <div class="icon">
                        <img src="assets/landing-page/plan.svg" alt="">
                    </div>
                    <div class="icon-content">
                        einen genauen Plan und Begleitung für Deine Karriere
                    </div>
                </div>
                <div class="icon-wrapper">
                    <div class="icon">
                        <img src="assets/landing-page/software.svg" alt="">
                    </div>
                    <div class="icon-content">
                        Zugang zum Bonus- sowie Beteiligungsprogramm
                    </div>
                </div>
            </div>

            <swiper *ngIf="mobile" class="swiper-container icons-wrapper" fxFlex="auto" [config]="config"
                [disabled]="disabled">
                <div class="icon-wrapper swiper-slide">
                    <div class="icon">
                        <img src="assets/landing-page/stats.svg" alt="">
                    </div>
                    <div class="icon-content">
                        eine komplette Aus- und Weiterbildung im Bereich Finanzen
                    </div>
                </div>
                <div class="icon-wrapper swiper-slide">
                    <div class="icon">
                        <img src="assets/landing-page/leadership.svg" alt="">
                    </div>
                    <div class="icon-content">
                        kompetente Ansprechpartner sowie einen persönlichen Coach
                    </div>
                </div>
                <div class="icon-wrapper swiper-slide">
                    <div class="icon">
                        <img src="assets/landing-page/software.svg" alt="">
                    </div>
                    <div class="icon-content">
                        Zugriff auf das innovativste und modernste Verwaltungsprogramm in dieser Branche
                    </div>
                </div>
                <div class="icon-wrapper swiper-slide">
                    <div class="icon">
                        <img src="assets/landing-page/turn.svg" alt="">
                    </div>
                    <div class="icon-content">
                        die Möglichkeit Dein Alltag flexibel zu gestalten
                    </div>
                </div>
                <div class="icon-wrapper swiper-slide">
                    <div class="icon">
                        <img src="assets/landing-page/plan.svg" alt="">
                    </div>
                    <div class="icon-content">
                        einen genauen Plan und Begleitung, für Deine Karriere
                    </div>
                </div>
                <div class="icon-wrapper swiper-slide">
                    <div class="icon">
                        <img src="assets/landing-page/software.svg" alt="">
                    </div>
                    <div class="icon-content">
                        Zugang zum Bonus- sowie Beteiligungsprogramm
                    </div>
                </div>
            </swiper>
        </div>
        <div class="button-wrapper">
            <button type="button" (click)="openModalStep()">JETZT KOSTENLOSES<br> KENNENLERNGESPRÄCH
                VEREINBAREN</button>
        </div>
        <h4>Also bist Du dafür geeignet?</h4>
        <h6>Klar ist, dass Du für den gewünschten Erfolg selbst verantwortlich bist. Halte somit nicht selbst nicht auf!
        </h6>
    </div>
</div>
<div class="options">
    <div class="container">
        <div class="section-wrapper">
            <div class="section-header">
                <div class="left-header">
                    geeignet für Leute die:
                </div>
                <div class="right-header">
                    NICHT geeignet für Leute die:
                </div>
            </div>
            <div class="section-content">
                <div class="left-content">
                    <p>ihre Visionen im Leben erfüllen wollen</p>
                    <p>ihre Talente nutzen und selbstbestimmt leben wollen</p>
                    <p>mehr vom Leben wollen</p>
                    <p>endlich so richtig glücklich sein wollen</p>
                </div>
                <div class="right-content">
                    <p>sich nicht bewegen und lieber zurücklehnen wollen</p>
                    <p>lieber in Selbstmitleid versinken, statt aktiv zu werden</p>
                    <p>denken, es geht ja doch nicht besser</p>
                    <p>sich aufgeben und mit dem Status Quo arrangieren</p>
                </div>
            </div>
        </div>
        
        <h4>Was nun? Triff eine Entscheidung, oder...</h4>
        <p>willst Du Dich immer noch ärgern, warum Du nicht mehr Ende Monat auf der Seite hast? Nach Möglichkeiten zu suchen, die Dir helfen endlich den gewünschten Erfolg einzufahren?
        </p>
        <p><b>Akzteptiere nicht einfach die Situation wie sie ist, frage Dich WIE Du dies nach Deinen Vorstellungen ändern kannst!</b></p>
        <p>Gib Dir selbst diese Chance und nimm Dir die Zeit dafür, um eine Entscheidung zu treffen, die vielleicht Dein Leben für immer positiv verändern wird!</p>
        <div class="button-wrapper">
            <button type="button" (click)="openModalStep()">JETZT KOSTENLOSES<br> ERSTGESPRÄCH
                VEREINBAREN</button>
        </div>
    </div>
</div>
<div class="footer">
    <div class="container">
        <div class="footer-content">
            <p (click)="openModal('terms')">Datenschutz</p>
            <p (click)="openModal('about')">Impressum</p>
            <p (click)="openModal('haftung')">AGB</p>
        </div>
    </div>
</div>
<div class="modal-wrap modal-closed" *ngIf="modalVisible">
    <div class="modal-cnt-wrap">
      <div class="modal-cnt term" [innerHtml]="modalContent" [ngClass]="{'terms-modal': termsModal}">
  
      </div>
      <div class="close-modal">
        <img src="assets/landing-page/close-modal.svg" alt="close" (click)="closeModal()">
      </div>
    </div>
  </div>
  <div class="modal-wrap modal-closed" *ngIf="videoVisible" (click)="closeVideoModal()">
      <div class="modal-cnt-wrap video-wrap">
          <div class="modal-cnt video">
              <!-- <div class="close-icon" (click)="closeModal()">
                  <img src="assets/landing-page/icon-flat-close.svg" alt="" (click)="closeModal()">
              </div> -->
              <video controls (click)="onEvent($event)" autoplay>
                  <source src="{{videoPreview}}" type="video/mp4">
                  Your browser does not support the video tag.
              </video>
          </div>
      </div>
  </div>

  <div class="modal-wrap modal-closed" *ngIf="stepsVisible" (click)="closeModalStep()">
      <div class="modal-cnt-wrap">
          <div class="modal-cnt step-cnt" (click)="onEvent($event)" [formGroup]="contactForm">
              <button class="back" *ngIf="!(firstStep||finalStep)" (click)="backwardStep()"><img
                      src="assets/landing-page/gray-arrow.svg" alt=""> Zurück</button>
              <div class="first-step" *ngIf="firstStep">
                  <div class="left-side">
                      <p>Anrede*</p>
                      <div class="inputs-wrapper">
                          <div class="input-wrapper"
                              [ngClass]="{'invalid': (f.gender.invalid && f.gender.touched) || (f.gender.invalid && formTrue)}">
                              <input type="radio" id="test1" formControlName="gender" value="Herr">
                              <label for="test1">Herr</label>
                          </div>
                          <div class="input-wrapper"
                              [ngClass]="{'invalid': (f.gender.invalid && f.gender.touched) || (f.gender.invalid && formTrue)}">
                              <input type="radio" id="test2" formControlName="gender" value="Frau">
                              <label for="test2">Frau</label>
                          </div>
                      </div>
                      <div class="input-wrapper margin-top">
                          <p>Vorname*</p>
                          <input type="text" formControlName="firstname"
                              [ngClass]="{'invalid': (f.firstname.invalid && f.firstname.touched) || (f.firstname.invalid && formTrue)}">
                      </div>
                      <div class="input-wrapper mobile-add margin-top">
                          <p>Nachname*</p>
                          <input type="text" formControlName="lastname"
                              [ngClass]="{'invalid': (f.lastname.invalid && f.lastname.touched) || (f.lastname.invalid && formTrue)}">
                      </div>
                      <div class="input-wrapper margin-top">
                          <p>E-mail*</p>
                          <input type="text" formControlName="email"
                              [ngClass]="{'invalid': (f.email.invalid && f.email.touched) || (f.email.invalid && formTrue)}">
                      </div>
                  </div>
                  <div class="right-side">
                      <div class="input-wrapper margin-top-mobile">
                          <p>Geburtstag*</p>
                          <input type="text" formControlName="date"
                              [ngClass]="{'invalid': (f.date.invalid && f.date.touched) || (f.date.invalid && formTrue)}">
                      </div>
                      <div class="input-wrapper margin-top mobile-remove">
                          <p>Nachname*</p>
                          <input type="text" formControlName="lastname"
                              [ngClass]="{'invalid': (f.lastname.invalid && f.lastname.touched) || (f.lastname.invalid && formTrue)}">
                      </div>
                      <div class="input-wrapper margin-top">
                          <p>Handynummer*</p>
                          <input type="text" formControlName="phone"
                              [ngClass]="{'invalid': (f.phone.invalid && f.phone.touched) || (f.phone.invalid && formTrue)}">
                      </div>
                  </div>
              </div>
              <div class="second-step" *ngIf="secondStep">
                  <h4>Jetzt kommen ein paar Fragen über Dich, damit wir uns für Dich vorbereiten können.</h4>
                  <div class="check-wrappers">
                      <div class="check" [ngClass]="{'active': secondStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                  </div>
                  <div class="content-wrapper block">
                      <div class="full-wrap">
                          <div class="input-wrapper margin-top half">
                              <p>Ort</p>
                              <input type="text" formControlName="ort">
                          </div>
                          <div class="input-wrapper margin-top half">
                              <p>PLZ</p>
                              <input type="text" formControlName="plz">
                          </div>
                      </div>
                      <div class="full-wrap">
                          <div class="input-wrapper margin-top half">
                              <p>Adresse</p>
                              <input type="text" formControlName="address">
                          </div>
                          <div class="input-wrapper margin-top half">
                              <p>Land</p>
                              <input type="text" formControlName="land">
                          </div>
                      </div>
                  </div>
                  <div class="button-wrapper">
                      <button type="button" (click)="nextStep('third')">WEITER</button>
                  </div>
              </div>
              <div class="third-step" *ngIf="thirdStep">
                  <div class="check-wrappers">
                      <div class="check" [ngClass]="{'success': thirdStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'active': thirdStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                  </div>
                  <div class="content-wrapper">
                      <div class="full-side">
                          <div class="input-wrapper">
                              <p>Was machst Du zurzeit beruflich?</p>
                              <textarea name="" id="" cols="40" rows="15" formControlName="questionOne"></textarea>
                          </div>
                      </div>
                  </div>
                  <div class="button-wrapper">
                      <button type="button" (click)="nextStep('fourth')">WEITER</button>
                  </div>
              </div>
              <div class="third-step" *ngIf="fourthStep">
                  <div class="check-wrappers">
                      <div class="check" [ngClass]="{'success': fourthStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'success': fourthStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'active': fourthStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                  </div>
                  <div class="content-wrapper">
                      <div class="full-side">
                          <div class="input-wrapper">
                              <p>Gibt es etwas, dass Du unbedingt erreichen möchtest?</p>
                              <textarea name="" id="" cols="40" rows="15" formControlName="questionTwo"></textarea>
                          </div>
                      </div>
                  </div>
                  <div class="button-wrapper">
                      <button type="button" (click)="nextStep('fifth')">WEITER</button>
                  </div>
              </div>
              <div class="third-step" *ngIf="fifthStep">
                  <div class="check-wrappers">
                      <div class="check" [ngClass]="{'success': fifthStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'success': fifthStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'success': fifthStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'active': fifthStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check">
                          <div class="red"></div>
                      </div>
                  </div>
                  <div class="content-wrapper">
                      <div class="full-side">
                          <div class="input-wrapper">
                              <p>Was hat Dich bewegt, Dich anzumelden?</p>
                              <textarea name="" id="" cols="40" rows="15" formControlName="questionThree"></textarea>
                          </div>
                      </div>
                  </div>
                  <div class="button-wrapper">
                      <button type="button" (click)="nextStep('seventh')">WEITER</button>
                  </div>
              </div>
              <div class="seven-step" *ngIf="seventhStep">
                  <div class="check-wrappers">
                      <div class="check" [ngClass]="{'success': seventhStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'success': seventhStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'success': seventhStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'success': seventhStep}">
                          <div class="red"></div>
                      </div>
                      <div class="check" [ngClass]="{'active': seventhStep}">
                          <div class="red"></div>
                      </div>
                  </div>
                  <div class="content-wrapper">
                      <div class="margin-side">
                          <div class="input-wrapper">
                              <p>Wann bist Du am besten erreichbar?</p>
                              <div class="time" [ngClass]="{'active': morning}" (click)="checkTime('morning')">
                                  Morgen 08:00 - 12:00
                              </div>
                              <div class="time" [ngClass]="{'active': afternoon}" (click)="checkTime('afternoon')">
                                  Nachmittag 12:00 - 16:00
                              </div>
                              <div class="time" [ngClass]="{'active': evening}" (click)="checkTime('evening')">
                                  Abend 16:00 - 19:00
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="button-wrapper success">
                      <button type="button" (click)="nextStep('final'); send()">ERLEDIGT</button>
                  </div>
              </div>
              <div class="final-step" *ngIf="finalStep">
                  <div class="content-wrapper">
                      <div class="margin-side">
                          <img src="assets/landing-page/green-check.svg" alt="">
                          <h4>Danke für Deine Anmeldung!</h4>
                      </div>
                  </div>
              </div>
              <div class="button-wrapper" *ngIf="firstStep">
                  <button type="button" (click)="nextStep('second')">LOS GEHT'S</button>
              </div>
          </div>
      </div>
  </div>