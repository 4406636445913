import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private apiUrl = environment.apiUrl
  private apiUrlLandingPage = 'https://contact.nextgen.ag/contact'
  private apiUrlBA = 'https://www.brokeradmin.ch/api/v1';

  constructor(private _httpClient: HttpClient) { }


  sendMail(data): Promise<any[]> {
    console.log([...data])
    const headers = { 'enctype': 'multipart/form-data' };
    return this._httpClient.post<any[]>(`${this.apiUrl}/mail`,data,{headers}).toPromise()
  }

  sendMailLandingPage(data): Promise<any[]> {
    return this._httpClient.post<any[]>(`${this.apiUrlLandingPage}`,data).toPromise()
  }
  
  getAllEmployers(): Promise<any> {
    return this._httpClient.get<any[]>(`${this.apiUrlBA}/users/ssm/employers`).toPromise()
  }

}