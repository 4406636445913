<div class="main-wrapper">
    <div class="intro-default">
        <div class="container">
            <div class="image-wrapper">
                <img src="assets/maska.svg" alt="">
            </div>
        </div>
    </div>
    <div class="container">
        <h1 class="title-page">Analyse</h1>
        <div class="main">
            <div class="section">
                <h2>Die ganzheitliche Analyse für Ihre Finanzen</h2>
                <p>Was möchten Sie erreichen? Diese Frage steht bei unserer ganzheitlichen Finanzanalyse im Mittelpunkt.
                    Für uns zählen nicht einzelne Anlagemöglichkeiten und Angebote. Im Mittelpunkt stehen vielmehr Sie
                    als Mensch mit Ihren ganz eigenen Vorstellungen und Zielen. Unsere Rundum-Analyse baut daher auf
                    Ihren persönlichen Wünschen auf. Gemeinsam mit Ihnen entwickeln wir auf dieser Basis Ihre
                    Finanzplanung und finden ein massgeschneidertes Lösungsangebot für Ihre Situation.</p>
            </div>
            <div class="tabordion">
                <section id="section2" #sectionTwo>
                    <label id="option2" (click)="showtab('secondTabState')"><span
                            [ngClass]="{rotate : states.secondTabState=='open'}">+</span>
                        <p>Welche finanziellen Ziele möchten Sie erreichen?</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.secondTabState>
                        <article>
                            <p>Der Finanzmarkt ist komplex. Angesichts der zahlreichen Möglichkeiten verlieren Laien
                                schnell den Überblick. Wie legen Sie Ihr Geld am besten an? Wie sorgen Sie ideal für Ihr
                                Leben vor? Wir helfen Ihnen, Antworten auf diese Fragen zu finden. Den Ausgangspunkt
                                bildet dabei eine Analyse Ihrer finanziellen Ziele. Welche Wünsche haben Sie? Was
                                möchten Sie konkret erreichen?</p>
                            <p>Die persönlichen Bedürfnisse verändern sich im Laufe eines Lebens. Beginnen Sie gerade
                                eine Ausbildung oder ein Studium, sind Ihnen andere Dinge wichtig, als wenn Sie sich
                                selbstständig machen möchten oder im Begriff sind, eine Familie zu gründen. Neben Ihrer
                                aktuellen Situation beziehen wir auch Ihre langfristigen Wünsche in die Finanzplanung
                                ein. Träumen Sie vom Leben in einem eigenen Haus mit grossem Garten? Möchten Sie reisen
                                und die Welt kennen lernen? Wollen Sie Karriere machen oder sich auf die Familie
                                konzentrieren? Welche Vorsorge- und Anlagemöglichkeiten für Sie die richtigen sind,
                                hängt zudem auch von Ihrer Persönlichkeit ab. Legen Sie grossen Wert auf Sicherheit oder
                                gehen Sie auch mal ein Risiko ein? </p>
                        </article>
                    </div>
                </section>
                <section id="section3" #sectionThree>
                    <label id="option3" (click)="showtab('thirdTabState')"><span
                            [ngClass]="{rotate : states.thirdTabState=='open'}">+</span>
                        <p>Wir überlassen Ihre Finanzen nicht dem Zufall!</p>
                    </label>
                    <div class="article-wrapper" [@openClose]=states.thirdTabState>
                        <article>
                            <p>Um die Beratung unserer Kunden kümmern sich bei der SSM Partner AG produktunabhängige
                                Finanzcoaches. Statt uns auf die Produkte eines Anbieters zu konzentrieren, haben wir
                                den Überblick über den gesamten Markt. So können wir für Sie die Angebote auswählen, die
                                tatsächlich am besten zu Ihnen und Ihren Wünschen passen.</p>
                            <p>Gemeinsam mit Ihnen analysieren unsere Finanzcoaches Ihre finanzielle Ausgangssituation.
                                Wir zeigen, wie Sie Fixkosten reduzieren und Geld zur Seite legen. Ausgehend von Ihren
                                Wünschen finden wir passende Vorsorgemöglichkeiten und Versicherungen für Sie und Ihre
                                Familie. Ob Sie auf ein Eigenheim, ein neues Auto oder einen Urlaub sparen, wir stellen
                                Ihnen geeignete Anlageoptionen vor. Für jeden Wunsch erhalten Sie ein massgeschneidertes
                                Lösungsangebot.</p>
                            <p>Wünschen Sie eine umfassende und persönliche Finanzberatung? Dann nehmen Sie gleich
                                Kontakt zu uns auf!</p>
                        </article>
                    </div>
                </section>
            </div>
        </div>

        <div class="sidebar">
            <h2>Informationen anfordern</h2>
            <form class="form-wrapper" [formGroup]="financeForm" action="" *ngIf="!formSidebarSuccess">
                <div class="field">
                    <input formControlName="vorname" type="text" placeholder="Vorname"
                        [ngClass]="{'invalid': (f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.vorname.invalid && f.vorname.touched) || (f.vorname.invalid && financeTrue)">
                        <span *ngIf="f.vorname.errors.required">Bitte Vorname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="nachname" type="text" placeholder="Nachname"
                        [ngClass]="{'invalid': (f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.nachname.invalid && f.nachname.touched) || (f.nachname.invalid && financeTrue)">
                        <span *ngIf="f.nachname.errors.required">Bitte Nachname angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="email" type="text" placeholder="E-Mail"
                        [ngClass]="{'invalid': (f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.email.invalid && f.email.touched) || (f.email.invalid && financeTrue)">
                        <span *ngIf="f.email.errors.required">Bitte Email angeben</span>
                    </div>
                </div>
                <div class="field">
                    <input formControlName="telefon" type="text" placeholder="Telefon"
                        [ngClass]="{'invalid': (f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)}">
                    <div class="error-msg"
                        *ngIf="(f.telefon.invalid && f.telefon.touched) || (f.telefon.invalid && financeTrue)">
                        <span *ngIf="f.telefon.errors.required">Bitte Telefon angeben</span>
                    </div>
                </div>
                <div class="checkbox-wrapper">
                    <div class="row">
                        <label class="checkbox">Krankenkasse
                            <input type="checkbox">
                            <span class="checkmark" #krankenkasse
                                (click)="selectType('Krankenkasse', krankenkasse)"></span>
                        </label>
                        <label class="checkbox">Sachversicherung
                            <input type="checkbox">
                            <span class="checkmark" #sachversicherung
                                (click)="selectType('Sachversicherung', sachversicherung)"></span>
                        </label>
                    </div>
                    <div class="row">
                        <label class="checkbox">Unfallversicherung
                            <input type="checkbox">
                            <span class="checkmark" #unfallversicherung
                                (click)="selectType('Unfallversicherung', unfallversicherung)"></span>
                        </label>
                        <label class="checkbox">Vorsorge
                            <input type="checkbox">
                            <span class="checkmark" #vorsorge (click)="selectType('Vorsorge', vorsorge)"></span>
                        </label>
                    </div>
                </div>
                <div class="field area">
                    <textarea formControlName="message" cdkAutosizeMinRows="110" placeholder="Nachricht"
                        cdkAutosizeMaxRows="120"
                        [ngClass]="{'invalid': (f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)}"></textarea>
                    <div class="error-msg"
                        *ngIf="(f.message.invalid && f.message.touched) || (f.message.invalid && financeTrue)">
                        <span *ngIf="f.message.errors.required">Bitte Nachricht eingeben</span>
                    </div>
                </div>
                <div class="btn-wrapper">
                    <button class="classic-button" (click)="sendSidebar()">SENDEN</button>
                </div>
            </form>
            <div class="success-mess" *ngIf="formSidebarSuccess">
                Nachricht wurde erfolgreich übermittelt.
            </div>
        </div>
    </div>
</div>