import { Component, OnInit } from '@angular/core';
import { ScrollService } from 'src/app/services/scroll.service';

@Component({
  selector: 'app-lebensphase',
  templateUrl: './lebensphase.component.html',
  styleUrls: ['./lebensphase.component.scss']
})
export class LebensphaseComponent implements OnInit {

  constructor(private scroll: ScrollService) { }

  ngOnInit(): void {
    this.scroll.setScroll()
  }

}
