<div class="main-wrapper">
    <div class="intro-default">
        <div class="container">
            <div class="section-default">
                <h2>Unser Team</h2>
            </div>
            <div class="image-wrapper">
                <!-- <img src="assets/maska.svg" alt=""> -->
            </div>
        </div>
    </div>
    <div class="container">
        <div class="tab">
            <button class="tablinks" (click)="changeAffiliate('main')" [ngClass]="{'active': main}">Hauptsitz</button>
            <button class="tablinks" (click)="changeAffiliate('pfungen')" [ngClass]="{'active': pfungen}">Pfungen</button>
            <button class="tablinks" (click)="changeAffiliate('regensdorf')" [ngClass]="{'active': regensdorf}">Buchs</button>
            <button class="tablinks" (click)="changeAffiliate('bern')" [ngClass]="{'active': bern}">Bern</button>
            <button class="tablinks" (click)="changeAffiliate('luzern')" [ngClass]="{'active': luzern}">Lausanne</button>
            <button class="tablinks" (click)="changeAffiliate('safenwil')" [ngClass]="{'active': safenwil}">Olten</button>
            <button class="tablinks" (click)="changeAffiliate('gallen')" [ngClass]="{'active': gallen}">St. Gallen</button>
            <button class="tablinks" (click)="changeAffiliate('ticino')" [ngClass]="{'active': ticino}">Ticino</button>
            <button class="tablinks" (click)="changeAffiliate('zoffingen')" [ngClass]="{'active': zoffingen}">Zofingen</button>
            <button class="tablinks" (click)="changeAffiliate('zurich')" [ngClass]="{'active': zurich}">Zürich Süd</button>
            <button class="tablinks" (click)="changeAffiliate('nord')" [ngClass]="{'active': nord}">Zürich Nord</button>
            <button class="tablinks" (click)="changeAffiliate('zug')" [ngClass]="{'active': zug}">Zug</button>
          </div>
        <div class="tabordion" *ngIf="main">
            <div class="address">
                <div class="address-left">
                    <h3>SSM Partner AG</h3>
                    <h3>Stationsstrasse 92</h3>
                    <h3>6023 Rothenburg</h3>
                </div>
                <div class="address-left">
                    <h3>Tel. <a href="{{'tel:0412202050'}}">+41 41 220 20 50</a></h3>
                    <h3>Email: <a href="mailto:info@ssmpartner.ch">info@ssmpartner.ch</a></h3>
                </div>
            </div>
            <section id="section1" #sectionOne>
                <label id="option1" (click)="showtab('firstTabState')"><span
                        [ngClass]="{rotate : states.firstTabState=='open'}">+</span>
                    <p>Geschäftsleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.firstTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/artan.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #artan (click)="openInfo(artan)">
                                    <h2>Artan Selmanaj <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>HUMAN RESOURCES</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:as@ssmpartner.ch">as@ssmpartner.ch </a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/davide.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #davide (click)="openInfo(davide)">
                                    <h2>Davide Spadea <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>VERTRIEBSLEITER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ds@ssmpartner.ch">ds@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card"></div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section>
            <section id="section2" #sectionTwo>
                <label id="option2" (click)="showtab('secondTabState')"><span
                        [ngClass]="{rotate : states.secondTabState=='open'}">+</span>
                    <p>Kader</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.secondTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/azra.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #azra (click)="openInfo(azra)">
                                    <h2>Azra Sabotic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-m-new">CONTROLLING & PROZESSE VERMITTLERBETREUERIN</h5>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:azs@ssmpartner.ch">azs@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/florent.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #florent (click)="openInfo(florent)">
                                    <h2>Florent Tahiraj <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>LEITER AUSSENDIENST</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ft@ssmpartner.ch">ft@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/gerardo2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #gerardo (click)="openInfo(gerardo)">
                                    <h2>Gerardo Rosamilia <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KEY ACCOUNT MANAGER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gr@ssmpartner.ch">gr@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/manuel.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #manuelTwo (click)="openInfo(manuelTwo)">
                                    <h2 class="short-h">Manuel Gomes <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-m">LEITER VORSORGE UND AUSBILDUNG</h5>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:tc@ssmpartner.ch">mg@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/manuel.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #manuel (click)="openInfo(manuel)">
                                    <h2 class="short-h">Manuel Gomes <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-m">LEITER VORSORGE UND AUSBILDUNG</h5>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:tc@ssmpartner.ch">mg@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/pero.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #pero (click)="openInfo(pero)">
                                    <h2>Pero Janjic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-m-new">{{'Leiter Corporate IT & Digitalisierung' | uppercase}}</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:pero@ssmpartner.ch">pero@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/sinan.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sinan (click)="openInfo(sinan)">
                                    <h2>Sinan Bulica <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>VERKAUFSLEITER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sb@ssmpartner.ch">sb@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="newSection" #sectionNew>
                <label id="optionNew" (click)="showtab('newTabState')"><span
                        [ngClass]="{rotate : states.newTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ardrit.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ardrit (click)="openInfo(ardrit)">
                                    <h2>Ardrit Rushiti <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ar@ssmpartner.ch">ar@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/betrim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #betrim (click)="openInfo(betrim)">
                                    <h2>Betrim Shabani <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:bes@ssmpartner.ch">bes@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/tiago.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #tiago (click)="openInfo(tiago)">
                                    <h2 class="short-h">Tiago Costa Albino <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-m">TEAMLEITER</h5>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:tc@ssmpartner.ch">tc@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper short-p">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/tarzan.png" alt="">
                                </div>
                                <div class="info-wrapper" #tharsan (click)="openInfo(tharsan)">
                                    <h2>Tharsan Ganesh <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gth@ssmpartner.ch">gth@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section>
            <section id="newSectionBuch" #sectionNewBucg>
                <label id="optionNewBuch" (click)="showtab('newTabStateBuch')"><span
                        [ngClass]="{rotate : states.newTabStateBuch=='open'}">+</span>
                    <p>Buchhaltung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTabStateBuch>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dorner.png" alt="">
                                </div>
                                <div class="info-wrapper" #dorner (click)="openInfo(dorner)">
                                    <h2 class="short-h">Kamila Dorner <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZWESEN</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:kamila.dorner@ssmpartner.ch">kamila.dorner@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                        <div class="cleaner"></div>
                    </article>
                </div>
            </section>
            <section id="section910" #sectionNineTen>
                <label id="option910" (click)="showtab('nineTenTabState')"><span
                        [ngClass]="{rotate : states.nineTenTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.nineTenTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/anesa.png" alt="">
                                </div>
                                <div class="info-wrapper" #anesa (click)="openInfo(anesa)">
                                    <h2>Anesa Seferovic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-position">{{ 'Innendienst' | uppercase }}</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p class="short-email"><a href="mailto:anesa.seferovic@ssmpartner.ch">anesa.seferovic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/clirim.png" alt="">
                                </div>
                                <div class="info-wrapper" #clirim (click)="openInfo(clirim)">
                                    <h2>Clirim Shabani <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="vl:clirim.shabani@ssmpartner.ch">clirim.shabani@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dominik.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #dominik (click)="openInfo(dominik)">
                                    <h2>Dominik Kabashi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>SALES SUPPORT</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:dk@ssmpartner.ch">dk@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/djellona.png" alt="">
                                </div>
                                <div class="info-wrapper" #djellona (click)="openInfo(djellona)">
                                    <h2>Djellona Hajrullahu <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>ADMINISTRATION</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:djellona.hajrullahu@ssmpartner.ch">djellona.hajrullahu@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/djellona.png" alt="">
                                </div>
                                <div class="info-wrapper" #djellonaTwo (click)="openInfo(djellonaTwo)">
                                    <h2>Djellona Hajrullahu <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>ADMINISTRATION</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:djellona.hajrullahu@ssmpartner.ch">djellona.hajrullahu@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/melani.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #melani (click)="openInfo(melani)">
                                    <h2 class="short-h">Melani Janosevic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mj@ssmpartner.ch">mj@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/vivian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vivian (click)="openInfo(vivian)">
                                    <h2>Vivian Delle Fave <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 class="short-position">LEITERIN ANTRAGSVERARBEITUNG</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="vl:rh@ssmpartner.ch">vl@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <!-- <section id="section3" #sectionThree>
                <label id="option3" (click)="showtab('thirdTabState')"><span
                        [ngClass]="{rotate : states.thirdTabState=='open'}">+</span>
                    <p>Antragsverarbeitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.thirdTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/vivian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vivian (click)="openInfo(vivian)">
                                    <h2>Vivian Delle Fave <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>LEITERIN ANTRAGSVERARBEITUNG</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="vl:rh@ssmpartner.ch">vl@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">

                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <!-- <section id="section5" #sectionFive>
                <label id="option5" (click)="showtab('fifthTabState')"><span
                        [ngClass]="{rotate : states.fifthTabState=='open'}">+</span>
                    <p>Sach- & Lebensversicherungen</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <!-- <section id="section10" #section10>
                <label id="option5" (click)="showtab('tenTabState')"><span
                        [ngClass]="{rotate : states.tenTabState=='open'}">+</span>
                    <p>Office Management</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.tenTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <section id="section7" #sectionSeven>
                <label id="option7" (click)="showtab('seventhTabState')"><span
                        [ngClass]="{rotate : states.seventhTabState =='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.seventhTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alban.png" alt="">
                                </div>
                                <div class="info-wrapper" #albanTwo (click)="openInfo(albanTwo)">
                                    <h2>Alban Kuci <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:alban.kuci@ssmpartner.ch">alban.kuci@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/amini.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #amini (click)="openInfo(amini)">
                                    <h2>Amini Suliman <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:as@ssmpartner.ch">as@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/arber.png" alt="">
                                </div>
                                <div class="info-wrapper" #arber (click)="openInfo(arber)">
                                    <h2 class="short-h new">Arber Hoxha <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:arh@ssmpartner.ch">arh@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/ardian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ardian (click)="openInfo(ardian)">
                                    <h2 class="short-h new">Ardian Krasniqi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ark@ssmpartner.ch">ark@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/ardian.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ardianTwo (click)="openInfo(ardianTwo)">
                                    <h2 class="short-h new">Ardian Krasniqi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ark@ssmpartner.ch">ark@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/baja.png" alt="">
                                </div>
                                <div class="info-wrapper" #baja (click)="openInfo(baja)">
                                    <h2>Baja Krizevac <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:baja.krizevac@ssmpartner.ch">baja.krizevac@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/besir.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #besir (click)="openInfo(besir)">
                                    <h2 class="short-h new">Besir Kastrati <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:besir.kastrati@ssmpartner.ch">besir.kastrati@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/diogo2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #diogo (click)="openInfo(diogo)">
                                    <h2 class="short-h new">Diogo Da Silva Baptista <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:dsb@ssmpartner.ch">dsb@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/duzmani.png" alt="">
                                </div>
                                <div class="info-wrapper" #duzmani (click)="openInfo(duzmani)">
                                    <h2>Emanuel Duzmani<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper short-l-m">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a
                                                    href="mailto:emanuel.duzmani@ssmpartner.ch">emanuel.duzmani@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/francesco.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #francesco (click)="openInfo(francesco)">
                                    <h2>Francesco Curcio<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper short-l-m">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a
                                                    href="mailto:francesco.curcio@ssmpartner.ch">francesco.curcio@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/ibrahim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ibrahim (click)="openInfo(ibrahim)">
                                    <h2>Ibrahim Onac <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:io@ssmpartner.ch">io@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/ibrahim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ibrahimTwoo (click)="openInfo(ibrahimTwoo)">
                                    <h2>Ibrahim Onac <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:io@ssmpartner.ch">io@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/laska.png" alt="">
                                </div>
                                <div class="info-wrapper" #laskaTwo (click)="openInfo(laskaTwo)">
                                    <h2>Laska Robert<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:laska.robert@ssmpartner.ch">laska.robert@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/leotrim2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #leotrimTwo (click)="openInfo(leotrimTwo)">
                                    <h2>Leotrim Selmanaj<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:les@ssmpartner.ch">les@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sara2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #saraTwo (click)="openInfo(saraTwo)">
                                    <h2>Sara Junker <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATERIN</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sasj@ssmpartner.ch">sasj@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/silviu.png" alt="">
                                </div>
                                <div class="info-wrapper" #silviu (click)="openInfo(silviu)">
                                    <h2 class="short-h new">Silviu Mihai Toma <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:silviu.toma@ssmpartner.ch">silviu.toma@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sergio (click)="openInfo(sergio)">
                                    <h2>Sergio Linares <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sergio.linares@ssmpartner.ch">sergio.linares@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card" style="float: left;">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sergioTwo (click)="openInfo(sergioTwo)">
                                    <h2>Sergio Linares <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sergio.linares@ssmpartner.ch">sergio.linares@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card" style="float: left; margin-left:4%;">
                                <div class="img-wrapper">
                                    <img src="assets/vincenzo.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vincenzo (click)="openInfo(vincenzo)">
                                    <h2>Vincenzo Colucci <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:cv@ssmpartner.ch">cv@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/vincenzo.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #vincenzoTwo (click)="openInfo(vincenzoTwo)">
                                    <h2>Vincenzo Colucci <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:cv@ssmpartner.ch">cv@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card tc-card">
                            </div>
                        </div>
                        <!-- <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mathias.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #mathias (click)="openInfo(mathias)">
                                    <h2>Mathias Cukic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mathias.cukic@ssmpartner.ch">mathias.cukic@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mauro.png" alt="">
                                </div>
                                <div class="info-wrapper" #mauro (click)="openInfo(mauro)">
                                    <h2>Mauro Caruso <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mauro.caruso@ssmpartner.ch">mauro.caruso@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sara2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #saraTwo (click)="openInfo(saraTwo)">
                                    <h2>Sara Junker <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATERIN</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ose@ssmpartner.ch">sasj@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/sergio.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sergio (click)="openInfo(sergio)">
                                    <h2>Sergio Linares <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sergio.linares@ssmpartner.ch">sergio.linares@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </article>
                </div>
            </section>
            <section id="section8" #sectionEight>
                <label id="option8" (click)="showtab('eightTabState')"><span
                        [ngClass]="{rotate : states.eightTabState=='open'}">+</span>
                    <p>Lernende/Praktikanten</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/fiona.png" alt="">
                                </div>
                                <div class="info-wrapper" #giorgia (click)="openInfo(giorgia)">
                                    <h2>Fiona Ferataj <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>Lernende/Praktikanten</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:fiona.ferataj@ssmpartner.ch">fiona.ferataj@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/giorgia.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #giorgia (click)="openInfo(giorgia)">
                                    <h2>Giorgia Raspa <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>LEHRLING</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ger@ssmpartner.ch">ger@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div class="tabordion" *ngIf="zurich">
            <div class="address">
                <div class="address-left">
                    <h3>SSM Partner AG GA Zürich Süd</h3>
                    <h3>Soodstrasse 52</h3>
                    <h3>8134 Adliswil</h3>
                </div>
                <div class="address-left">
                    <h3>Tel. <a href="{{'tel:044440008'}}">+41 44 440 00 08</a></h3>
                    <h3>Email: <a href="mailto:zh-sud@ssmpartner.ch">zh-sud@ssmpartner.ch</a></h3>
                </div>
            </div>
            
            <section id="section15" #sectionOneFive>
                <label id="option15" (click)="showtab('fifthOneTabState')"><span
                        [ngClass]="{rotate : states.fifthOneTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/andre2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #andre (click)="openInfo(andre)">
                                    <h2>André Felix<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>GENERALAGENT</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:felix@ssmpartner.ch">felix@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/luciano.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #luciano (click)="openInfo(luciano)">
                                    <h2>Luciano Gutiérrez<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>GENERALAGENT</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gutierrez@ssmpartner.ch">gutierrez@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section16" #sectionSixOne>
                <label id="option16" (click)="showtab('fifthTwoTabState')"><span
                        [ngClass]="{rotate : states.fifthTwoTabState=='open'}">+</span>
                    <p>Verkaufsleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/rade.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #rade (click)="openInfo(rade)">
                                    <h2>Radivoje Djuric <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>VERKAUFSLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:djuric@ssmpartner.ch">djuric@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <!-- <section id="section17" #sectionSevenOne>
                <label id="option16" (click)="showtab('fifthThreeTabState')"><span
                        [ngClass]="{rotate : states.fifthThreeTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/raphael2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #raphael (click)="openInfo(raphael)">
                                    <h2 class="short-h">Raphael Nick Zumsteg <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:zumsteg@ssmpartner.ch">zumsteg@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <section id="section18" #sectionEightOne>
                <label id="option18" (click)="showtab('fifthFourTabState')"><span
                        [ngClass]="{rotate : states.fifthFourTabState=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthFourTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/aron2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #aron (click)="openInfo(aron)">
                                    <h2>Aron Berardi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:berardi@ssmpartner.ch">berardi@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/kurz.png" alt="">
                                </div>
                                <div class="info-wrapper" #kurz (click)="openInfo(kurz)">
                                    <h2>Daniel Kurz <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:daniel.kurz@ssmpartner.ch">daniel.kurz@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ablanalp.png" alt="">
                                </div>
                                <div class="info-wrapper" #abplanalp (click)="openInfo(abplanalp)">
                                    <h2>Isabelle Abplanalp <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:isabelle.abplanalp@ssmpartner.ch">isabelle.abplanalp@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/stanzione.png" alt="">
                                </div>
                                <div class="info-wrapper" #stanzione (click)="openInfo(stanzione)">
                                    <h2 class="short-h new">Gennaro Stanzione <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gennaro.stanzione@ssmpartner.ch">gennaro.stanzione@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/stanzione.png" alt="">
                                </div>
                                <div class="info-wrapper" #stanzioneTwo (click)="openInfo(stanzioneTwo)">
                                    <h2 class="short-h new">Gennaro Stanzione <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gennaro.stanzione@ssmpartner.ch">gennaro.stanzione@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/lejla.png" alt="">
                                </div>
                                <div class="info-wrapper" #lejlaTwo (click)="openInfo(lejlaTwo)">
                                    <h2 class="short-h new">Lejla Kryeziu <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATERIN</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:lejla.kryeziu@ssmpartner.ch">lejla.kryeziu@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/lludmila.png" alt="">
                                </div>
                                <div class="info-wrapper" #liudmilaTwo (click)="openInfo(liudmilaTwo)">
                                    <h2 class="short-h new">Liudmila Zimmermann <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>KUNDENBERATERIN</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:zimmermann@ssmpartner.ch">zimmermann@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">

                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mehmet2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #mehmetTwo (click)="openInfo(mehmetTwo)">
                                    <h2>Mehmet Ünlütepe <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:unlutepe@ssmpartner.ch">unlutepe@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/rebeka2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #rebeka (click)="openInfo(rebeka)">
                                    <h2>Rebeka Plohl <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:rebeka.plohl@ssmpartner.ch">rebeka.plohl@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section19" #sectionNineOne>
                <label id="option19" (click)="showtab('fifthFiveTabState')"><span
                        [ngClass]="{rotate : states.fifthFiveTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthFiveTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/raffaele2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #raffaele (click)="openInfo(raffaele)">
                                    <h2>Raffaele Oppido <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:oppido@ssmpartner.ch">oppido@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div class="tabordion" *ngIf="gallen">
            <div class="address">
                <div class="address-left">
                    <h3>SSM Partner AG GA St.Gallen</h3>
                    <h3>Im Stadtwald 5</h3>
                    <h3>9400 Rorschach</h3>
                </div>
                <div class="address-left">
                    <h3>Tel. <a href="{{'tel:0718400224'}}">+41 71 840 02 24</a></h3>
                    <h3>Tel. <a href="{{'tel:0718400204'}}">+41 71 840 02 04</a></h3>
                    <h3>Email: <a href="mailto:stgallen@ssmpartner.ch">stgallen@ssmpartner.ch</a></h3>
                </div>
            </div>
            
            <section id="section20" #sectionTwenty>
                <label id="option20" (click)="showtab('fifthSixTabState')"><span
                        [ngClass]="{rotate : states.fifthSixTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.fifthSixTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/miki2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #miki (click)="openInfo(miki)">
                                    <h2>Miroslav Stevic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>GENERALAGENT</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:miroslav.stevic@ssmpartner.ch">miroslav.stevic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <!-- <section id="section170" #sectionSevenOneZero>
                <label id="option16" (click)="showtab('sevenZeroTabState')"><span
                        [ngClass]="{rotate : states.sevenZeroTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sevenZeroTabState>
                    <article>
                        <div class="cards-wrapper">
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">

                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <section id="section190" #sectionNineOneZero>
                <label id="option16" (click)="showtab('nineZeroTabState')"><span
                        [ngClass]="{rotate : states.nineZeroTabState=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.nineZeroTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alban2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #alban (click)="openInfo(alban)">
                                    <h2 class="short-h">Alban Emini <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:alban.emini@ssmpartner.ch">alban.emini@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/niciforovic.png" alt="">
                                </div>
                                <div class="info-wrapper" #niciforovic (click)="openInfo(niciforovic)">
                                    <h2 class="short-h">Ana Niciforovic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ana.niciforovic@ssmpartner.ch">ana.niciforovic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/bykov.png" alt="">
                                </div>
                                <div class="info-wrapper" #bykov (click)="openInfo(bykov)">
                                    <h2 class="short-h">Dominik Bykov <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:dominik.bykov@ssmpartner.ch">dominik.bykov@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/ilijaz2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ilijazTwo (click)="openInfo(ilijazTwo)">
                                    <h2 class="short-h">Ilijaz Alijagic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ilijaz.alijagic@ssmpartner.ch">ilijaz.alijagic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ilijaz2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #ilijaz (click)="openInfo(ilijaz)">
                                    <h2 class="short-h">Ilijaz Alijagic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ilijaz.alijagic@ssmpartner.ch">ilijaz.alijagic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/hofner.png" alt="">
                                </div>
                                <div class="info-wrapper" #hofner (click)="openInfo(hofner)">
                                    <h2 class="short-h">Hafner Simon <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:hafner.simon@ssmpartner.ch">hafner.simon@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/milijana.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #milijana (click)="openInfo(milijana)">
                                    <h2 class="short-h">Milijana Saric <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:milijana.saric@ssmpartner.ch">milijana.saric@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/ole.png" alt="">
                                </div>
                                <div class="info-wrapper" #ole (click)="openInfo(ole)">
                                    <h2 class="short-h">Ole Wüste <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ole.wuste@ssmpartner.ch">ole.wuste@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/ole.png" alt="">
                                </div>
                                <div class="info-wrapper" #oleTwo (click)="openInfo(oleTwo)">
                                    <h2 class="short-h">Ole Wüste <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ole.wuste@ssmpartner.ch">ole.wuste@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ranko.png" alt="">
                                </div>
                                <div class="info-wrapper" #ranko (click)="openInfo(ranko)">
                                    <h2 class="short-h">Ranko Antunovic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ranko.antunovic@ssmpartner.ch">ranko.antunovic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/reka.png" alt="">
                                </div>
                                <div class="info-wrapper" #reka (click)="openInfo(reka)">
                                    <h2 class="short-h">Réka Berkics <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:reka.berkics@ssmpartner.ch">reka.berkics@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0718400224'}}" class="phone">+41 71 840 02 24</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section180" #sectionEightOneZero>
                <label id="option16" (click)="showtab('eightZeroTabState')"><span
                        [ngClass]="{rotate : states.eightZeroTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightZeroTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/veranda.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #verandaTwo (click)="openInfo(verandaTwo)">
                                    <h2>Veranda Nasufi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:vn@ssmpartner.ch">vn@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>
        
        <div class="tabordion" *ngIf="nord">
            <div class="address">
                <div class="address-left">
                    <h3>SSM Partner AG GA Zürich Nord</h3>
                    <h3>Europastrasse 31</h3>
                    <h3>8152 Glattbrugg</h3>
                </div>
                <div class="address-left">
                    <h3>Tel. <a href="{{'tel:0415002849'}}">+41 43 541 37 90</a></h3>
                    <h3>Email: <a href="mailto:zh-nord@ssmpartner.ch">zh-nord@ssmpartner.ch</a></h3>
                </div>
            </div>
            
            <section id="section933" #sectionNineThreeThree>
                <label id="option933" (click)="showtab('nineThreeThreeTabState')"><span
                        [ngClass]="{rotate : states.nineThreeThreeTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.nineThreeThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/besnik.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #besnik (click)="openInfo(besnik)">
                                    <h2>Besnik Uka<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>AGENTURLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:besnik.uka@ssmpartner.ch">besnik.uka@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section1110" #sectionNineThreeThreeTwo>
                <label id="option111" (click)="showtab('sectionNineThreeThreeTwo')"><span
                        [ngClass]="{rotate : states.sectionNineThreeThreeTwo=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectionNineThreeThreeTwo>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mirsad.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #mirsad (click)="openInfo(mirsad)">
                                    <h2>Mirsad Ademi<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mirsad.ademi@ssmpartner.ch">mirsad.ademi@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section91" #sectionEightOne>
                <label id="option91" (click)="showtab('eightOneTabState')"><span
                        [ngClass]="{rotate : states.eightOneTabState=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/adhurim.png" alt="">
                                </div>
                                <div class="info-wrapper" #adhurim (click)="openInfo(adhurim)">
                                    <h2>Adhurim Selmani <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:adhurim.selmani@ssmpartner.ch">adhurim.selmani@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/dedeler.png" alt="">
                                </div>
                                <div class="info-wrapper" #dedeler (click)="openInfo(dedeler)">
                                    <h2>Adnan Dedeler<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:adnan.dedeler@ssmpartner.ch">adnan.dedeler@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ana.png" alt="">
                                </div>
                                <div class="info-wrapper" #ana (click)="openInfo(ana)">
                                    <h2>Ana Serrano <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ana.serrano@ssmpartner.ch">ana.serrano@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/doko.png" alt="">
                                </div>
                                <div class="info-wrapper" #doko (click)="openInfo(doko)">
                                    <h2>Ardit Doko <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ardit.doko@ssmpartner.ch">ardit.doko@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/doko.png" alt="">
                                </div>
                                <div class="info-wrapper" #dokoTwo (click)="openInfo(dokoTwo)">
                                    <h2>Ardit Doko <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ardit.doko@ssmpartner.ch">ardit.doko@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/enur.png" alt="">
                                </div>
                                <div class="info-wrapper" #enurTwo (click)="openInfo(enurTwo)">
                                    <h2>Enur Vejseloski <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:enur.vejseloski@ssmpartner.ch">enur.vejseloski@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/francisco.png" alt="">
                                </div>
                                <div class="info-wrapper" #franciscoThree (click)="openInfo(franciscoThree)">
                                    <h2 class="short-h new short-h-desk">Francisco Roberto Dopena Perez <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:francisco.perez@ssmpartner.ch">francisco.perez@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/francisco2.png" alt="">
                                </div>
                                <div class="info-wrapper" #franciscoTwo (click)="openInfo(franciscoTwo)">
                                    <h2 class="short-h">Francisco Quizeidioco <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:francisco.quizeidioco@ssmpartner.ch">francisco.quizeidioco@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/naef.png" alt="">
                                </div>
                                <div class="info-wrapper" #naef (click)="openInfo(naef)">
                                    <h2>Kevin Naef <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:kevin.naef@ssmpartner.ch">kevin.naef@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ansbach.png" alt="">
                                </div>
                                <div class="info-wrapper" #ansbach (click)="openInfo(ansbach)">
                                    <h2>Jaime Ansbach<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:jaime.ansbach@ssmpartner.ch">jaime.ansbach@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/lavdrim.png" alt="">
                                </div>
                                <div class="info-wrapper" #lavdrim (click)="openInfo(lavdrim)">
                                    <h2>Lavdrim Xhelili<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:lavdrim.xhelili@ssmpartner.ch">lavdrim.xhelili@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/lavdrim.png" alt="">
                                </div>
                                <div class="info-wrapper" #lavdrimTwo (click)="openInfo(lavdrimTwo)">
                                    <h2>Lavdrim Xhelili<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:lavdrim.xhelili@ssmpartner.ch">lavdrim.xhelili@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/leutrim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #leutrim (click)="openInfo(leutrim)">
                                    <h2>Leutrim Selmani<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:leutrim.selmani@ssmpartner.ch">leutrim.selmani@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/meron.png" alt="">
                                </div>
                                <div class="info-wrapper" #meronTwo (click)="openInfo(meronTwo)">
                                    <h2>Meron Badafe<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:meron.badafe@ssmpartner.ch">meron.badafe@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/miriam.png" alt="">
                                </div>
                                <div class="info-wrapper" #miriam (click)="openInfo(miriam)">
                                    <h2>Miriam Rahmanzadeh<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:miriam.rahmanzadeh@ssmpartner.ch">miriam.rahmanzadeh@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/petra.png" alt="">
                                </div>
                                <div class="info-wrapper" #petra (click)="openInfo(petra)">
                                    <h2>Petra Ilic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:petra.ilic@ssmpartner.ch">petra.ilic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/ruhollah.png" alt="">
                                </div>
                                <div class="info-wrapper" #ruhollah (click)="openInfo(ruhollah)">
                                    <h2>Ruhollah Ahmadi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ruhollah.ahmadi@ssmpartner.ch">ruhollah.ahmadi@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)44 440 00 08</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/sinanf.png" alt="">
                                </div>
                                <div class="info-wrapper" #sinanfTwos (click)="openInfo(sinanfTwos)">
                                    <h2>Sinan Fetahi<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sinan.fetahi@ssmpartner.ch">sinan.fetahi@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/sinanf.png" alt="">
                                </div>
                                <div class="info-wrapper" #sinanfTwo (click)="openInfo(sinanfTwo)">
                                    <h2>Sinan Fetahi<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sinan.fetahi@ssmpartner.ch">sinan.fetahi@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/veloso.png" alt="">
                                </div>
                                <div class="info-wrapper" #velosoTwo (click)="openInfo(velosoTwo)">
                                    <h2>Tiago Veloso <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:tiago.veloso@ssmpartner.ch">tiago.veloso@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section82" #sectionEightTwo>
                <label id="option82" (click)="showtab('eightTwoTabState')"><span
                        [ngClass]="{rotate : states.eightTwoTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.eightTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/natasa.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #natasa (click)="openInfo(natasa)">
                                    <h2>Natasa Kojic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:natasa.kojic@ssmpartner.ch">natasa.kojic@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:041435413790'}}" class="phone">+41 43 541 37 90</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div class="tabordion" *ngIf="zug">
            <div class="address">
                <div class="address-left">
                    <h3>SSM Partner Agentur Zug</h3>
                    <h3>Sennweidstrasse 45</h3>
                    <h3>6312 Steinhausen</h3>
                </div>
                <div class="address-left">
                    <h3>Tel. <a href="{{'tel:0417811174'}}">+41 41 781 11 74</a></h3>
                    <h3>Email: <a href="mailto:zug@ssmpartner.ch">zug@ssmpartner.ch</a></h3>
                </div>
                <div class="address-left">
                    <h3>Verkaufsstelle Schaffhausen</h3>
                    <h3>Kronnengässchen 3</h3>
                    <h3>8200 Schaffhausen</h3>
                </div>
            </div>
            
            <section id="section102" #sectionTenTwo>
                <label id="option102" (click)="showtab('tenTwoTabState')"><span
                        [ngClass]="{rotate : states.tenTwoTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.tenTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/kamil.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #kamil (click)="openInfo(kamil)">
                                    <h2>Kamil Malinowski<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>AGENTURLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:kamil.malinowski@ssmpartner.ch">kamil.malinowski@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <!-- <section id="section101" #sectionTenOne>
                <label id="option101" (click)="showtab('tenOneTabState')"><span
                        [ngClass]="{rotate : states.tenOneTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.tenOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/angelika.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #angelika (click)="openInfo(angelika)">
                                    <h2>Angelika Meurer <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5 style="margin-bottom: 3px;">{{'Verkaufsstellenleiterin' | uppercase}} <br> {{'Schaffhausen' | uppercase}}</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:angelika.meurer@ssmpartner.ch">angelika.meurer@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section> -->
            <section id="section103" #sectionTenThree>
                <label id="option103" (click)="showtab('tenThreeTabState')"><span
                        [ngClass]="{rotate : states.tenThreeTabState=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.tenThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sankowska.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sankowska (click)="openInfo(sankowska)">
                                    <h2>Agnieszka Sankowska <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:agnieszka.sankowska@ssmpartner.ch">agnieszka.sankowska@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/csaba.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #csaba (click)="openInfo(csaba)">
                                    <h2>Csaba Sebestyen<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:csaba.sebestyen@ssmpartner.ch">csaba.sebestyen@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/gabriel.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #gabriel (click)="openInfo(gabriel)">
                                    <h2 class="short-h new">Gabriel Castro Dominguez<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gabriel.castro@ssmpartner.ch">gabriel.castro@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/angel.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #angel (click)="openInfo(angel)">
                                    <h2 class="short-h new">Luis Angel Ayuso Rosique <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:luis.ayuso@ssmpartner.ch">luis.ayuso@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/angel.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #angelTwo (click)="openInfo(angelTwo)">
                                    <h2 class="short-h new">Luis Angel Ayuso Rosique <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:luis.ayuso@ssmpartner.ch">luis.ayuso@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/javier.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #javier (click)="openInfo(javier)">
                                    <h2 class="short-h new">Luis Javier Moreira Chu<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:luis.javier@ssmpartner.ch">luis.javier@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section104" #sectionTenFour>
                <label id="option104" (click)="showtab('tenFourTabState')"><span
                        [ngClass]="{rotate : states.tenFourTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.tenFourTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/nicole.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #nicole (click)="openInfo(nicole)">
                                    <h2>Nicole Aregger <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:nicole.aregger@ssmpartner.ch">nicole.aregger@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div class="tabordion" *ngIf="zoffingen">
            <div class="address">
                <div class="address-left">
                    <h3>SSM Partner Agentur Zofingen</h3>
                    <h3>Untere Brühlstrasse 13</h3>
                    <h3>4800 Zofingen</h3>
                </div>
                <div class="address-left">
                    <h3>Tel. <a href="{{'tel:0628344010'}}">+41 62 834 40 10</a></h3>
                    <h3>Email: <a href="mailto:zofingen@ssmpartner.ch">zofingen@ssmpartner.ch</a></h3>
                </div>
            </div>
            
            <section id="section61" #sectionSixOne>
                <label id="option61" (click)="showtab('sixthOneTabState')"><span
                        [ngClass]="{rotate : states.sixthOneTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sixthOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sandro.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sandro (click)="openInfo(sandro)">
                                    <h2>Sandro Trachsel<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>AGENTURLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sandro.trachsel@ssmpartner.ch">sandro.trachsel@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/sajic.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sajic (click)="openInfo(sajic)">
                                    <h2>Igor Sajic<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>GENERALAGENT</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:igor.sajic@ssmpartner.ch">igor.sajic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section71" #sectionOneSeven>
                <label id="option71" (click)="showtab('sevenOneTabState')"><span
                        [ngClass]="{rotate : states.sevenOneTabState=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sevenOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/brunoc.png" alt="">
                                </div>
                                <div class="info-wrapper" #brunoc (click)="openInfo(brunoc)">
                                    <h2>Bruno Cardoso<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:bruno.cardoso@ssmpartner.ch">bruno.cardoso@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/jasna.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #jasna (click)="openInfo(jasna)">
                                    <h2>Jasna Pandurevic<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:jasna.pandurevic@ssmpartner.ch">jasna.pandurevic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/lucab.png" alt="">
                                </div>
                                <div class="info-wrapper" #lucab (click)="openInfo(lucab)">
                                    <h2>Luca Bonaventura<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:luca.bonaventura@ssmpartner.ch">luca.bonaventura@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section62" #sectionSixTwo>
                <label id="option62" (click)="showtab('sixthTwoTabState')"><span
                        [ngClass]="{rotate : states.sixthTwoTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sixthTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/patrizia.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #patrizia (click)="openInfo(patrizia)">
                                    <h2>Patrizia Fagone <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>INNENDIENST</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:pf@ssmpartner.ch">pf@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>
        

        <div class="tabordion" *ngIf="safenwil">
            <div class="address">
                <div class="address-left">
                    <h3>SSM Partner AG</h3>
                    <h3>Solothurnerstrasse 257</h3>
                    <h3>4600 Olten</h3>
                </div>
                <div class="address-left">
                    <h3>Tel. <a href="{{'tel:0412202050'}}">+41 41 220 20 50</a></h3>
                    <h3>Email: <a href="mailto:info@ssmpartner.ch">info@ssmpartner.ch</a></h3>
                </div>
            </div>
            
            <section id="section1501" #sectionNewOne>
                <label id="option15" (click)="showtab('newOneTabState')"><span
                        [ngClass]="{rotate : states.newOneTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/belle.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #belle (click)="openInfo(belle)">
                                    <h2>Emanuele Bello<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>GENERALAGENT</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:emanuele.bello@ssmpartner.ch">emanuele.bello@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section1110" #sectioNewTwo>
                <label id="option111" (click)="showtab('sectioNewTwo')"><span
                        [ngClass]="{rotate : states.sectioNewTwo=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectioNewTwo>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mariano.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #mariano (click)="openInfo(mariano)">
                                    <h2>Davide Mariano <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>VERKAUFSLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:davide.mariano@ssmpartner.ch">davide.mariano@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/mario.png" alt="">
                                </div>
                                <div class="info-wrapper" #mario (click)="openInfo(mario)">
                                    <h2>Mario Canti<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mario.canti@ssmpartner.ch">mario.canti@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/nderim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #nderim (click)="openInfo(nderim)">
                                    <h2>Nderim Saciri<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:nderim.saciri@ssmpartner.ch">nderim.saciri@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section103" #sectionNewThree>
                <label id="option103" (click)="showtab('sectionNewThree')"><span
                        [ngClass]="{rotate : states.sectionNewThree=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectionNewThree>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alessandro.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #alessandro (click)="openInfo(alessandro)">
                                    <h2>Alessandro Tornesello <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:alessandro.tornesello@ssmpartner.ch">alessandro.tornesello@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/gian.png" alt="">
                                </div>
                                <div class="info-wrapper" #gian (click)="openInfo(gian)">
                                    <h2>Gian-Luca Vigliotta <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gian-luca.vigliotta@ssmpartner.ch">gian-luca.vigliotta@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/flamurnew.png" alt="">
                                </div>
                                <div class="info-wrapper" #flamurThree (click)="openInfo(flamurThree)">
                                    <h2>Flamur Aljisani <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:flamur.aljisani@ssmpartner.ch">flamur.aljisani@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/katja.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #katja (click)="openInfo(katja)">
                                    <h2>Katja Ilak <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:katja.ilak@ssmpartner.ch">katja.ilak@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/katja.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #katjaTwo (click)="openInfo(katjaTwo)">
                                    <h2>Katja Ilak <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:katja.ilak@ssmpartner.ch">katja.ilak@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/marijo.png" alt="">
                                </div>
                                <div class="info-wrapper" #marijoTwo (click)="openInfo(marijoTwo)">
                                    <h2>Marijo Ibric <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:marijo.ibric@ssmpartner.ch">marijo.ibric@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/martin.png" alt="">
                                </div>
                                <div class="info-wrapper" #martin (click)="openInfo(martin)">
                                    <h2>Martin Steindorfer <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:martin.steindorfer@ssmpartner.ch">martin.steindorfer@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/michelle.png" alt="">
                                </div>
                                <div class="info-wrapper" #michele (click)="openInfo(michele)">
                                    <h2>Michele Riccardo <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:michele.riccardo@ssmpartner.ch">michele.riccardo@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/nedim.png" alt="">
                                </div>
                                <div class="info-wrapper" #nedimTwo (click)="openInfo(nedimTwo)">
                                    <h2>Nedim Brzina<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:nedim.brzina@ssmpartner.ch">nedim.brzina@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/remzi.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #remzi (click)="openInfo(remzi)">
                                    <h2 class="short-h new">Remzi Bilginer<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:remzi.bilginer@ssmpartner.ch">remzi.bilginer@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/sandro2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sandro (click)="openInfo(sandro)">
                                    <h2 class="short-h new">Sandro Caluori<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sandro.caluori@ssmpartner.ch">sandro.caluori@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/sandro2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #sandroTwo (click)="openInfo(sandroTwo)">
                                    <h2 class="short-h new">Sandro Caluori<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:sandro.caluori@ssmpartner.ch">sandro.caluori@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section114" #sectionElevenFour>
                <label id="option114" (click)="showtab('elevenFourTabState')"><span
                        [ngClass]="{rotate : states.elevenFourTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenFourTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/bello.png" alt="">
                                </div>
                                <div class="info-wrapper" #bello (click)="openInfo(bello)">
                                    <h2>Bello Michele <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:bello.michele@ssmpartner.ch">bello.michele@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/kirthana.png" alt="">
                                </div>
                                <div class="info-wrapper" #kirthana (click)="openInfo(kirthana)">
                                    <h2>Kirthana Uthayakumar <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:kirthana.uthayakumar@ssmpartner.ch">kirthana.uthayakumar@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div class="tabordion" *ngIf="regensdorf">
            
            <section id="section112" #sectionElevenTwoNew>
                <label id="option112" (click)="showtab('sectionElevenTwoNew')"><span
                        [ngClass]="{rotate : states.sectionElevenTwoNew=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectionElevenTwoNew>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/guxim.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #guxim (click)="openInfo(guxim)">
                                    <h2>Guxim Ramizi<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>AGENTURLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:guxim.ramizi@ssmpartner.ch">guxim.ramizi@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section1110" #sectioNewTwoNew>
                <label id="option111" (click)="showtab('sectioNewTwoNew')"><span
                        [ngClass]="{rotate : states.sectioNewTwoNew=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectioNewTwoNew>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/lushe.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #lushe (click)="openInfo(lushe)">
                                    <h2>Lushe Murati <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>VERKAUFSLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:lushe.murati@ssmpartner.ch">lushe.murati@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section190" #sectionNineOneZeroNew>
                <label id="option16" (click)="showtab('sectionNineOneZeroNew')"><span
                        [ngClass]="{rotate : states.sectionNineOneZeroNew=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.sectionNineOneZeroNew>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/adisa.png" alt="">
                                </div>
                                <div class="info-wrapper" #adisa (click)="openInfo(adisa)">
                                    <h2>Adisa Musa <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:adisa.musa@ssmpartner.ch">adisa.musa@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/belal.png" alt="">
                                </div>
                                <div class="info-wrapper" #belal (click)="openInfo(belal)">
                                    <h2>Belal Ahmed <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:belal.ahmed@ssmpartner.ch">belal.ahmed@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/abdiu.png" alt="">
                                </div>
                                <div class="info-wrapper" #abdiu (click)="openInfo(abdiu)">
                                    <h2>Demir Abdiu <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:demir.abdiu@ssmpartner.ch">demir.abdiu@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/flamur2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #flamur (click)="openInfo(flamur)">
                                    <h2 class="short-h">Flamur Murati <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:flamur.murati@ssmpartner.ch">flamur.murati@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/flamur2.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #flamurmurati (click)="openInfo(flamurmurati)">
                                    <h2 class="short-h">Flamur Murati <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:flamur.murati@ssmpartner.ch">flamur.murati@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/marigona.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #marigona (click)="openInfo(marigona)">
                                    <h2 class="short-h">Marigona Murati <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:marigona.murati@ssmpartner.ch">marigona.murati@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/vanesak.png" alt="">
                                </div>
                                <div class="info-wrapper" #vanesak (click)="openInfo(vanesak)">
                                    <h2>Vanesa Krasniqi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:vanesa.krasniqi@ssmpartner.ch">vanesa.krasniqi@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/zejno.png" alt="">
                                </div>
                                <div class="info-wrapper" #zejno (click)="openInfo(zejno)">
                                    <h2>Zejno Musliu <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:zejno.musliu@ssmpartner.ch">zejno.musliu@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        <div class="tabordion" *ngIf="bern">
            
            <section id="section1151" #sectionNewElevenTwo>
                <label id="option1151" (click)="showtab('newTwoTabState')"><span
                        [ngClass]="{rotate : states.newTwoTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/agron.png" alt="">
                                </div>
                                <div class="info-wrapper" #agron (click)="openInfo(agron)">
                                    <h2>Agron Selmanaj<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>AGENTURLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:agron.selmanaj@ssmpartner.ch">agron.selmanaj@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section1131" #sectionNewElevenThree>
                <label id="option1131" (click)="showtab('newElevenThreeTabState')"><span
                        [ngClass]="{rotate : states.newElevenThreeTabState=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newElevenThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/albans.png" alt="">
                                </div>
                                <div class="info-wrapper" #albans (click)="openInfo(albans)">
                                    <h2>Alban Selmanaj <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:alban.selmanaj@ssmpartner.ch">alban.selmanaj@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/bilel.png" alt="">
                                </div>
                                <div class="info-wrapper" #bilel (click)="openInfo(bilel)">
                                    <h2>Bilel Chagra<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:bilel.chagra@ssmpartner.ch">bilel.chagra@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/nrece.png" alt="">
                                </div>
                                <div class="info-wrapper" #nrece (click)="openInfo(nrece)">
                                    <h2>Nrecë Gjykaj <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:nrece.gjykaj@ssmpartner.ch">pius.baumgartner@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/prince.png" alt="">
                                </div>
                                <div class="info-wrapper" #prince (click)="openInfo(prince)">
                                    <h2>Prince Clever<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:prince.clever@ssmpartner.ch">prince.clever@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/prince.png" alt="">
                                </div>
                                <div class="info-wrapper" #princeTwo (click)="openInfo(princeTwo)">
                                    <h2>Prince Clever<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:prince.clever@ssmpartner.ch">prince.clever@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/rahim.png" alt="">
                                </div>
                                <div class="info-wrapper" #rahim (click)="openInfo(rahim)">
                                    <h2>Rahim Haxhiselimaj<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:rahim.haxhiselimaj@ssmpartner.ch">rahim.haxhiselimaj@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>
        
        <div class="tabordion" *ngIf="pfungen">
            
            <section id="section112" #sectionElevenTwo>
                <label id="option112" (click)="showtab('elevenTwoTabState')"><span
                        [ngClass]="{rotate : states.elevenTwoTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/erdal.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #erdal (click)="openInfo(erdal)">
                                    <h2>Erdal Karakas<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>AGENTURLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:erdal.karakas@ssmpartner.ch">erdal.karakas@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section111" #sectionElevenOne>
                <label id="option111" (click)="showtab('elevenOneTabState')"><span
                        [ngClass]="{rotate : states.elevenOneTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/semih.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #semih (click)="openInfo(semih)">
                                    <h2>Semih Karakas <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:semih.karakas@ssmpartner.ch">semih.karakas@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0444400008'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section113" #sectionElevenThree>
                <label id="option113" (click)="showtab('elevenThreeTabState')"><span
                        [ngClass]="{rotate : states.elevenThreeTabState=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/abdullah.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #abdullah (click)="openInfo(abdullah)">
                                    <h2>Abdullah Yigitel <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:abdullah.yigital@ssmpartner.ch">abdullah.yigital@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/aydin.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #aydin (click)="openInfo(aydin)">
                                    <h2>Aydin Yurtsever<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:aydin.yurtsever@ssmpartner.ch">aydin.yurtsever@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/djevat.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #djevat (click)="openInfo(djevat)">
                                    <h2>Djevat Fetaji<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:djevat.fetaji@ssmpartner.ch">djevat.fetaji@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/scandurro.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #scandurro (click)="openInfo(scandurro)">
                                    <h2>Massimo Scandurro<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:massimo.scandurro@ssmpartner.ch">massimo.scandurro@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/scandurro.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #scandurroTwo (click)="openInfo(scandurroTwo)">
                                    <h2>Massimo Scandurro<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:massimo.scandurro@ssmpartner.ch">massimo.scandurro@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/murat.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #muratTwo (click)="openInfo(muratTwo)">
                                    <h2>Murat Sahin <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:murat.sahin@ssmpartner.ch">murat.sahin@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="section114" #sectionElevenFour>
                <label id="option114" (click)="showtab('elevenFourTabState')"><span
                        [ngClass]="{rotate : states.elevenFourTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.elevenFourTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/bilge.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #bilge (click)="openInfo(bilge)">
                                    <h2>Bilge Tepe <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:bilge.tepe@ssmpartner.ch">bilge.tepe@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/busra.jpg" alt="">
                                </div>
                                <div class="info-wrapper" #busra (click)="openInfo(busra)">
                                    <h2>Büsra Saygili <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:büsra.saygili@ssmpartner.ch">büsra.saygili@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>

        
        <div class="tabordion" *ngIf="luzern">
            
            <section id="sectionLu1" #luzernNewOne>
                <label id="optionLu1" (click)="showtab('newLuzernOneTabState')"><span
                        [ngClass]="{rotate : states.newLuzernOneTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newLuzernOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/marianna.png" alt="">
                                </div>
                                <div class="info-wrapper" #marianna (click)="openInfo(marianna)">
                                    <h2>Marianna Todorovic<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>AGENTURLEITERN</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:marianna.todorovic@ssmpartner.ch">marianna.todorovic@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="sectionLu2" #newLuzernTwoTabState>
                <label id="optionLu2" (click)="showtab('newLuzernTwoTabState')"><span
                        [ngClass]="{rotate : states.newLuzernTwoTabState=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newLuzernTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/enzo.png" alt="">
                                </div>
                                <div class="info-wrapper" #enzoTwo (click)="openInfo(enzoTwo)">
                                    <h2>Enzo Aubry<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:enzo.aubry@ssmpartner.ch">enzo.aubry@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/jawad.png" alt="">
                                </div>
                                <div class="info-wrapper" #jawad (click)="openInfo(jawad)">
                                    <h2>Jawad Akouchiou <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:jawad.akouchiou@ssmpartner.ch">jawad.akouchiou@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/thomas.png" alt="">
                                </div>
                                <div class="info-wrapper" #thomas (click)="openInfo(thomas)">
                                    <h2>Thomas Farny<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:thomas.farny@ssmpartner.ch">thomas.farny@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/zied2.png" alt="">
                                </div>
                                <div class="info-wrapper" #zied (click)="openInfo(zied)">
                                    <h2>Zied Daldou<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:zied.daldou@ssmpartner.ch">zied.daldou@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card"></div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
        </div>
            
        
        <div class="tabordion" *ngIf="ticino">
            
            <section id="sectionTi1" #newTicinoTwoTabState>
                <label id="sectionTi1" (click)="showtab('newTicinoOneTabState')"><span
                        [ngClass]="{rotate : states.newTicinoOneTabState=='open'}">+</span>
                    <p>Agenturleitung</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTicinoOneTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/gerardo.png" alt="">
                                </div>
                                <div class="info-wrapper" #centralla (click)="openInfo(centralla)">
                                    <h2>Gerardo Centrella<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>AGENTURLEITER</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gerardo.centrella@ssmpartner.ch">gerardo.centrella@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0797376433'}}" class="phone">+41 79 737 64 33</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="sectionTi2" #newTicinoTwoTabState>
                <label id="sectionTi2" (click)="showtab('newTicinoTwoTabState')"><span
                        [ngClass]="{rotate : states.newTicinoTwoTabState=='open'}">+</span>
                    <p>Teamleiter</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTicinoTwoTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/jurkic.png" alt="">
                                </div>
                                <div class="info-wrapper" #jurkic (click)="openInfo(jurkic)">
                                    <h2>Ivan Jurkic <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:ivan.jurkic@ssmpartner.ch">ivan.jurkic@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0795050711'}}" class="phone">+41 79 505 07 11</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/massimo.png" alt="">
                                </div>
                                <div class="info-wrapper" #massimo (click)="openInfo(massimo)">
                                    <h2>Massimo Pansardi <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>TEAMLEITER</h5>
                                        <div class="p-wrapper">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:massimo.pansardi@ssmpartner.ch">massimo.pansardi@ssmpartner.ch</a></p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0764439909'}}" class="phone">+41 76 443 99 09 </a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">

                            </div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="sectionTi4" #newTicinoFoueTabState>
                <label id="sectionTi4" (click)="showtab('newTicinoFoueTabState')"><span
                        [ngClass]="{rotate : states.newTicinoFoueTabState=='open'}">+</span>
                    <p>Kundenberater</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTicinoFoueTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/lagana.png" alt="">
                                </div>
                                <div class="info-wrapper" #lagana (click)="openInfo(lagana)">
                                    <h2>Andrea Lagana <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:andrea.lagana@ssmpartner.ch">andrea.lagana@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0799390329'}}" class="phone">+41 79 939 03 29</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/carlo.png" alt="">
                                </div>
                                <div class="info-wrapper" #carlo (click)="openInfo(carlo)">
                                    <h2>Carlo Schwendener <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:carlo.schwendener@ssmpartner.ch">carlo.schwendener@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0793936176'}}" class="phone">+41 79 393 61 76</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/carmelo.png" alt="">
                                </div>
                                <div class="info-wrapper" #staropoli (click)="openInfo(staropoli)">
                                    <h2>Carmelo Staropoli<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:carmelo.staropoli@ssmpartner.ch">carmelo.staropoli@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0787554797'}}" class="phone">+41 78 755 47 97</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card additional-card">
                                <div class="img-wrapper">
                                    <img src="assets/roncon.png" alt="">
                                </div>
                                <div class="info-wrapper" #roncon (click)="openInfo(roncon)">
                                    <h2>Davide Roncon<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:davide.roncon@ssmpartner.ch">davide.roncon@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0795384330'}}" class="phone">+41 79 538 43 30</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card add-remove">
                                <div class="img-wrapper">
                                    <img src="assets/roncon.png" alt="">
                                </div>
                                <div class="info-wrapper" #ronconTwo (click)="openInfo(ronconTwo)">
                                    <h2>Davide Roncon<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:davide.roncon@ssmpartner.ch">davide.roncon@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0412202050'}}" class="phone">+41 (0)41 220 20 50</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/alessi.png" alt="">
                                </div>
                                <div class="info-wrapper" #alessi (click)="openInfo(alessi)">
                                    <h2>Fabian Alessi<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:fabian.mattia.alessi@ssmpartner.ch">fabian.mattia.alessi@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0799499468'}}" class="phone">+41 79 949 94 68</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div class="card tc-card">
                                <div class="img-wrapper">
                                    <img src="assets/stagliao.png" alt="">
                                </div>
                                <div class="info-wrapper" #stagliao (click)="openInfo(stagliao)">
                                    <h2>Mattia Stagliano<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:mattia.stagliano@ssmpartner.ch">mattia.stagliano@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0788998217'}}" class="phone">+41 78 899 82 17</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/michael.png" alt="">
                                </div>
                                <div class="info-wrapper" #michael (click)="openInfo(michael)">
                                    <h2>Michael De Luca<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:gianni.de-luca@ssmpartner.ch">gianni.de-luca@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0782184645'}}" class="phone">+41 78 218 46 45</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/viviana.png" alt="">
                                </div>
                                <div class="info-wrapper" #viviana (click)="openInfo(viviana)">
                                    <h2>Viviana Lino<img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>FINANZCOACH</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:viviana.lino@ssmpartner.ch">viviana.lino@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0762931512'}}" class="phone">+41 76 293 15 12</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card"></div>
                        </div>
                    </article>
                </div>
            </section>
            <section id="sectionTi3" #newTicinoThreeTabState>
                <label id="option19" (click)="showtab('newTicinoThreeTabState')"><span
                        [ngClass]="{rotate : states.newTicinoThreeTabState=='open'}">+</span>
                    <p>Backoffice</p>
                </label>
                <div class="article-wrapper" [@openClose]=states.newTicinoThreeTabState>
                    <article>
                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="img-wrapper">
                                    <img src="assets/giovanna.png" alt="">
                                </div>
                                <div class="info-wrapper" #giovanna (click)="openInfo(giovanna)">
                                    <h2>Giovanna Di Nardo <img src="assets/up-arrow.svg" alt=""></h2>
                                    <div class="info-content-wrapper">
                                        <h5>BACKOFFICE</h5>
                                        <div class="p-wrapper short-l">
                                            <img src="assets/mail-active.svg" alt="">
                                            <p><a href="mailto:giovanna.dinardo@ssmpartner.ch">giovanna.dinardo@ssmpartner.ch</a>
                                            </p>
                                        </div>
                                        <div class="p-wrapper">
                                            <img src="assets/phone-active.svg" alt="">
                                            <p><a href="{{'tel:0788913337'}}" class="phone">+41 78 891 33 37</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                            </div>
                            <div class="card">
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </div>


    </div>
</div>