import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FinanceComponent } from './finance/finance.component';
import { NewsComponent } from './news/news.component';
import { ContactComponent } from './contact/contact.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PostComponent } from './post/post.component';
import { ReactiveFormsModule} from '@angular/forms';
import { FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { LebensereignisComponent } from './lebensereignis/lebensereignis.component';
import { LebensphaseComponent } from './lebensphase/lebensphase.component';
import { KarriereComponent } from './karriere/karriere.component';
import { AnalyseComponent } from './analyse/analyse.component';
import { WerteComponent } from './werte/werte.component';
import { AcademyComponent } from './academy/academy.component';
import { FormularComponent } from './lebensereignis-components/formular/formular.component';
import { LebensphaseOverviewComponent } from './lebensphase-overview/lebensphase-overview.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { LebensereignisOverviewComponent } from './lebensereignis-overview/lebensereignis-overview.component';
import { KarrierePusherComponent } from './karriere-pusher/karriere-pusher.component';
import { AboutNewComponent } from './about-new/about-new.component';
import { AgentureComponent } from './agenture/agenture.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    FooterComponent,
    HeaderComponent,
    FinanceComponent,
    NewsComponent,
    ContactComponent,
    ImpressumComponent,
    PostComponent,
    LebensereignisComponent,
    LebensphaseComponent,
    KarriereComponent,
    AnalyseComponent,
    AboutNewComponent,
    WerteComponent,
    AcademyComponent,
    AgentureComponent,
    FormularComponent,
    LebensphaseOverviewComponent,
    LebensereignisOverviewComponent,
    KarrierePusherComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2ImgMaxModule,
    SwiperModule,
    AgmCoreModule.forRoot()
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
